<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="displayButton"
    @body-opened="$emit('bodyOpened', { event: event })"
  >
    <template #name>
      <RunGlyph class="h-6 w-6 text-scale-6"></RunGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      <span v-if="!operatorCommand" class="ml-1"
        >{{ $t("command") }}:
        <span class="font-medium">{{ event.type_data.command }}</span></span
      >
      <span v-else class="ml-1">{{ $t("operator") }}</span></template
    >
    <template #body>
      <ShowLogsConsole
        class="text-xs"
        :htmlLogs="htmlLogs"
        :isLoading="isLoading"
      ></ShowLogsConsole>
    </template>
  </BaseEvent>
</template>

<script>
import AnsiUp from "ansi_up";
import { escape } from "lodash";
import { defineComponent } from "vue";

import RunGlyph from "@/components/atoms/glyphs/RunGlyph.vue";
import ShowLogsConsole from "@/components/parts/app/showLogs/ShowLogsConsole.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "RunEvent",
  components: { ShowLogsConsole, RunGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
    eventComplement: Object,
  },
  emits: ["bodyOpened"],
  data() {
    const colorizer = new AnsiUp();
    colorizer.use_classes = true;
    return { colorizer };
  },
  computed: {
    operatorCommand() {
      return !this.event.type_data.command;
    },
    displayButton() {
      return !this.operatorCommand && !!this.event.type_data.audit_log_id;
    },
    isLoading() {
      return !this.eventComplement;
    },
    htmlLogs() {
      let html = "";
      if (!this.eventComplement) {
        return "";
      }

      if (this.eventComplement.error) {
        html += "<span class='ansi-bright-red-fg'>";
        html += this.$t("errors.generic");
        html += "</span>";
        return html;
      }

      for (const log of this.eventComplement.data.logs) {
        html += "<span class='text-blue-300'>";
        html += escape(log.time) + " ";
        html += "</span>";
        if (log.type === "output") {
          html += "<span class='text-green-400'>";
        } else {
          html += "<span class='text-secondary-4'>";
        }
        html += escape(log.type) + " ";
        html += "</span>";
        html += "<span>" + this.colorizer.ansi_to_html(log.content) + "</span>";
      }

      return html;
    },
  },
});
</script>

<i18n>
en:
  title: "Command run"
  command: "Command"
  operator: "An operator started a one-off container for maintenance/support purposes."
  errors:
    generic: "The logs could not be fetched, please retry in a little while. If the issue keeps happening, please get in touch with support!"
fr:
  title: "Commande exécutée"
  command: "Commande"
  operator: "Un opérateur a lancé un container one-off pour des raisons de maintenance/support"
  errors:
    generic: "Les logs n'ont pas pu être récupérés, veuillez retenter d'ici peu. Si le problème persiste, contactez nous au support!"
</i18n>
