<template>
  <div class="pt-8">
    <div class="flex">
      <DividedSectionTitle
        class="flex-grow"
        :title="$t('title')"
        :withCount="true"
        :count="appCount"
      >
        <div class="flex justify-end">
          <SortBy
            :currentSort="currentSort"
            @sort-by-choice-selected="(e) => $emit('sortByChoiceSelected', e)"
          ></SortBy>

          <SCButton
            kind="empty"
            size="md"
            class="ml-2"
            @click="$emit('toggleFiltersDisplay')"
          >
            <span class="flex items-center justify-center w-14">
              <span v-if="filterCount === 0">{{ $t("filter") }}</span>
              <span v-else>{{ $t("clear") }}</span>

              <span class="ml-2">
                <FilterGlyph v-if="filterCount === 0" class="text-scale-7" />
                <div
                  v-else
                  class="bg-primary-2 text-primary-4 rounded-full h-4 w-4 flex items-center justify-center text-xs"
                >
                  {{ filterCount }}
                </div>
              </span>
            </span>
          </SCButton>
        </div>
      </DividedSectionTitle>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import FilterGlyph from "@/components/atoms/glyphs/FilterGlyph.vue";
import DividedSectionTitle from "@/components/atoms/titles/DividedSectionTitle.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SortBy from "@/components/parts/app/listApps/SortBy.vue";

export default defineComponent({
  name: "ListAppsTitle",
  components: { SortBy, DividedSectionTitle, SCButton, FilterGlyph },
  props: {
    appCount: [Number, String],
    filterQuery: Object,
    currentSort: String,
  },
  emits: ["sortByChoiceSelected", "toggleFiltersDisplay"],
  computed: {
    filterCount() {
      return Object.keys(this.filterQuery).length;
    },
  },
});
</script>

<i18n>
en:
  title: "Applications"
  sort: "Sort by"
  filter: "Filter"
  clear: "Clear"
fr:
  title: "Applications"
  sort: "Trier"
  filter: "Filtrer"
  clear: "Effacer"
</i18n>
