<template>
  <main>
    <OverviewAppCard
      v-if="accessAsAdmin"
      :app="app"
      :parentAppInfo="parentAppInfo"
      :scmRepoLink="scmRepoLink.value"
      :collaborators="collaborators"
    />

    <OverviewAppCard
      v-else-if="scmRepoLink && scmRepoLink.value"
      :app="app"
      :parentAppInfo="parentAppInfo"
      :scmRepoLink="scmRepoLink.value"
      :collaborators="collaborators"
    />

    <OverviewAppCard
      v-else-if="appInfo"
      :app="appInfo"
      :gitURL="app.git_url"
      :parentAppInfo="parentAppInfo"
      :scmRepoLink="appInfo.scm_repo_link"
      :collaborators="collaborators"
      @copy-git-remote="(e) => $emit('copyGitRemote', e)"
    />

    <ShowAppCards
      :addons="addons"
      :collaborators="collaborators"
      :containers="containers"
      :events="events"
      :reviewApps="reviewApps"
      :lastDeployment="lastDeployment"
      :scmRepoLink="scmRepoLink.value"
      :app="app"
      :user="user"
      :dataAccessConsent="dataAccessConsent"
      :createDataAccessConsentHandler="createDataAccessConsentHandler"
      @restart-action="(e) => $emit('restartAction', e)"
      @start-data-access-consent-creation="
        (e) => $emit('startDataAccessConsentCreation', e)
      "
    ></ShowAppCards>
    <AddDataAccessConsentFormModal
      v-if="createDataAccessConsentHandler"
      :formHandler="createDataAccessConsentHandler"
      @modal-closed="$emit('cancelDataAccessConsentCreation')"
      @form-submitted="(e) => $emit('submitDataAccessConsentCreation', e)"
    ></AddDataAccessConsentFormModal>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import OverviewAppCard from "@/components/organisms/appCards/OverviewAppCard.vue";
import ShowAppCards from "@/components/parts/app/showApp/ShowAppCards.vue";
import AddDataAccessConsentFormModal from "@/components/parts/appSettings/dataAccessConsentsSettings/AddDataAccessConsentFormModal.vue";

export default defineComponent({
  name: "Overview",
  components: {
    OverviewAppCard,
    ShowAppCards,
    AddDataAccessConsentFormModal,
  },
  props: {
    appInfo: Object,
    parentAppInfo: Object,
    app: Object,
    scmRepoLink: Object,
    user: Object,
    addons: Object,
    collaborators: Object,
    containers: Object,
    events: Object,
    lastDeployment: Object,
    reviewApps: Object,
    createDataAccessConsentHandler: Object,
    dataAccessConsent: Object,
  },
  emits: [
    "cancelDataAccessConsentCreation",
    "copyGitRemote",
    "restartAction",
    "startDataAccessConsentCreation",
    "submitDataAccessConsentCreation",
  ],
  data() {
    return {};
  },
  computed: {
    accessAsAdmin() {
      return this.user.flags?.admin && !this.appInfo;
    },
  },
});
</script>
