<template>
  <div class="svg-img">
    <DockerLogo
      v-if="addonProvider.id === 'scalingo-docker-image'"
      class="icon-margin"
      :width="width"
    />
    <ElasticSearchLogo
      v-else-if="addonProvider.id === 'elasticsearch'"
      :width="width"
    />
    <OpenSearchLogo
      v-else-if="addonProvider.id === 'opensearch'"
      :width="width"
    />
    <InfluxDBLogo v-else-if="addonProvider.id === 'influxdb'" :width="width" />
    <MongoDBLogo v-else-if="addonProvider.id === 'mongodb'" :width="width" />
    <MySQLLogo v-else-if="addonProvider.id === 'mysql'" :width="width" />
    <OpenVPNLogo
      v-else-if="addonProvider.id === 'scalingo-openvpn'"
      :width="width"
    />
    <PostgreSQLLogo
      v-else-if="addonProvider.id === 'postgresql'"
      :width="width"
    />
    <RedisLogo v-else-if="addonProvider.id === 'redis'" :width="width" />
    <template v-else>
      <div
        class="bg-scale-2 rounded-lg flex items-center justify-center font-bold"
        :style="{
          height: `${width}px`,
          width: `${width}px`,
          'font-size': `${width / 4}px`,
        }"
      >
        <template v-if="addonProvider.id === 'scalingo-vpn-ipsec'">
          VPN
        </template>
        <template v-if="addonProvider.id === 'tcp-gateway'">TCP</template>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import DockerLogo from "@/components/atoms/graphics/addonProviders/Docker.vue";
import ElasticSearchLogo from "@/components/atoms/graphics/addonProviders/ElasticSearch.vue";
import InfluxDBLogo from "@/components/atoms/graphics/addonProviders/InfluxDB.vue";
import MongoDBLogo from "@/components/atoms/graphics/addonProviders/MongoDB.vue";
import MySQLLogo from "@/components/atoms/graphics/addonProviders/MySQL.vue";
import OpenSearchLogo from "@/components/atoms/graphics/addonProviders/OpenSearch.vue";
import OpenVPNLogo from "@/components/atoms/graphics/addonProviders/OpenVPN.vue";
import PostgreSQLLogo from "@/components/atoms/graphics/addonProviders/PostgreSQL.vue";
import RedisLogo from "@/components/atoms/graphics/addonProviders/Redis.vue";
import { addonProviderIconWidth } from "@/config/icons";

export default defineComponent({
  name: "AddonProviderIcon",
  components: {
    DockerLogo,
    ElasticSearchLogo,
    InfluxDBLogo,
    MongoDBLogo,
    MySQLLogo,
    OpenSearchLogo,
    OpenVPNLogo,
    PostgreSQLLogo,
    RedisLogo,
  },
  props: {
    addonProvider: Object,
    width: {
      type: [Number, String],
      default: addonProviderIconWidth,
    },
  },
});
</script>

<style scoped>
.icon-margin {
  margin: 6px 0;
}
</style>
