<template>
  <SideMenuLayout>
    <template #side>
      <SideMenu :app="app" :user="user"></SideMenu>
    </template>
    <template #router>
      <router-view :app="app" />
    </template>
  </SideMenuLayout>
</template>

<script>
import { defineComponent } from "vue";

import SideMenuLayout from "@/components/layouts/SideMenuLayout.vue";
import SideMenu from "@/components/organisms/menus/AppSettingsMenu.vue";

export default defineComponent({
  name: "SettingsRoot",
  components: {
    SideMenuLayout,
    SideMenu,
  },
  props: {
    app: Object,
    user: Object,
  },
  data: function () {
    return {};
  },
});
</script>

<i18n>
  en:
    title: "Deployments"
    subtitle: "Last deployments."
  fr:
    title: "Déploiements"
    subtitle: "Liste des derniers déploiements."
</i18n>
