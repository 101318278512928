<template>
  <AppCard :app="app" :scmRepoLink="scmRepoLink">
    <template #header>
      <div v-if="parentAppInfo" class="flex-shrink">
        <ParentAppLink class="mt-4" />
      </div>

      <div class="flex-grow text-2xl font-bold truncate text-scale-10">
        <div v-if="parentAppInfo" class="mb-2">
          <router-link
            :to="parentAppRoute"
            :title="app.name"
            class="text-base text-scale-4 font-medium hover:underline"
          >
            {{ parentAppInfo.name }}
          </router-link>
        </div>

        {{ app.name }}
      </div>
    </template>
    <template #body>
      <div class="flex flex-col lg:flex-row lg:justify-between lg:w-full">
        <SCMIntegrationLinks
          :app="app"
          :gitURL="gitURL"
          :scmRepoLink="scmRepoLink"
          :moreSpacing="true"
          :withDeployConfigLink="true"
          @copy-git-remote="(e) => $emit('copyGitRemote', e)"
        />
        <InlinedCollaborators :collaborators="collaborators" :app="app" />
      </div>
    </template>
  </AppCard>
</template>

<script>
import { defineComponent } from "vue";

import ParentAppLink from "@/components/atoms/glyphs/ParentAppLink.vue";
import AppCard from "@/components/organisms/appCards/AppCard.vue";
import InlinedCollaborators from "@/components/organisms/appCards/InlinedCollaborators.vue";
import SCMIntegrationLinks from "@/components/organisms/appCards/SCMIntegrationLinks.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "OverviewAppCard",
  components: {
    InlinedCollaborators,
    SCMIntegrationLinks,
    AppCard,
    ParentAppLink,
  },
  props: {
    app: Object,
    parentAppInfo: Object,
    scmRepoLink: Object,
    collaborators: Object,
    gitURL: String,
  },
  emits: ["copyGitRemote"],
  computed: {
    parentAppRoute() {
      return {
        name: Routes.App.Overview,
        params: {
          region: this.parentAppInfo.region,
          id: this.parentAppInfo.name,
        },
      };
    },
  },
});
</script>
