import i18n from "@/i18n";

import type { TranslateResult } from "vue-i18n";

// humanReadableSize returns a human readable size like 1KB 234MB 2GB etc.
export function humanReadableSize(
  bytes: number,
  stopAt?: number,
  toFixed: number = 0,
): string {
  if (bytes === 0) {
    return `0 ${i18n.global.tc("helpers.humanReadableSize.bytes", 0)}`;
  }

  const sizes: TranslateResult[] = [
    i18n.global.tc("helpers.humanReadableSize.bytes", bytes),
    i18n.global.t("helpers.humanReadableSize.kilo"),
    i18n.global.t("helpers.humanReadableSize.mega"),
    i18n.global.t("helpers.humanReadableSize.giga"),
    i18n.global.t("helpers.humanReadableSize.tera"),
  ];

  let i: number = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) return `${bytes} ${sizes[i]}`;
  if (stopAt && i > stopAt) i = stopAt;
  if (i >= sizes.length) i = sizes.length - 1;

  return `${(bytes / 1024 ** i).toFixed(i >= 3 ? toFixed : 0)} ${sizes[i]}`;
}
