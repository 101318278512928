<template>
  <ViewComponent
    :addons="addons"
    :addonProviders="addonProviders"
    @addon-provider-clicked="addonProviderSelected"
  ></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/addonsCreation/Step1.vue";
import { Routes } from "@/router/names";
import { availableAddonProviders } from "@/store/addon-providers";
import { billingProfile } from "@/store/billing-profile";
import { useAppAddonsStore } from "@/stores/app/addons";

export default defineComponent({
  name: "AddonCreationStep1Container",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  computed: {
    addonsStore() {
      return useAppAddonsStore();
    },
    addons() {
      return {
        items: this.addonsStore.items,
        promiseInfo: this.addonsStore.promiseInfo,
      };
    },
    billingProfile() {
      return billingProfile(this.$store);
    },
    addonProviders() {
      return availableAddonProviders(
        this.$store,
        this.app.region,
        this.addons.items,
        {
          transform: (coll) => {
            if (this.app.hds_resource) {
              coll = coll.filter((provider) => provider.hds_available);
            }
            if (this.app.c3_resource) {
              coll = coll.filter((provider) => provider.c3_available);
            }
            return coll;
          },
        },
      );
    },
  },
  methods: {
    addonProviderSelected(addonProvider) {
      this.$router.push({
        name: Routes.App.Addon.Creation.Step2,
        params: {
          ...this.$route.params,
          providerId: addonProvider.id,
        },
      });
    },
  },
});
</script>
