<template>
  <main>
    <ProgramCard
      :user="user"
      @copy-referral-link="(e) => $emit('copyReferralLink', e)"
    />

    <StatsCard class="mt-4" :stats="stats" />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import ProgramCard from "@/components/parts/account/referrals/ProgramCard.vue";
import StatsCard from "@/components/parts/account/referrals/StatsCard.vue";

export default defineComponent({
  name: "Referrals",
  components: { ProgramCard, StatsCard },
  props: {
    user: Object,
    stats: Object,
  },
  emits: ["copyReferralLink"],
});
</script>
