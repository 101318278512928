<template>
  <SimpleModal size="lg:w-1/2 xl:w-2/5" @modal-closed="$emit('modalClosed')">
    <template #title>
      {{ $t("title") }}
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template #body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-3 text-scale-8">
              {{ $t("warning.text") }}
              <DocLink
                href="https://doc.scalingo.com/platform/app/review-apps"
                >{{ $t("warning.more") }}</DocLink
              >
            </div>
            <div>
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.deploy_review_apps_enabled"
                name="deployReviewAppsEnabled"
              >
                <CheckboxInput
                  :name="field.name"
                  :modelValue="field.value"
                  class="mb-5 mt-3"
                  :errors="errors"
                  :label="$t('enabledLabel')"
                  @update:model-value="handleChange"
                ></CheckboxInput>
              </Field>
            </div>

            <div
              v-if="form.deploy_review_apps_enabled"
              class="border-t text-scale-8"
            >
              <div class="flex mt-4 flex-col">
                <div class="text-scale-10 text-lg mb-2">
                  {{ $t("allowForkLabel") }}
                </div>
                <div>
                  {{ $t("allowForkText") }}
                </div>
                <div>
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.automatic_creation_from_forks_allowed"
                    name="allowFork"
                  >
                    <CheckboxInput
                      :name="field.name"
                      :modelValue="field.value"
                      class="mb-5 mt-3"
                      :errors="errors"
                      :label="$t('allowForkCheckbox')"
                      @update:model-value="handleChange"
                    ></CheckboxInput>
                  </Field>
                </div>
              </div>
            </div>

            <div
              v-if="form.deploy_review_apps_enabled"
              class="border-t text-scale-7"
            >
              <div class="text-scale-10 text-lg mb-2 mt-4">
                {{ $t("autoDestroy") }}
              </div>
              <div>
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.delete_on_close_enabled"
                  name="deleteOnCloseEnabled"
                >
                  <CheckboxInput
                    :name="field.name"
                    :modelValue="field.value"
                    class="mb-2 mt-3 ml-6"
                    :errors="errors"
                    :label="$t('autoDestroyPrLabel')"
                    @update:model-value="handleChange"
                  ></CheckboxInput>
                </Field>
              </div>
              <div class="mb-3">
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.hours_before_delete_on_close"
                  name="hoursBeforeDeleteOnClose"
                >
                  <span class="flex items-center ml-16"
                    ><span class="mr-2">{{ $t("after") }}</span>
                    <TextInput
                      :name="field.name"
                      :modelValue="field.value"
                      :errors="errors"
                      class="w-16 h-8"
                      type="number"
                      @update:model-value="handleChange"
                    ></TextInput>
                    <span class="ml-2">{{ $t("hours") }}</span>
                  </span>
                </Field>
              </div>
              <div>
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.delete_stale_enabled"
                  name="deleteStaleEnabled"
                >
                  <CheckboxInput
                    :name="field.name"
                    :modelValue="field.value"
                    class="mb-2 ml-6"
                    :errors="errors"
                    :label="$t('autoDestroyNoEventLabel')"
                    @update:model-value="handleChange"
                  ></CheckboxInput>
                </Field>
              </div>
              <div class="mb-3">
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.hours_before_delete_stale"
                  name="hoursBeforeDeleteStale"
                >
                  <span class="flex items-center ml-16">
                    <span class="mr-2"> {{ $t("during") }}</span>
                    <TextInput
                      :name="field.name"
                      :modelValue="field.value"
                      :errors="errors"
                      type="number"
                      class="w-16 h-8"
                      @update:model-value="handleChange"
                    ></TextInput>
                    <span class="ml-2">{{ $t("hours") }}</span>
                  </span>
                </Field>
              </div>
            </div>
            <FormAlert
              v-if="paymentRequired || hasErrorMessages"
              class="mt-2 mb-6"
              styling="error"
            >
              <template #text>
                <template v-if="paymentRequired">
                  <template v-if="currentUserIsOwner">
                    <p>
                      {{ $t("paymentRequired.owner") }}

                      <router-link :to="billingProfileRoute" class="underline">
                        {{ $t("billingProfile") }}
                      </router-link>
                      {{ $t("paymentRequired.andAdd") }}

                      <router-link :to="paymentMethodsRoute" class="underline">
                        {{ $t("paymentMethod") }}
                      </router-link>
                    </p>
                  </template>
                  <template v-else>
                    {{ owner.username }}
                    <p>{{ $t("paymentRequired.collaborator") }}</p>
                  </template>
                </template>

                <template v-else-if="hasErrorMessages">
                  {{ formHandler.errors.$base.join() }}
                </template>
              </template>
            </FormAlert>
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Form, Field } from "vee-validate";
import { defineComponent } from "vue";

import TextInput from "@/components/atoms/inputs/TextInput.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CheckboxInput from "@/components/molecules/inputs/CheckboxInput.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "EditAutomaticReviewAppsModal",
  components: {
    DocLink,
    TextInput,
    CheckboxInput,
    SimpleModal,
    SCButton,
    Field,
    Form,
    FormAlert,
  },
  mixins: [FormModalHandlerMixin],
  props: {
    owner: Object,
    scmRepoLink: Object,
  },
  emits: ["modalClosed"],

  computed: {
    billingProfileRoute() {
      return { name: Routes.Billing.Profile };
    },
    paymentMethodsRoute() {
      return { name: Routes.Billing.PaymentMethods };
    },
    paymentRequired() {
      if (!this.formHandler.isError) return false;

      return this.formHandler.errorsInfo.paymentRequired;
    },
    hasErrorMessages() {
      if (!this.formHandler.isError) return false;

      return this.formHandler.errors.$base.length > 0;
    },
    currentUserIsOwner() {
      return this.owner.id === this.currentUser.id;
    },
  },
});
</script>

<i18n>
en:
  title: "Automatic deployment of review apps"
  subtitle: "Edit review apps configuration."
  buttonName: "Update"
  enabledLabel: "I want to enable automatic review apps"
  autoDestroy: "Auto destroy review apps when"
  autoDestroyPrLabel: "the pull request is closed"
  autoDestroyNoEventLabel: "no deploy or commits event have occurred"
  allowForkLabel: "Auto deploy review apps from forks"
  allowForkText: "Only allow automatic deployment of review apps from forks if you trust the owners of those forks. This could lead to security issues."
  allowForkCheckbox: "I want to allow review apps coming from forks"
  after: "delete after"
  during: "during"
  hours: "hour"
  billingProfile: "billing profile"
  paymentMethod: "a payment method"
  paymentRequired:
    owner: "You must end the free trial to create a review app. Please fill your "
    andAdd: " and add "
    collaborator: "(the owner) must end the free trial to create a review app"
  warning:
    text: "Automatic Review Apps will allow anyone with pull/merge requests capability on repository to automatically deploy any change in a clone of the parent app. Scalingo will not automatically deploy a review app that comes from a fork."
    more: "Learn more"
fr:
  title: "Déploiement automatique des review apps"
  subtitle: "Modifier la configuration des review apps."
  buttonName: "Mettre à jour"
  enabledLabel: "Je souhaite activer les review apps automatiques"
  autoDestroy: "Autodétruit les review apps quand"
  autoDestroyPrLabel: "la pull request est fermée"
  autoDestroyNoEventLabel: "aucun deploiement ou commit n'a eu lieu"
  allowForkLabel: "Auto déploiement des review apps provenant de forks"
  allowForkText: "N'autorisez le déploiement automatique de review apps à partir de forks que si vous faites confiance aux propriétaires de ces forks. Cela pourrait entraîner des problèmes de sécurité."
  allowForkCheckbox: "Je souhaite activer les review apps provenant de forks"
  after: "supprimer après"
  during: "pendant"
  billingProfile: "profil de facturation"
  paymentMethod: "un moyen de paiement"
  hours: "heure"
  paymentRequired:
    owner: "Vous devez mettre fin à l'essai gratuit pour créer une review app. Veuillez remplir votre "
    andAdd: " et ajouter "
    collaborator: "(le⋅la propriétaire) doit mettre fin à l'essai gratuit pour créer une review app"
  warning:
    text: "Les review apps automatiques permettent à toute personne ayant les droits suffisants pour créer des pull/merge requests sur le dépôt de code source, de déployer automatiquement des review apps. Scalingo ne déploiera pas de review app si son code provient d'un fork."
    more: "En savoir plus"
</i18n>
