<template>
  <main>
    <ChoiceCard
      :enabled="true"
      :addons="addons"
      :addonProviders="addonProviders"
      @addon-provider-clicked="(e) => $emit('addonProviderClicked', e)"
    ></ChoiceCard>
    <CardSeparator />
    <PlanCard :enabled="false" />
    <CardSeparator />
    <ValidationCard :enabled="false" />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import ChoiceCard from "@/components/parts/app/newAddons/ChoiceCard.vue";
import PlanCard from "@/components/parts/app/newAddons/PlanCard.vue";
import ValidationCard from "@/components/parts/app/newAddons/ValidationCard.vue";

export default defineComponent({
  name: "AddonCreationStep1",
  components: {
    ValidationCard,
    PlanCard,
    ChoiceCard,
    CardSeparator,
  },
  props: {
    addons: Object,
    addonProviders: Object,
  },
  emits: ["addonProviderClicked"],
  data() {
    return {};
  },
  methods: {},
});
</script>
