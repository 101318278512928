<template>
  <main class="flex flex-col space-y-4 mt-8">
    <Card>
      <template #header>
        <CardHeader :title="$t('title')" :subtitle="idleQueries">
          <template #buttons>
            <SCButton
              :loading="fakeLoadRefresh"
              @click="
                listQueries();
                setLoadingButton('fakeLoadRefresh');
              "
            >
              {{ $t("refresh") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <LoadingCardState v-if="isLoading" />
        <div v-else id="table-container" class="overflow-x-auto">
          <RunningQueriesList
            :queriesListActive="queriesListActive"
            :cancelQuery="cancelQuery"
            :terminateQuery="terminateQuery"
          >
          </RunningQueriesList>
        </div>
      </template>
    </Card>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import RunningQueriesList from "@/components/parts/db/runningQueries/RunningQueriesList.vue";

export default defineComponent({
  name: "DbRunningQueries",
  components: {
    // ActivateExtension,
    RunningQueriesList,
    CardHeader,
    Card,
    SCButton,
    LoadingCardState,
  },
  props: {
    db: {
      type: Object,
      required: true,
    },
    queriesListActive: {
      type: Array,
      required: false,
    },
    queriesListIdle: {
      type: Array,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
    listQueries: {
      type: Function,
      required: true,
    },
    cancelQuery: {
      type: Function,
      required: true,
    },
    terminateQuery: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fakeLoadRefresh: false,
    };
  },
  computed: {
    idleQueries() {
      return this.$i18n.t("idleQueries") + this.queriesListIdle.length;
    },
  },
  methods: {
    setLoadingButton(varName) {
      this[varName] = true;
      setTimeout(() => {
        this[varName] = false;
      }, 500);
    },
  },
});
</script>

<i18n>
  en:
    title: "Running Queries"
    refresh: "Refresh queries"
    idleQueries: "Idle Connections: "
  fr:
    title: "Requêtes en cours d’exécution"
    refresh: "Actualiser les requêtes"
    idleQueries: "Connexions en attente : "
</i18n>
