<template>
  <main class="mt-8">
    <DbCartridge
      :app="app"
      :addon="addon"
      :db="db"
      :dbVersion="dbVersion"
      @show-available-upgrade="(e) => $emit('showAvailableUpgrade', e)"
    />
    <div class="flex flex-col mid-xl:grid mid-xl:grid-cols-2 gap-4 mt-6">
      <InformationCard
        v-if="dbPlan.promiseInfo.isSuccess && dbMetrics.promiseInfo.isSuccess"
        :app="app"
        :dbPlan="dbPlan"
        :db="db"
        :dbMetrics="dbMetrics"
        class="col-span-2"
      />
      <WarningsCard
        v-if="dbPlan.promiseInfo.isSuccess && dbMetrics.promiseInfo.isSuccess"
        :app="app"
        :dbPlan="dbPlan"
        :db="db"
        :dbMetrics="dbMetrics"
        :addon="addon"
        class="col-span-2"
      />
      <ConnectCard :db="db" :user="user" :app="app" :variables="variables" />
      <BackupsCard
        v-if="lastBackup.promiseInfo.isSuccess && dbPlan.promiseInfo.isSuccess"
        :lastBackup="lastBackup"
        :oldestBackup="oldestBackup"
        :dbPlan="dbPlan"
        :db="db"
        :app="app"
        :addon="addon"
      />
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackupsCard from "@/components/parts/db/dbOverview/BackupsCard.vue";
import ConnectCard from "@/components/parts/db/dbOverview/ConnectCard.vue";
import DbCartridge from "@/components/parts/db/dbOverview/DbCartridge.vue";
import InformationCard from "@/components/parts/db/dbOverview/InformationCard.vue";
import WarningsCard from "@/components/parts/db/dbOverview/WarningsCard.vue";

export default defineComponent({
  name: "DbOverview",
  components: {
    WarningsCard,
    DbCartridge,
    ConnectCard,
    BackupsCard,
    InformationCard,
  },
  props: {
    app: Object,
    addon: Object,
    db: Object,
    dbPlan: Object,
    user: Object,
    dbVersion: Object,
    lastBackup: Object,
    oldestBackup: Object,
    dbMetrics: Object,
    variables: Object,
  },
  emits: ["showAvailableUpgrade", "cancelDBUpgrade", "upgradeDB"],
});
</script>
