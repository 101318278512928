<template>
  <div>
    <DeploymentEvent
      v-if="event.type === 'deployment'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <RestartEvent
      v-else-if="event.type === 'restart'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <ScaleEvent
      v-else-if="event.type === 'scale'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewCollaboratorEvent
      v-else-if="event.type === 'new_collaborator'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditVariablesEvent
      v-else-if="event.type === 'edit_variables'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditVariableEvent
      v-else-if="event.type === 'edit_variable'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewVariableEvent
      v-else-if="event.type === 'new_variable'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <UpgradeAddonEvent
      v-else-if="event.type === 'upgrade_addon'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <AcceptCollaboratorEvent
      v-else-if="event.type === 'accept_collaborator'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <LinkScmEvent
      v-else-if="event.type === 'link_scm'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewDomainEvent
      v-else-if="event.type === 'new_domain'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteDomainEvent
      v-else-if="event.type === 'delete_domain'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <CrashEvent
      v-else-if="event.type === 'crash'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <CreateContactEvent
      v-else-if="event.type === 'create_contact'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <UpdateContactEvent
      v-else-if="event.type === 'update_contact'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteContactEvent
      v-else-if="event.type === 'delete_contact'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteAlertEvent
      v-else-if="event.type === 'delete_alert'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewAlertEvent
      v-else-if="event.type === 'new_alert'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <AlertEvent
      v-else-if="event.type === 'alert'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <RunEvent
      v-else-if="event.type === 'run'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
      :eventComplement="eventComplement"
      @body-opened="(e) => $emit('bodyOpened', e)"
    />

    <AddonUpdatedEvent
      v-else-if="event.type === 'addon_updated'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewAddonEvent
      v-else-if="event.type === 'new_addon'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewAppEvent
      v-else-if="event.type === 'new_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditDomainEvent
      v-else-if="event.type === 'edit_domain'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditAutoscalerEvent
      v-else-if="event.type === 'edit_autoscaler'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewAutoscalerEvent
      v-else-if="event.type === 'new_autoscaler'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteAutoscalerEvent
      v-else-if="event.type === 'delete_autoscaler'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteAppEvent
      v-else-if="event.type === 'delete_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditAppEvent
      v-else-if="event.type === 'edit_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteAddonEvent
      v-else-if="event.type === 'delete_addon'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewNotifierEvent
      v-else-if="event.type === 'new_notifier'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditNotifierEvent
      v-else-if="event.type === 'edit_notifier'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewKeyEvent
      v-else-if="event.type === 'new_key'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <RenameAppEvent
      v-else-if="event.type === 'rename_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <StartRegionMigrationEvent
      v-else-if="event.type === 'start_region_migration'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteCollaboratorEvent
      v-else-if="event.type === 'delete_collaborator'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <TransferAppEvent
      v-else-if="event.type === 'transfer_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <UpgradeDatabaseEvent
      v-else-if="event.type === 'upgrade_database'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteVariableEvent
      v-else-if="event.type === 'delete_variable'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteNotifierEvent
      v-else-if="event.type === 'delete_notifier'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteIntegrationEvent
      v-else-if="event.type === 'delete_integration'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteKeyEvent
      v-else-if="event.type === 'delete_key'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteTokenEvent
      v-else-if="event.type === 'delete_token'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditKeyEvent
      v-else-if="event.type === 'edit_key'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <LinkGithubEvent
      v-else-if="event.type === 'link_github'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewIntegrationEvent
      v-else-if="event.type === 'new_integration'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewTokenEvent
      v-else-if="event.type === 'new_token'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <RegenerateTokenEvent
      v-else-if="event.type === 'regenerate_token'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <ResumeAddonEvent
      v-else-if="event.type === 'resume_addon'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <StopAppEvent
      v-else-if="event.type === 'stop_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <SuspendAddonEvent
      v-else-if="event.type === 'suspend_addon'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <TfaEnabledEvent
      v-else-if="event.type === 'tfa_enabled'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <UnlinkGithubEvent
      v-else-if="event.type === 'unlink_github'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <UnlinkScmEvent
      v-else-if="event.type === 'unlink_scm'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <AddPaymentMethodEvent
      v-else-if="event.type === 'add_payment_method'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewUserEvent
      v-else-if="event.type === 'new_user'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <TfaDisabledEvent
      v-else-if="event.type === 'tfa_disabled'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <AddCreditEvent
      v-else-if="event.type === 'add_credit'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <AddVoucherEvent
      v-else-if="event.type === 'add_voucher'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <AuthorizeGithubEvent
      v-else-if="event.type === 'authorize_github'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <PaymentAttemptEvent
      v-else-if="event.type === 'payment_attempt'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <RevokeGithubEvent
      v-else-if="event.type === 'revoke_github'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <RepeatedCrashEvent
      v-else-if="event.type === 'repeated_crash'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewLogDrainEvent
      v-else-if="event.type === 'new_log_drain'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteLogDrainEvent
      v-else-if="event.type === 'delete_log_drain'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <CreateDataAccessConsentEvent
      v-else-if="event.type === 'create_data_access_consent'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <EditHDSContactEvent
      v-else-if="event.type === 'edit_hds_contact'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <LoginSuccessEvent
      v-else-if="event.type === 'login_success'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <LoginFailureEvent
      v-else-if="event.type === 'login_failure'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <LoginLockEvent
      v-else-if="event.type === 'login_lock'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <LoginUnlockSuccessEvent
      v-else-if="event.type === 'login_unlock_success'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <PasswordResetSuccessEvent
      v-else-if="event.type === 'password_reset_success'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <PasswordResetQueryEvent
      v-else-if="event.type === 'password_reset_query'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <!-- + Force ssl with be transformed into force-tls in the future -->
    <DatabaseAddForceTlsEvent
      v-else-if="
        event.type === 'database/add_feature' &&
        event.type_data.feature === 'force-ssl'
      "
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />
    <DatabaseRemoveForceTlsEvent
      v-else-if="
        event.type === 'database/remove_feature' &&
        event.type_data.feature === 'force-ssl'
      "
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />
    <DatabaseAddForceTlsEvent
      v-else-if="
        event.type === 'database/add_feature' &&
        event.type_data.feature === 'force-tls'
      "
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />
    <DatabaseRemoveForceTlsEvent
      v-else-if="
        event.type === 'database/remove_feature' &&
        event.type_data.feature === 'force-tls'
      "
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />
    <!-- - Force ssl with be transformed into force-tls in the future-->

    <DatabaseAddPubliclyAvailableEvent
      v-else-if="
        event.type === 'database/add_feature' &&
        event.type_data.feature === 'publicly-available'
      "
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DatabaseRemovePubliclyAvailableEvent
      v-else-if="
        event.type === 'database/remove_feature' &&
        event.type_data.feature === 'publicly-available'
      "
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <NewAddonLogDrainEvent
      v-else-if="event.type === 'new_addon_log_drain'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DeleteAddonLogDrainEvent
      v-else-if="event.type === 'delete_addon_log_drain'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <StackChangedEvent
      v-else-if="event.type === 'stack_changed'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DatabaseAddFeature
      v-else-if="event.type === 'database/add_feature'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DatabaseRemoveFeature
      v-else-if="event.type === 'database/remove_feature'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <UpdateScmEvent
      v-else-if="event.type === 'update_scm'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <CreateReviewAppEvent
      v-else-if="event.type === 'create_review_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <DestroyReviewAppEvent
      v-else-if="event.type === 'destroy_review_app'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <PlanDatabaseMaintenanceEvent
      v-else-if="event.type === 'plan_database_maintenance'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <StartDatabaseMaintenanceEvent
      v-else-if="event.type === 'start_database_maintenance'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <CompleteDatabaseMaintenanceEvent
      v-else-if="event.type === 'complete_database_maintenance'"
      :event="event"
      :long="long"
      :displayAuthor="displayAuthor"
      :displayAppName="displayAppName"
      :withEventLink="withEventLink"
    />

    <template v-else>
      <GenericEvent
        :event="event"
        :long="long"
        :displayAuthor="displayAuthor"
        :displayAppName="displayAppName"
        :withEventLink="withEventLink"
      ></GenericEvent>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import AcceptCollaboratorEvent from "@/components/parts/events/AcceptCollaboratorEvent.vue";
import AddCreditEvent from "@/components/parts/events/AddCreditEvent.vue";
import AddonUpdatedEvent from "@/components/parts/events/AddonUpdatedEvent.vue";
import AddPaymentMethodEvent from "@/components/parts/events/AddPaymentMethodEvent.vue";
import AddVoucherEvent from "@/components/parts/events/AddVoucherEvent.vue";
import AlertEvent from "@/components/parts/events/AlertEvent.vue";
import AuthorizeGithubEvent from "@/components/parts/events/AuthorizeGithubEvent.vue";
import CompleteDatabaseMaintenanceEvent from "@/components/parts/events/CompleteDatabaseMaintenanceEvent.vue";
import CrashEvent from "@/components/parts/events/CrashEvent.vue";
import CreateContactEvent from "@/components/parts/events/CreateContactEvent.vue";
import CreateDataAccessConsentEvent from "@/components/parts/events/CreateDataAccessConsentEvent.vue";
import CreateReviewAppEvent from "@/components/parts/events/CreateReviewAppEvent.vue";
import DatabaseAddFeature from "@/components/parts/events/DatabaseAddFeature.vue";
import DatabaseAddForceTlsEvent from "@/components/parts/events/DatabaseAddForceTlsEvent.vue";
import DatabaseAddPubliclyAvailableEvent from "@/components/parts/events/DatabaseAddPubliclyAvailableEvent.vue";
import DatabaseRemoveFeature from "@/components/parts/events/DatabaseRemoveFeature.vue";
import DatabaseRemoveForceTlsEvent from "@/components/parts/events/DatabaseRemoveForceTlsEvent.vue";
import DatabaseRemovePubliclyAvailableEvent from "@/components/parts/events/DatabaseRemovePubliclyAvailableEvent.vue";
import DeleteAddonEvent from "@/components/parts/events/DeleteAddonEvent.vue";
import DeleteAddonLogDrainEvent from "@/components/parts/events/DeleteAddonLogDrainEvent.vue";
import DeleteAlertEvent from "@/components/parts/events/DeleteAlertEvent.vue";
import DeleteAppEvent from "@/components/parts/events/DeleteAppEvent.vue";
import DeleteAutoscalerEvent from "@/components/parts/events/DeleteAutoscalerEvent.vue";
import DeleteCollaboratorEvent from "@/components/parts/events/DeleteCollaboratorEvent.vue";
import DeleteContactEvent from "@/components/parts/events/DeleteContactEvent.vue";
import DeleteDomainEvent from "@/components/parts/events/DeleteDomainEvent.vue";
import DeleteIntegrationEvent from "@/components/parts/events/DeleteIntegrationEvent.vue";
import DeleteKeyEvent from "@/components/parts/events/DeleteKeyEvent.vue";
import DeleteLogDrainEvent from "@/components/parts/events/DeleteLogDrainEvent.vue";
import DeleteNotifierEvent from "@/components/parts/events/DeleteNotifierEvent.vue";
import DeleteTokenEvent from "@/components/parts/events/DeleteTokenEvent.vue";
import DeleteVariableEvent from "@/components/parts/events/DeleteVariableEvent.vue";
import DeploymentEvent from "@/components/parts/events/DeploymentEvent.vue";
import DestroyReviewAppEvent from "@/components/parts/events/DestroyReviewAppEvent.vue";
import EditAppEvent from "@/components/parts/events/EditAppEvent.vue";
import EditAutoscalerEvent from "@/components/parts/events/EditAutoscalerEvent.vue";
import EditDomainEvent from "@/components/parts/events/EditDomainEvent.vue";
import EditHDSContactEvent from "@/components/parts/events/EditHDSContactEvent.vue";
import EditKeyEvent from "@/components/parts/events/EditKeyEvent.vue";
import EditNotifierEvent from "@/components/parts/events/EditNotifierEvent.vue";
import EditVariableEvent from "@/components/parts/events/EditVariableEvent.vue";
import EditVariablesEvent from "@/components/parts/events/EditVariablesEvent.vue";
import GenericEvent from "@/components/parts/events/GenericEvent.vue";
import LinkGithubEvent from "@/components/parts/events/LinkGithubEvent.vue";
import LinkScmEvent from "@/components/parts/events/LinkScmEvent.vue";
import LoginFailureEvent from "@/components/parts/events/LoginFailureEvent.vue";
import LoginLockEvent from "@/components/parts/events/LoginLockEvent.vue";
import LoginSuccessEvent from "@/components/parts/events/LoginSuccessEvent.vue";
import LoginUnlockSuccessEvent from "@/components/parts/events/LoginUnlockSuccessEvent.vue";
import NewAddonEvent from "@/components/parts/events/NewAddonEvent.vue";
import NewAddonLogDrainEvent from "@/components/parts/events/NewAddonLogDrainEvent.vue";
import NewAlertEvent from "@/components/parts/events/NewAlertEvent.vue";
import NewAppEvent from "@/components/parts/events/NewAppEvent.vue";
import NewAutoscalerEvent from "@/components/parts/events/NewAutoscalerEvent.vue";
import NewCollaboratorEvent from "@/components/parts/events/NewCollaboratorEvent.vue";
import NewDomainEvent from "@/components/parts/events/NewDomain.vue";
import NewIntegrationEvent from "@/components/parts/events/NewIntegrationEvent.vue";
import NewKeyEvent from "@/components/parts/events/NewKeyEvent.vue";
import NewLogDrainEvent from "@/components/parts/events/NewLogDrainEvent.vue";
import NewNotifierEvent from "@/components/parts/events/NewNotifierEvent.vue";
import NewTokenEvent from "@/components/parts/events/NewTokenEvent.vue";
import NewUserEvent from "@/components/parts/events/NewUserEvent.vue";
import NewVariableEvent from "@/components/parts/events/NewVariableEvent.vue";
import PasswordResetQueryEvent from "@/components/parts/events/PasswordResetQueryEvent.vue";
import PasswordResetSuccessEvent from "@/components/parts/events/PasswordResetSuccessEvent.vue";
import PaymentAttemptEvent from "@/components/parts/events/PaymentAttemptEvent.vue";
import PlanDatabaseMaintenanceEvent from "@/components/parts/events/PlanDatabaseMaintenanceEvent.vue";
import RegenerateTokenEvent from "@/components/parts/events/RegenerateTokenEvent.vue";
import RenameAppEvent from "@/components/parts/events/RenameAppEvent.vue";
import RepeatedCrashEvent from "@/components/parts/events/RepeatedCrashEvent.vue";
import RestartEvent from "@/components/parts/events/RestartEvent.vue";
import ResumeAddonEvent from "@/components/parts/events/ResumeAddonEvent.vue";
import RevokeGithubEvent from "@/components/parts/events/RevokeGithubEvent.vue";
import RunEvent from "@/components/parts/events/RunEvent.vue";
import ScaleEvent from "@/components/parts/events/ScaleEvent.vue";
import StackChangedEvent from "@/components/parts/events/StackChangedEvent.vue";
import StartDatabaseMaintenanceEvent from "@/components/parts/events/StartDatabaseMaintenanceEvent.vue";
import StartRegionMigrationEvent from "@/components/parts/events/StartRegionMigrationEvent.vue";
import StopAppEvent from "@/components/parts/events/StopAppEvent.vue";
import SuspendAddonEvent from "@/components/parts/events/SuspendAddonEvent.vue";
import TfaDisabledEvent from "@/components/parts/events/TfaDisabledEvent.vue";
import TfaEnabledEvent from "@/components/parts/events/TfaEnabledEvent.vue";
import TransferAppEvent from "@/components/parts/events/TransferAppEvent.vue";
import UnlinkGithubEvent from "@/components/parts/events/UnlinkGithubEvent.vue";
import UnlinkScmEvent from "@/components/parts/events/UnlinkScmEvent.vue";
import UpdateContactEvent from "@/components/parts/events/UpdateContactEvent.vue";
import UpdateScmEvent from "@/components/parts/events/UpdateScmEvent.vue";
import UpgradeAddonEvent from "@/components/parts/events/UpgradeAddonEvent.vue";
import UpgradeDatabaseEvent from "@/components/parts/events/UpgradeDatabaseEvent.vue";

export default defineComponent({
  name: "EventSwitch",
  components: {
    DestroyReviewAppEvent,
    CreateReviewAppEvent,
    UpdateScmEvent,
    DatabaseRemoveFeature,
    DatabaseAddFeature,
    StackChangedEvent,
    DatabaseRemovePubliclyAvailableEvent,
    DatabaseAddPubliclyAvailableEvent,
    DatabaseRemoveForceTlsEvent,
    DatabaseAddForceTlsEvent,
    DeleteAddonLogDrainEvent,
    NewAddonLogDrainEvent,
    PasswordResetQueryEvent,
    PasswordResetSuccessEvent,
    DeleteLogDrainEvent,
    NewLogDrainEvent,
    RepeatedCrashEvent,
    NewKeyEvent,
    EditNotifierEvent,
    NewNotifierEvent,
    DeleteAddonEvent,
    EditAppEvent,
    DeleteAppEvent,
    DeleteAutoscalerEvent,
    NewAutoscalerEvent,
    EditAutoscalerEvent,
    EditDomainEvent,
    NewAppEvent,
    NewAddonEvent,
    AddonUpdatedEvent,
    RunEvent,
    AlertEvent,
    NewAlertEvent,
    DeleteAlertEvent,
    CrashEvent,
    DeleteDomainEvent,
    NewDomainEvent,
    LinkScmEvent,
    AcceptCollaboratorEvent,
    EditVariablesEvent,
    EditVariableEvent,
    DeploymentEvent,
    RestartEvent,
    ScaleEvent,
    NewCollaboratorEvent,
    GenericEvent,
    UpgradeAddonEvent,
    NewVariableEvent,
    RenameAppEvent,
    StartRegionMigrationEvent,
    DeleteCollaboratorEvent,
    TransferAppEvent,
    UpgradeDatabaseEvent,
    DeleteVariableEvent,
    DeleteNotifierEvent,
    DeleteIntegrationEvent,
    DeleteKeyEvent,
    DeleteTokenEvent,
    EditKeyEvent,
    LinkGithubEvent,
    NewIntegrationEvent,
    NewTokenEvent,
    RegenerateTokenEvent,
    ResumeAddonEvent,
    StopAppEvent,
    SuspendAddonEvent,
    TfaEnabledEvent,
    UnlinkGithubEvent,
    UnlinkScmEvent,
    AddPaymentMethodEvent,
    NewUserEvent,
    TfaDisabledEvent,
    AddCreditEvent,
    AddVoucherEvent,
    AuthorizeGithubEvent,
    PaymentAttemptEvent,
    RevokeGithubEvent,
    CreateDataAccessConsentEvent,
    CreateContactEvent,
    UpdateContactEvent,
    DeleteContactEvent,
    EditHDSContactEvent,
    LoginSuccessEvent,
    LoginFailureEvent,
    LoginLockEvent,
    LoginUnlockSuccessEvent,
    PlanDatabaseMaintenanceEvent,
    StartDatabaseMaintenanceEvent,
    CompleteDatabaseMaintenanceEvent,
  },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
    eventComplement: Object,
  },
  emits: ["bodyOpened"],
});
</script>
