<template>
  <SimpleModal size="w-1/2 xl:w-1/4" @modal-closed="$emit('modalClosed')">
    <template #title>
      {{ $t("title") }}
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template #body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.name"
                name="name"
                rules="required"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('domain', 'name')"
                  labelClass="capitalize"
                  :errors="errors"
                  @update:model-value="handleChange"
                ></TextInput>
              </Field>
            </div>

            <SCAlert v-if="globalErrors" kind="error">
              {{ globalErrors }}
            </SCAlert>

            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "AddDomainFormModal",
  components: {
    SimpleModal,
    SCButton,
    TextInput,
    SCAlert,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  emits: ["modalClosed"],
  computed: {
    globalErrors() {
      if (!this.formHandler.operation?.error?.data?.errors?.domain) {
        return null;
      }

      return this.formHandler.operation?.error.data.errors.domain.join(",");
    },
  },
});
</script>

<i18n>
en:
  title: "Add domain"
  subtitle: "Add domain to your app"
  buttonName: "Add"
fr:
  title: "Ajouter domaine"
  subtitle: "Ajouter un domaine à votre app"
  buttonName: "Ajouter"
</i18n>
