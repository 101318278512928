<template>
  <div>
    <div
      class="rounded-sm border-b border-scale-3 px-2 py-3 bg-scale-1 font-medium flex items-center justify-between space-x-1 cursor-pointer"
      @click="open = !open"
    >
      <div class="flex items-center space-x-1">
        <font-awesome-icon
          v-if="!open"
          size="2x"
          icon="caret-right"
          class="h-5 w-5"
        ></font-awesome-icon>
        <font-awesome-icon
          v-if="open"
          size="2x"
          icon="caret-down"
          class="h-5 w-5"
        ></font-awesome-icon>
        <span
          >{{ usage.app }}
          <span v-if="multipleRegions" class="text-scale-7"
            >- {{ usage.region }}</span
          ></span
        >
        &nbsp;
        <Pill v-if="usage.deleted_at" class="border" type="neutral">{{
          $t("deleted")
        }}</Pill>
      </div>
      <div class="text-scale-7">{{ price }}</div>
    </div>
    <div v-if="open">
      <table v-if="itemsToDisplay" class="table-fixed w-full text-scale-10">
        <thead class="font-normal">
          <tr class="text-scale-6 text-sm h-8">
            <td class="bg-scale-1 rounded-l-sm w-3/6 px-4">
              {{ $t("label.item") }}
            </td>
            <td class="bg-scale-1 w-2/6 text-right">
              {{ $t("label.duration") }}
            </td>
            <td class="bg-scale-1 rounded-r-sm w-1/6 text-right">
              <div class="flex justify-end items-baseline">
                <div>{{ $t("label.price") }}</div>
                <div class="text-scale-6 text-xs ml-1">{{ $t("exclVat") }}</div>
                ↓
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, k) in items" :key="k">
            <tr>
              <td class="px-4 py-2">{{ item.label }}</td>
              <td class="text-right">
                <template v-if="item.duration">
                  <span :title="humanizeDuration(item.duration * 60)">
                    {{ $t("duration", { count: item.duration }) }}
                  </span>
                </template>
              </td>
              <td class="text-right">{{ formatAmount(item.price) }}</td>
            </tr>
            <tr v-if="item.overusage">
              <td class="py-2">{{ item.label }} - {{ $t("overusage") }}</td>
              <td class="text-right">{{ item.disk_overusage }} Gb-h</td>
              <td class="text-right">
                <span
                  v-if="item.disk_overusage_future_price"
                  class="flex flex-col"
                >
                  <span class="line-through">{{
                    formatAmount(item.disk_overusage_future_price)
                  }}</span>
                  <span class="text-scale-7 text-sm">
                    {{ $t("future_price") }}
                  </span>
                </span>
                <span v-else>
                  {{ formatAmount(item.disk_overusage_price) }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-else class="text-center text-scale-7 py-2">
        {{ $t("nothing") }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Pill from "@/components/atoms/pills/Pill.vue";
import { formatAmount } from "@/lib/utils/currency";
import { humanizeDuration } from "@/lib/utils/time";

export default defineComponent({
  name: "AppPeriod",
  components: { Pill },
  props: {
    usage: Object,
    multipleRegions: Boolean,
  },
  data() {
    return { open: false };
  },
  computed: {
    items() {
      let items = [];
      for (const k1 in this.usage.runtimes) {
        const runtime = this.usage.runtimes[k1];
        items.push({
          label: this.runtimeLabel(runtime),
          duration: runtime.duration,
          price: runtime.price,
        });
      }
      for (const k2 in this.usage.addon_usages) {
        const addon = this.usage.addon_usages[k2];
        let item = {
          label: this.addonLabel(addon),
          duration: addon.duration,
          price: addon.price,
          overusage: false,
        };
        if (this.overusage(addon)) {
          item.overusage = true;
          item.disk_overusage = addon.disk_overusage;
          item.disk_overusage_price = addon.disk_overusage_price;
          item.disk_overusage_future_price = addon.disk_overusage_future_price;
        }
        items.push(item);
      }

      return items.slice().sort((a, b) => b.price - a.price);
    },
    price() {
      let price = 0;
      for (const k1 in this.usage.runtimes) {
        price += this.usage.runtimes[k1].price;
      }
      for (const k2 in this.usage.addon_usages) {
        price += this.usage.addon_usages[k2].price;
      }
      for (const k3 in this.usage.addon_usages) {
        const addon = this.usage.addon_usages[k3];
        if (!this.overusage(addon)) {
          continue;
        }
        price += addon.disk_overusage_price;
      }
      return formatAmount(price);
    },
    itemsToDisplay() {
      return (
        this.usage.runtimes.length > 0 || this.usage.addon_usages.length > 0
      );
    },
  },
  methods: {
    overusage(addon) {
      return addon.disk_overusage && addon.disk_overusage !== 0;
    },
    runtimeLabel(runtime) {
      return (
        this.$t("label.container") +
        " -  " +
        this.$t("label.type") +
        " " +
        runtime.type +
        " - " +
        this.$t("label.size") +
        " " +
        runtime.size_name
      );
    },
    addonLabel(addon) {
      return (
        this.$t("label.addon") +
        " " +
        addon.provider_name +
        " - " +
        addon.plan_name
      );
    },
    formatAmount,
    humanizeDuration,
  },
});
</script>

<i18n>
en:
  label:
    item: "Resource"
    duration: "Consumption"
    price: "Amount"
    container: "Container"
    size: "Size"
    type: "Type"
    addon: "Addon"
  duration: "none | 1 minute | {count} minutes"
  nothing: "No data for the selected period"
  deleted: "Deleted"
  exclVat: "excl. VAT"
  future_price: "€0 (free of charge until May 1st)"
  overusage: "Overusage"
fr:
  label:
    item: "Ressource"
    duration: "Consommation"
    price: "Montant"
    container: "Container"
    size: "Taille"
    type: "Type"
    addon: "Addon"
  duration: "aucune | 1 minute | {count} minutes"
  nothing: "Aucune donnée pour la période sélectionnée"
  deleted: "Supprimée"
  exclVat: "excl. TVA"
  future_price: "0€ (offert jusqu’au 1er mai)"
  overusage: "Surconsommation"
</i18n>
