<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"> </CardHeader>
    </template>
    <template #body>
      <LoadingCardState v-if="isLoading" class="h-64 justify-center" />
      <EmptyCardState v-else-if="isError" class="h-64 justify-center">
        <template #icon><CrossGlyph /></template>
        <span class="capitalize">{{ promiseInfo.err.data.error }}</span>
      </EmptyCardState>
      <ContainerCPU v-else-if="isLoaded" :datasets="datasets" />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ContainerCPU from "@/components/organisms/graphs/ContainerCPU.vue";
import { makeDataSet } from "@/lib/metrics";

export default defineComponent({
  name: "CpuCard",
  components: {
    Card,
    CardHeader,
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
    ContainerCPU,
  },
  props: {
    promiseInfo: Object,
    container: Object,
    cpuData: Array,
    graphSize: Object,
    index: Number,
  },
  computed: {
    isLoaded() {
      return this.promiseInfo.isSuccess;
    },
    isLoading() {
      return this.promiseInfo.isLoading;
    },
    isError() {
      return this.promiseInfo.isError;
    },
    cpuDataset() {
      return makeDataSet(this.$i18n, "containers", "cpu", this.cpuData);
    },
    datasets() {
      return [this.cpuDataset];
    },
  },
});
</script>

<i18n>
en:
  title: "CPU"
  subtitle: "CPU load for this instance."
fr:
  title: "CPU"
  subtitle: "Charge CPU de cette instance."
</i18n>
