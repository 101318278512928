<template>
  <SimpleModal
    :hasBorder="true"
    size="w-3/4 lg:w-1/2 2xl:w-1/4"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      <div class="mb-4">
        <slot name="title" />
      </div>
    </template>

    <template #body>
      <div class="mt-6">
        <slot name="question" />
      </div>

      <div v-if="withInfo" class="mt-2 text-scale-6 text-sm">
        <slot name="info" />
      </div>

      <div
        v-if="confirmationValue !== ''"
        class="text-scale-9 font-medium text-sm mt-6"
      >
        <p><slot name="confirmation" /></p>
        <TextInput v-model="confirmedValue" class="mt-2"></TextInput>
      </div>

      <div class="flex mt-6">
        <SCButton
          block
          kind="danger"
          size="lg"
          class="flex-grow mt-4"
          :disabled="!isConfirmed"
          :loading="formHandler?.isSubmitting"
          @click="submitForm"
        >
          <span class="flex justify-center items-center">
            <BinGlyph v-if="binIcon" class="-mt-1 mr-2" />
            <slot name="button" />
          </span>
        </SCButton>
      </div>

      <div class="flex">
        <SCButton
          block
          kind="neutral"
          size="lg"
          class="flex flex-grow mt-4"
          @click="$emit('modalClosed')"
        >
          {{ $t("cancelButton") }}
        </SCButton>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import TextInput from "@/components/atoms/inputs/TextInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { formHandlerMixin } from "@/mixins/form_handler_mixin";

export default defineComponent({
  name: "DeleteModal",
  components: {
    TextInput,
    BinGlyph,
    SCButton,
    SimpleModal,
  },
  mixins: [formHandlerMixin({ modal: true, withoutHandler: true })],
  props: {
    withInfo: Boolean,
    confirmationValue: {
      type: String,
      default: "",
    },
    binIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["formSubmitted", "modalClosed"],
  data() {
    return {
      confirmedValue: "",
    };
  },
  computed: {
    isConfirmed() {
      if (this.confirmationValue === "") {
        return true;
      }
      return this.confirmationValue === this.confirmedValue;
    },
  },
});
</script>

<i18n>
en:
  cancelButton: "Cancel"
fr:
  cancelButton: "Annuler"
</i18n>
