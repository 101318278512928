<template>
  <div>
    <template v-if="isLoading">
      <LoadingCardState />
    </template>

    <template v-if="isFinished">
      <div v-for="(item, index) in addonCategories" :key="index">
        <div class="font-medium">
          {{ item }}
          <div class="py-4 grid gap-4 grid-cols-1 pv:grid-cols-4">
            <div
              v-for="(subItem, subIndex) in addonListByCategory(item)"
              :key="subIndex"
              class="xl:w-4/5 cursor-pointer"
              @click="addonProviderClicked(subItem)"
            >
              <AddonProvider
                :selectedAddonProvider="selectedAddonProvider"
                :addonProvider="subItem"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AddonProvider from "@/components/parts/app/newAddons/AddonProvider.vue";

export default defineComponent({
  name: "AddonProviders",
  components: { AddonProvider, LoadingCardState },
  props: {
    addons: Object,
    addonProviders: Object,
    selectedAddonProvider: Object,
  },
  emits: ["addonProviderClicked"],
  data: function () {
    return {};
  },
  computed: {
    isLoading() {
      const apFetch = this.addonProviders.latestFetch;
      const adFetch = this.addons.latestFetch;

      let val = !apFetch.hasStarted || apFetch.isLoading;

      if (adFetch) {
        val = val || !adFetch.hasStarted || adFetch.isLoading;
      }

      return val;
    },
    isFinished() {
      const apFetch = this.addonProviders.latestFetch;
      const adFetch = this.addons.latestFetch;

      let val = apFetch.isFinished;

      if (adFetch) {
        val = val && adFetch.isFinished;
      }

      return val;
    },
    availableProviders() {
      return this.addonProviders.items;
    },
    addonCategories: function () {
      const categories = this.availableProviders.map(
        (addon) => addon.category.name,
      );
      return categories.filter(
        (categoryName, index) => categories.indexOf(categoryName) === index,
      );
    },
  },
  methods: {
    addonProviderClicked(addon) {
      this.$emit("addonProviderClicked", addon);
    },
    addonListByCategory(category) {
      return this.availableProviders.filter(
        (addon) => addon.category.name === category,
      );
    },
  },
});
</script>
