<template>
  <div>
    <GitPushDeployCartridge
      :app="app"
      :isSelected="selectedCartridge === 'git-push'"
      @cartridge-selected="cartridgeSelected"
    ></GitPushDeployCartridge>

    <template v-for="integration in scmIntegrations.items">
      <template v-if="['github', 'gitlab'].includes(integration.scm_type)">
        <SaasSCMDeployCartridge
          :key="integration.id"
          class="mt-4"
          :scmType="integration.scm_type"
          :integration="integration"
          :scmRepoLink="scmRepoLink"
          :isSelected="selectedCartridge === integration.scm_type"
          @cartridge-selected="cartridgeSelected"
          @start-s-c-m-link="startSCMLink(integration)"
          @unlink-repo="$emit('unlinkRepo')"
        ></SaasSCMDeployCartridge>
      </template>

      <template v-else>
        <HostedSCMDeployCartridge
          :key="integration.id"
          class="mt-4"
          :scmType="integration.scm_type"
          :integration="integration"
          :scmRepoLink="scmRepoLink"
          :isSelected="selectedCartridge === integration.scm_type"
          @cartridge-selected="cartridgeSelected"
          @start-s-c-m-link="startSCMLink(integration)"
          @unlink-repo="$emit('unlinkRepo')"
        ></HostedSCMDeployCartridge>
      </template>
    </template>

    <template v-for="(scmType, i) in unconnectedSaasSCMTypes" :key="i">
      <SaasSCMDeployCartridge
        class="mt-4"
        :scmType="scmType"
        :scmRepoLink="scmRepoLink"
        :isSelected="selectedCartridge === scmType"
        @cartridge-selected="cartridgeSelected"
        @connect="connectSCMIntegration(scmType)"
      ></SaasSCMDeployCartridge>
    </template>

    <HostedSCMDeployCartridge
      v-if="showFullList"
      class="mt-4"
      scmType="github-enterprise"
      :integration="githubEnterpriseIntegration"
      :scmRepoLink="scmRepoLink"
      :isSelected="selectedCartridge === 'github-enterprise'"
      @cartridge-selected="cartridgeSelected"
      @connect="connectHostedSCMIntegration('github-enterprise')"
      @start-s-c-m-link="startSCMLink(githubEnterpriseIntegration)"
      @unlink-repo="$emit('unlinkRepo')"
    ></HostedSCMDeployCartridge>

    <HostedIntegrationsLinks
      class="mt-4"
      @connect-hosted-s-c-m-integration="(e) => connectHostedSCMIntegration(e)"
    />

    <HostedSCMModal
      v-if="connectIntegrationHandler"
      :scmType="connectIntegrationHandler.scmType"
      :formHandler="connectIntegrationHandler"
      @modal-closed="$emit('cancelHostedSCMConnection')"
      @form-submitted="(e) => $emit('confirmHostedSCMConnection', e)"
    />

    <SCMLinkModal
      v-if="showSCMLinkModal"
      :currentSCMIntegration="currentSCMIntegration"
      :scmOrgs="scmOrgs"
      :hasMoreOrgs="hasMoreOrgs"
      :scmRepos="scmRepos"
      :scmBranches="scmBranches"
      :repoLinkOperation="repoLinkOperation"
      :listOrgsInfo="listOrgsInfo"
      :listReposInfo="listReposInfo"
      :listBranchesInfo="listBranchesInfo"
      @scm-org-selected="(eventArgs) => $emit('scmOrgSelected', eventArgs)"
      @scm-repo-searched="(e) => $emit('scmRepoSearched', e)"
      @scm-repo-selected="(eventArgs) => $emit('scmRepoSelected', eventArgs)"
      @scm-branch-selected="
        (eventArgs) => $emit('scmBranchSelected', eventArgs)
      "
      @submit="(eventArgs) => $emit('finishSCMRepoLink', eventArgs)"
      @modal-closed="$emit('stopSCMLink')"
      @load-more-organizations="
        (eventArgs) => $emit('loadMoreOrganizations', eventArgs)
      "
    ></SCMLinkModal>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import GitPushDeployCartridge from "@/components/parts/configure/app/GitPushDeployCartridge.vue";
import HostedIntegrationsLinks from "@/components/parts/configure/app/HostedIntegrationsLinks.vue";
import HostedSCMDeployCartridge from "@/components/parts/configure/app/HostedSCMDeployCartridge.vue";
import HostedSCMModal from "@/components/parts/configure/app/HostedSCMModal.vue";
import SaasSCMDeployCartridge from "@/components/parts/configure/app/SaasSCMDeployCartridge.vue";
import SCMLinkModal from "@/components/parts/configure/app/SCMLinkModal.vue";

export default defineComponent({
  name: "DeployCartridge",
  components: {
    HostedIntegrationsLinks,
    SaasSCMDeployCartridge,
    GitPushDeployCartridge,
    HostedSCMDeployCartridge,
    SCMLinkModal,
    HostedSCMModal,
  },
  props: {
    app: Object,
    scmRepoLink: Object,
    scmIntegrations: Object,
    selectedCartridge: String,
    currentSCMIntegration: Object,
    scmOrgs: Array,
    hasMoreOrgs: Boolean,
    scmRepos: Array,
    scmBranches: Array,
    connectIntegrationHandler: Object,
    repoLinkOperation: Object,
    listOrgsInfo: Object,
    listReposInfo: Object,
    listBranchesInfo: Object,
  },
  emits: [
    "cancelHostedSCMConnection",
    "cartridgeSelected",
    "confirmHostedSCMConnection",
    "connectSCMIntegration",
    "finishSCMRepoLink",
    "scmBranchSelected",
    "scmOrgSelected",
    "scmRepoSearched",
    "scmRepoSelected",
    "startHostedSCMConnection",
    "startSCMLink",
    "stopSCMLink",
    "unlinkRepo",
    "loadMoreOrganizations",
  ],
  data() {
    return {
      showFullList: false,
      openedCartridge: "",
    };
  },
  computed: {
    showSCMLinkModal() {
      return !!this.currentSCMIntegration;
    },
    githubIntegration() {
      return this.scmIntegrations.items.find((integ) => {
        return integ.scm_type === "github";
      });
    },
    gitlabIntegration() {
      return this.scmIntegrations.items.find((integ) => {
        return integ.scm_type === "gitlab";
      });
    },
    githubEnterpriseIntegration() {
      return this.scmIntegrations.items.find((integ) => {
        return integ.scm_type === "github-enterprise";
      });
    },
    gitlabSelfHostedIntegration() {
      return this.scmIntegrations.items.find((integ) => {
        return integ.scm_type === "gitlab-self-hosted";
      });
    },
    unconnectedSaasSCMTypes() {
      return ["github", "gitlab"].filter((scmType) => {
        return !this.scmIntegrations.items.find((i) => i.scm_type === scmType);
      });
    },
  },
  methods: {
    cartridgeSelected(eventArgs) {
      this.$emit("cartridgeSelected", eventArgs);
    },
    connectSCMIntegration(scmType) {
      this.$emit("connectSCMIntegration", { scmType });
    },
    connectHostedSCMIntegration(scmType) {
      this.$emit("startHostedSCMConnection", { scmType });
    },
    startSCMLink(scmIntegration) {
      this.$emit("startSCMLink", { scmIntegration });
    },
  },
});
</script>

<i18n>
en:
  seeMore: "Show advanced options"
  seeLess: "Hide advanced options"
fr:
  seeMore: "Afficher les options avancées"
  seeLess: "Cacher les options avancées"
</i18n>
