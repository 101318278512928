<template>
  <SCTable
    class="mt-6"
    layout="fixed"
    :headers="headers"
    :items="domainsSorted"
    :paginated="true"
    :searchable="true"
    :searchFn="searchFn"
    :sortBy="sortBy"
    :sortDirection="sortDirection"
    @sort-changed="(e) => $emit('sortChanged', e)"
  >
    <template #item.status="{ item }">
      <DomainIndicator :domain="item" />
    </template>

    <template #item.name="{ item }">
      <div class="flex items-center">
        <div v-if="item.canonical === true" :title="$t('title.canonical')">
          <StarGlyph class="h-8 mr-2" />
        </div>

        <a
          v-if="shouldLinkDomain(item)"
          :href="hrefForDomain(item)"
          target="_blank"
          class="underline"
        >
          {{ item.name }}
        </a>

        <template v-else>
          {{ item.name }}
        </template>
      </div>
    </template>

    <template #item.message="{ item }">
      <DomainMessage :item="item"></DomainMessage>
    </template>

    <template #item.actions="{ item, index }">
      <DomainActions
        :item="item"
        :index="index"
        @start-make-canonical="(e) => $emit('startMakeCanonical', e)"
        @start-unmake-canonical="(e) => $emit('startUnmakeCanonical', e)"
        @delete-domain-clicked="(e) => $emit('deleteDomainClicked', e)"
      ></DomainActions>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import StarGlyph from "@/components/atoms/glyphs/StarGlyph.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import DomainActions from "@/components/parts/appSettings/domainsSettings/DomainActions.vue";
import DomainIndicator from "@/components/parts/appSettings/domainsSettings/DomainIndicator.vue";
import DomainMessage from "@/components/parts/appSettings/domainsSettings/DomainMessage.vue";

const HEADERS = [
  { value: "status", class: "w-8" },
  { value: "name", sortable: true },
  { value: "message" },
  { value: "actions", align: "right" },
];

const searchFn = (row, search) => {
  return (
    row.name
      .toString()
      .toLocaleLowerCase()
      .indexOf(search.toLocaleLowerCase()) !== -1
  );
};

export default defineComponent({
  name: "DomainsIndex",
  components: {
    DomainMessage,
    DomainActions,
    StarGlyph,
    SCTable,
    DomainIndicator,
  },
  props: {
    domains: Object,
    sortBy: String,
    sortDirection: String,
  },
  emits: [
    "deleteDomainClicked",
    "sortChanged",
    "startMakeCanonical",
    "startUnmakeCanonical",
  ],
  data() {
    return {
      headers: HEADERS.map((e) => {
        return { ...e, text: this.$i18n.t(`headers.${e.value}`) };
      }),
      searchFn: searchFn,
      beta: false,
    };
  },
  computed: {
    domainsSorted() {
      const canonical = this.domains.items.filter(
        (item) => item.canonical === true,
      );
      const notCanonical = this.domains.items.filter(
        (item) => item.canonical === false,
      );

      return [...canonical, ...notCanonical];
    },
  },
  methods: {
    shouldLinkDomain(domain) {
      return !domain.name.startsWith("*.");
    },
    hrefForDomain(domain) {
      const protocol = domain.ssl ? "https" : "http";

      return `${protocol}://${domain.name}`;
    },
  },
});
</script>

<i18n>
en:
  title:
    canonical: "This domain is the canonical one"
  headers:
    status: " "
    name: "Domain name"
    message: " "
    actions: " "
fr:
  title:
    canonical: "Ce domaine est le domaine canonique"
  headers:
    status: " "
    name: "Domain name"
    message: " "
    actions: " "
</i18n>
