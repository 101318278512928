<template>
  <div>
    <header>
      <NavBar :title="$t('title')"></NavBar>
    </header>
    <main class="container px-4 mt-8">
      <InfosStepCard :enabled="false" />
      <CardSeparator></CardSeparator>
      <DeployStepCard
        :enabled="true"
        :app="app"
        :scmRepoLink="scmRepoLink"
        :findAppInfo="findAppInfo"
        :scmIntegrations="scmIntegrations"
        :currentCartridge="currentCartridge"
        :currentSCMIntegration="currentSCMIntegration"
        :scmOrgs="scmOrgs"
        :hasMoreOrgs="hasMoreOrgs"
        :scmRepos="scmRepos"
        :scmBranches="scmBranches"
        :connectIntegrationHandler="connectIntegrationHandler"
        :repoLinkOperation="repoLinkOperation"
        :listOrgsInfo="listOrgsInfo"
        :listReposInfo="listReposInfo"
        :listBranchesInfo="listBranchesInfo"
        @form-submitted="deployFormSubmitted"
        @cartridge-selected="cartridgeSelected"
        @connect-s-c-m-integration="connectSCMIntegration"
        @start-hosted-s-c-m-connection="
          (e) => $emit('startHostedSCMConnection', e)
        "
        @cancel-hosted-s-c-m-connection="$emit('cancelHostedSCMConnection')"
        @confirm-hosted-s-c-m-connection="
          (e) => $emit('confirmHostedSCMConnection', e)
        "
        @start-s-c-m-link="startSCMLink"
        @stop-s-c-m-link="$emit('stopSCMLink')"
        @unlink-repo="$emit('unlinkRepo')"
        @scm-org-selected="(eventArgs) => $emit('scmOrgSelected', eventArgs)"
        @scm-repo-searched="(e) => $emit('scmRepoSearched', e)"
        @scm-repo-selected="(eventArgs) => $emit('scmRepoSelected', eventArgs)"
        @scm-branch-selected="
          (eventArgs) => $emit('scmBranchSelected', eventArgs)
        "
        @finish-s-c-m-repo-link="
          (eventArgs) => $emit('finishSCMRepoLink', eventArgs)
        "
        @load-more-organizations="
          (eventArgs) => $emit('loadMoreOrganizations', eventArgs)
        "
      />
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import NavBar from "@/components/molecules/navBar/NavBar.vue";
import DeployStepCard from "@/components/parts/configure/app/DeployStepCard.vue";
import InfosStepCard from "@/components/parts/create/app/InfosStepCard.vue";

export default defineComponent({
  name: "ConfigureApp",
  components: { NavBar, CardSeparator, DeployStepCard, InfosStepCard },
  props: {
    app: Object,
    scmRepoLink: Object,
    findAppInfo: Object,
    scmIntegrations: Object,
    currentCartridge: String,
    currentSCMIntegration: Object,
    scmOrgs: Array,
    hasMoreOrgs: Boolean,
    scmRepos: Array,
    scmBranches: Array,
    connectIntegrationHandler: Object,
    repoLinkOperation: Object,
    listOrgsInfo: Object,
    listReposInfo: Object,
    listBranchesInfo: Object,
  },
  emits: [
    "basicInformationFormSubmitted",
    "cancelHostedSCMConnection",
    "cartridgeSelected",
    "confirmHostedSCMConnection",
    "connectSCMIntegration",
    "deployFormSubmitted",
    "finishSCMRepoLink",
    "scmBranchSelected",
    "scmOrgSelected",
    "scmRepoSearched",
    "scmRepoSelected",
    "startHostedSCMConnection",
    "startSCMLink",
    "stopSCMLink",
    "unlinkRepo",
    "loadMoreOrganizations",
  ],
  methods: {
    basicInformationFormSubmitted(eventArgs) {
      this.$emit("basicInformationFormSubmitted", eventArgs);
    },
    deployFormSubmitted(eventArgs) {
      this.$emit("deployFormSubmitted", eventArgs);
    },
    cartridgeSelected(eventArgs) {
      this.$emit("cartridgeSelected", eventArgs);
    },
    connectSCMIntegration(eventArgs) {
      this.$emit("connectSCMIntegration", eventArgs);
    },
    startSCMLink(eventArgs) {
      this.$emit("startSCMLink", eventArgs);
    },
  },
});
</script>

<i18n>
  en:
    title: "New app creation"
  fr:
    title: "Création d'une nouvelle app"
</i18n>
