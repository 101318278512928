<template>
  <div class="flex flex-grow py-1 flex-col pv:flex-row">
    <div class="flex items-center justify-start flex-shrink pv:w-1/3">
      <div v-if="container.flag === 'error'" class="pr-2">
        <DangerSignGlyph class="text-color-2" />
      </div>
      <div class="flex flex-col flex-wrap">
        <div class="font-medium" :title="autoscalerTooltip">
          <AutoscalerGlyph
            v-if="displayAutoscaling"
            :class="autoscalerGlyphColor"
            class="inline-flex mr-1"
          />
          {{ container.name }}
        </div>
        <div
          v-if="container.command"
          class="text-scale-4 font-mono text-xs flex-grow leading-5"
        >
          {{ container.command }}
        </div>
      </div>
    </div>
    <div class="flex pv:w-2/3 flex-col pv:flex-row">
      <div class="pv:w-1/2 flex items-center pv:justify-center">
        <span class="pv:ml-2 text-scale-7 mt-2 hidden pv:flex">{{
          $t("size")
        }}</span>
        <AdvancedSelectInput
          v-model="requestedSize"
          class="pv:ml-2 mt-2 w-40 h-10"
          :options="sizeOptions"
          :state="sizeState"
        >
          <template #text="{ option }">
            {{ option.text }}
          </template>
        </AdvancedSelectInput>

        <span class="pv:ml-6 text-scale-7 mt-2">
          <div class="flex items-center px-2 pv:px-0">
            <span class="pv:ml-2 hidden pv:flex">
              {{ $t("amount") }}
            </span>
          </div>
        </span>
        <span class="pv:hidden pr-1 mt-1 text-scale-7">x</span>
        <TextInput
          v-model="requestedAmount"
          class="ml-2 mt-2 w-16 text-center"
          type="number"
          :disabled="!amountCanChange"
          :title="autoscalerTooltip"
          :max="maxContainers"
          :state="amountState"
        ></TextInput>
      </div>
      <div
        class="pv:w-1/2 flex items-center flex-grow pv:justify-end justify-center py-4 pv:py-0"
      >
        <Button
          kind="neutral"
          :actions="otherActions"
          :disabled="!formationHasChanged || !canActOnContainers"
          :actionsDisabled="autoscalerAction && autoscalerAction.isSubmitting"
          @click="scaleContainerOrWarn"
          @action="actionClicked"
        >
          {{ $t("actions.scale") }}
        </Button>
        <Button
          kind="neutral"
          class="ml-2"
          :actions="stopAction"
          :disabled="!canActOnContainers || !hasContainers"
          :actionsDisabled="!canActOnContainers || !hasContainers"
          :title="restartTitle"
          @click="restartContainer"
          @action="actionClicked"
        >
          {{ $t("actions.restart") }}
        </Button>
      </div>
    </div>

    <ScaleToZeroWarning
      v-if="showScaleToZeroWarning"
      :name="this.container.name"
      @modal-closed="showScaleToZeroWarning = false"
      @warning-accepted="scaleContainer"
    />

    <ScaleBelowLimitWarning
      v-if="showScaleBelowLimitWarning"
      :minContainers="minContainers"
      :isHds="isHds"
      @modal-closed="showScaleBelowLimitWarning = false"
      @warning-accepted="showScaleBelowLimitWarning = false"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import AutoscalerGlyph from "@/components/atoms/glyphs/AutoscalerGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import TextInput from "@/components/atoms/inputs/TextInput.vue";
import Button from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import ScaleBelowLimitWarning from "@/components/parts/app/resources/ScaleBelowLimitWarning.vue";
import ScaleToZeroWarning from "@/components/parts/app/resources/ScaleToZeroWarning.vue";

export default defineComponent({
  name: "ContainerItem",
  components: {
    ScaleBelowLimitWarning,
    AutoscalerGlyph,
    AdvancedSelectInput,
    ScaleToZeroWarning,
    Button,
    DangerSignGlyph,
    TextInput,
  },
  props: {
    container: Object,
    minContainers: Number,
    maxContainers: Number,
    sizeOptions: Array,
    canActOnContainers: Boolean,
    isHds: Boolean,
    autoscaler: Object,
    autoscalerAction: Object,
  },
  emits: [
    "createAutoscaler",
    "destroyAutoscaler",
    "disableAutoscaler",
    "editAutoscaler",
    "enableAutoscaler",
    "restartContainer",
    "stopContainer",
    "scaleContainer",
  ],
  data() {
    return {
      requestedSize: null,
      requestedAmount: null,
      showScaleToZeroWarning: false,
      showScaleBelowLimitWarning: false,
    };
  },
  computed: {
    autoscalerGlyphColor() {
      if (this.autoscaler) {
        return this.autoscaler.disabled ? "text-scale-4" : "text-color-1";
      }

      return "";
    },
    restartTitle() {
      if (!this.hasContainers) {
        return this.$t("title.restart.noContainer");
      }
      if (!this.canActOnContainers) {
        return this.$t("title.restart.cant");
      }
      return "";
    },
    displayAutoscaling() {
      return !!this.autoscaler;
    },
    autoscalerTooltip() {
      if (this.autoscaler) {
        return this.autoscaler.disabled
          ? this.$i18n.t("autoscaler.disabled")
          : this.$i18n.t("autoscaler.enabled", {
              min: this.autoscaler.min_containers,
              max: this.autoscaler.max_containers,
            });
      }

      return "";
    },
    otherActions() {
      const actions = [];

      if (this.autoscaler) {
        actions.push({
          key: "autoscaler.edit",
          text: this.$i18n.t("actions.autoscale.edit"),
        });

        if (this.autoscaler.disabled) {
          actions.push({
            key: "autoscaler.enable",
            text: this.$i18n.t("actions.autoscale.enable"),
          });

          actions.push({
            key: "autoscaler.destroy",
            text: this.$i18n.t("actions.autoscale.destroy"),
            kind: "warning",
          });
        } else {
          actions.push({
            key: "autoscaler.disable",
            text: this.$i18n.t("actions.autoscale.disable"),
          });
        }
      } else {
        actions.push({
          key: "autoscaler.create",
          text: this.$i18n.t("actions.autoscale.create"),
        });
      }

      return actions;
    },
    stopAction() {
      return [
        {
          key: "containers.stop",
          text: this.$i18n.t("actions.containers.stop"),
        },
      ];
    },
    sizeHasChanged() {
      return this.requestedSize?.toString() !== this.container.size?.toString();
    },
    sizeState() {
      return this.sizeHasChanged ? "success" : "";
    },
    amountCanChange() {
      return !this.autoscaler || this.autoscaler.disabled;
    },
    amountHasChanged() {
      return (
        this.requestedAmount?.toString() !== this.container.amount?.toString()
      );
    },
    amountState() {
      return this.amountHasChanged ? "success" : "";
    },
    formationHasChanged() {
      return this.sizeHasChanged || this.amountHasChanged;
    },
    hasContainers() {
      return this.container.amount !== 0;
    },
  },
  watch: {
    container() {
      this.setupFormationForm();
    },
  },
  mounted() {
    this.setupFormationForm();
  },
  methods: {
    setupFormationForm() {
      this.requestedSize = this.container.size;
      this.requestedAmount = this.container.amount;
    },
    scaleContainerOrWarn() {
      const amount = parseInt(this.requestedAmount, 10);

      if (amount === 0) {
        this.showScaleToZeroWarning = true;
        return;
      }

      if (amount > 0 && amount < this.minContainers) {
        this.showScaleBelowLimitWarning = true;
        return;
      }

      this.scaleContainer();
    },
    scaleContainer() {
      this.$emit("scaleContainer", {
        name: this.container.name,
        size: this.requestedSize,
        amount: this.requestedAmount,
      });
      this.showScaleToZeroWarning = false;
    },
    actionClicked(e) {
      if (e.action.key === "autoscaler.create") {
        this.$emit("createAutoscaler", { container: this.container });
        return;
      }

      if (e.action.key === "autoscaler.enable") {
        this.$emit("enableAutoscaler", { autoscaler: this.autoscaler });
        return;
      }

      if (e.action.key === "autoscaler.edit") {
        this.$emit("editAutoscaler", { autoscaler: this.autoscaler });
        return;
      }

      if (e.action.key === "autoscaler.disable") {
        this.$emit("disableAutoscaler", { autoscaler: this.autoscaler });
        return;
      }

      if (e.action.key === "autoscaler.destroy") {
        this.$emit("destroyAutoscaler", { autoscaler: this.autoscaler });
        return;
      }

      if (e.action.key === "containers.stop") {
        this.$emit("stopContainer", { name: this.container.name });
        return;
      }
    },
    restartContainer() {
      this.$emit("restartContainer", { name: this.container.name });
    },
  },
});
</script>

<i18n>
en:
  container: "Container"
  size: "Size"
  amount: "Qty"
  autoscaler:
    enabled: "Autoscaler is enabled (min: {min}, max: {max})"
    disabled: "Autoscaler is disabled"
  title:
    restart:
      cant: "It is not possible to trigger a restart at this moment"
      noContainer: "It is not possible to trigger a restart since there is 0 containers"
  actions:
    restart: "Restart"
    scale: "Scale"
    autoscale:
      create: "Setup autoscaler"
      edit: "Configure autoscaler"
      enable: "Re-enable autoscaler"
      disable: "Disable autoscaler"
      destroy: "Remove autoscaler"
    containers:
      stop: "Stop"
fr:
  container: "Container"
  size: "Taille"
  amount: "Qté"
  autoscaler:
    enabled: "Autoscaler est activé (min: {min}, max: {max})"
    disabled: "Autoscaler est désactivé"
  title:
    restart:
      cant: "Le redémarrage n'est pas possible pour le moment"
      noContainer: "Le redémarrage n'est pas possible étant donné qu'il n'y a aucun container"
  actions:
    restart: "Redémarrer"
    scale: "Scaler"
    autoscale:
      create: "Configurer l'autoscaling"
      edit: "Configurer l'autoscaling"
      enable: "Réactiver l'autoscaling"
      disable: "Désactiver l'autoscaling"
      destroy: "Supprimer l'autoscaling"
    containers:
      stop: "Stopper"
</i18n>
