<template>
  <section>
    <template v-if="scmRepoLink.latestFetch.isLoading">
      <LoadingCardState />
    </template>
    <template v-if="scmRepoLink.latestFetch.isSuccess">
      <ManualDeployCard
        :app="app"
        :scmRepoLink="scmRepoLink.value"
        :scmBranches="scmBranches"
        :deployHandler="deployHandler"
        :listBranchesInfo="listBranchesInfo"
        @fetch-scm-repo-link-branches="$emit('fetchScmRepoLinkBranches')"
        @manual-deploy="(e) => $emit('manualDeploy', e)"
      ></ManualDeployCard>
    </template>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ManualDeployCard from "@/components/parts/app/showDeployConfiguration/ManualDeployCard.vue";

export default defineComponent({
  name: "DeployConfiguration",
  components: {
    ManualDeployCard,
    LoadingCardState,
  },
  props: {
    app: Object,
    user: Object,
    scmRepoLink: Object,
    scmBranches: Array,
    deployHandler: Object,
    listBranchesInfo: Object,
  },
  emits: ["fetchScmRepoLinkBranches", "manualDeploy"],
  data() {
    return {};
  },
});
</script>
