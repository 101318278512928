<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')" />
    </template>
    <template #body>
      <LoadingCardState v-if="isLoading" class="h-64 justify-center" />
      <EmptyCardState
        v-else-if="noMetricForThePeriod"
        class="h-64 justify-center"
      >
        <template #icon><EmptySetGlyph /></template>
        <span class="flex flex-col text-center">
          <span>{{ $t("noMetric1") }}</span
          ><span>{{ $t("noMetric2") }}</span>
        </span>
      </EmptyCardState>
      <EmptyCardState
        v-else-if="isError && !noMetricForThePeriod"
        class="h-64 justify-center"
      >
        <template #icon><CrossGlyph /></template>
        <span class="capitalize">{{ promiseInfo.err.data.error }}</span>
      </EmptyCardState>
      <ResponseTime v-else-if="isLoaded" :datasets="datasets" />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import EmptySetGlyph from "@/components/atoms/glyphs/EmptySetGlyph.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ResponseTime from "@/components/organisms/graphs/ResponseTime.vue";
import {
  makeDataSet,
  makeEventsDataSet,
  onlyEventsOfType,
} from "@/lib/metrics";

export default defineComponent({
  name: "ResponseTimeCard",
  components: {
    EmptySetGlyph,
    Card,
    CardHeader,
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
    ResponseTime,
  },
  props: {
    promiseInfo: Object,
    responseTimeMedianRawData: Array,
    responseTimep95RawData: Array,
    responseTimep99RawData: Array,
    eventsRawData: Array,
  },
  computed: {
    isLoaded() {
      return this.promiseInfo.isSuccess;
    },
    isLoading() {
      return this.promiseInfo.isLoading;
    },
    isError() {
      return this.promiseInfo.isError;
    },
    noMetricForThePeriod() {
      if (!this.promiseInfo?.err?.data?.error) {
        return false;
      }

      return (
        this.isError && this.promiseInfo.err.data.error === "no metrics found"
      );
    },
    deployEventsDataset() {
      const deployEvents = onlyEventsOfType(this.eventsRawData, "deploy");

      return makeEventsDataSet(
        this.$i18n,
        "events",
        "deploy",
        deployEvents,
        this.p99Dataset,
      );
    },
    restartEventsDataset() {
      const restartEvents = onlyEventsOfType(this.eventsRawData, "restart");

      return makeEventsDataSet(
        this.$i18n,
        "events",
        "restart",
        restartEvents,
        this.p99Dataset,
      );
    },
    scaleEventsDataset() {
      const scaleEvents = onlyEventsOfType(this.eventsRawData, "scale");

      return makeEventsDataSet(
        this.$i18n,
        "events",
        "scale",
        scaleEvents,
        this.p99Dataset,
      );
    },

    medianDataset() {
      return makeDataSet(
        this.$i18n,
        "responseTime",
        "median",
        this.responseTimeMedianRawData,
      );
    },
    p95Dataset() {
      return makeDataSet(
        this.$i18n,
        "responseTime",
        "p95",
        this.responseTimep95RawData,
      );
    },
    p99Dataset() {
      return makeDataSet(
        this.$i18n,
        "responseTime",
        "p99",
        this.responseTimep99RawData,
      );
    },
    datasets() {
      return [
        this.medianDataset,
        this.p95Dataset,
        this.p99Dataset,
        this.restartEventsDataset,
        this.scaleEventsDataset,
        this.deployEventsDataset,
      ];
    },
  },
});
</script>

<i18n>
en:
  title: "Response time"
  subtitle: "Median, 95th percentile, 99th percentile."
  noMetric1: "No request to the app during the selected period"
  noMetric2: "Hence no metric to display"
fr:
  title: "Temps de réponse"
  subtitle: "Médian, 95ème percentile, 99ème percentile."
  noMetric1: "Aucune requête à l'app sur la période sélectionnée"
  noMetric2: "Par conséquence aucune métrique à aficher"
</i18n>
