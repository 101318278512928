<template>
  <main>
    <VoucherCreditsBanner v-if="hasCredits" class="mb-6" />
    <VouchersList :vouchers="vouchers" @redeem-voucher="voucherModalOpened" />

    <VoucherFormModal
      v-if="voucherFormHandler"
      :formHandler="voucherFormHandler"
      @form-submitted="voucherFormSubmitted"
      @modal-closed="voucherModalClosed"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import VoucherCreditsBanner from "@/components/organisms/banners/VoucherCreditsBanner.vue";
import VoucherFormModal from "@/components/parts/billing/showBilling/VoucherFormModal.vue";
import VouchersList from "@/components/parts/billing/showBilling/VouchersList.vue";

export default defineComponent({
  name: "Vouchers",
  components: {
    VouchersList,
    VoucherFormModal,
    VoucherCreditsBanner,
  },
  props: {
    user: Object,
    billingProfile: Object,
    hasCredits: Boolean,
    vouchers: Object,
    voucherFormHandler: Object,
  },
  emits: ["voucherFormSubmitted", "voucherModalClosed", "voucherModalOpened"],
  methods: {
    voucherModalOpened(eventArgs) {
      this.$emit("voucherModalOpened", eventArgs);
    },
    voucherModalClosed(eventArgs) {
      this.$emit("voucherModalClosed", eventArgs);
    },
    voucherFormSubmitted(eventArgs) {
      this.$emit("voucherFormSubmitted", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  subtitle: "Your credits are now visible on a dedicated page"
  none: "no vouchers redeemed"
  buttonName: "Redeem voucher"
fr:
  subtitle: "Les vouchers seront déduits de votre facture"
  none: "Aucun vouchers utilisé"
  buttonName: "Utiliser un voucher"
</i18n>
