<template>
  <ViewComponent
    :collaborators="uniqAndSortedCollaborators"
    :fetchPromiseInfo="collaboratorsStore.promiseInfo"
    :username="currentUsername"
    :isAppOwner="isAppOwner"
    :isCollaborator="isCollaborator"
    :groupBy="groupBy"
    :searchTerm="searchTerm"
    @group-by-selected="changeGroupBy"
    @open-app-collaborators="openAppCollaborators"
  ></ViewComponent>
</template>

<script>
import { defineComponent, onBeforeMount, computed, ref } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/Collaborators.vue";
import { sortCollaborators } from "@/lib/utils/collaborators.ts";
import { AppRoutes } from "@/router/app/names";
import { useAppInfosStore } from "@/stores/app-infos";
import { useCollaboratorsStore } from "@/stores/collaborators";
import { currentUser } from "@/stores/user";

export default defineComponent({
  name: "Collaborators",
  components: { ViewComponent },
  setup() {
    const router = useRouter();
    const collaboratorsStore = useCollaboratorsStore();
    const appInfosStore = useAppInfosStore();

    onBeforeMount(() => {
      collaboratorsStore.ensure({ staleAfter: "always" });
      appInfosStore.ensure();
    });

    const ownedApps = (apps) => {
      return apps.filter((app) => app.owner.id === currentUser().id);
    };

    const isAppOwner = computed(() => {
      const { items, promiseInfo } = appInfosStore;

      if (promiseInfo.isFinished) {
        return ownedApps(items).length > 0;
      }

      return true;
    });

    const isCollaborator = computed(() => {
      const { items, promiseInfo } = appInfosStore;

      if (promiseInfo.isFinished) {
        return items.length > ownedApps(items).length;
      }

      return true;
    });

    const byCollaborator = (collaborators, currentCollaborator) => {
      const existingCollaborator = collaborators.find(
        (object) => object.email === currentCollaborator.email,
      );

      if (existingCollaborator) {
        existingCollaborator.apps.push({
          name: currentCollaborator.app_name,
          region: currentCollaborator.regionCode,
          status: currentCollaborator.status,
          id: currentCollaborator.app_id,
        });
      } else {
        currentCollaborator.username ??= "n/a";
        currentCollaborator.apps = [
          {
            name: currentCollaborator.app_name,
            region: currentCollaborator.regionCode,
            status: currentCollaborator.status,
            id: currentCollaborator.app_id,
          },
        ];
        collaborators.push(currentCollaborator);
      }

      return collaborators;
    };

    const uniqAndSortedCollaborators = computed(() => {
      if (!collaboratorsStore.items) {
        return [];
      }
      const sortedCollaborators = sortCollaborators(collaboratorsStore.items);

      return sortedCollaborators.reduce(byCollaborator, []);
    });

    const currentUsername = computed(() => currentUser().username);

    const groupBy = ref("collaborators");
    const changeGroupBy = (value) => {
      groupBy.value = value;
    };

    const searchTerm = ref("");

    const openAppCollaborators = (item) => {
      const region = item.region;
      const appName = item.name;

      router.push({
        name: AppRoutes.Collaborators,
        params: {
          region,
          id: appName,
        },
      });
    };

    return {
      collaboratorsStore,
      isAppOwner,
      isCollaborator,
      uniqAndSortedCollaborators,
      currentUsername,
      groupBy,
      changeGroupBy,
      searchTerm,
      openAppCollaborators,
    };
  },
});
</script>
