<template>
  <div class="mt-6">
    <AdvancedSelectInput
      class="mt-4"
      :options="availableNotifiers"
      :loading="notifiers.latestFetch.isLoading"
      @update:model-value="addNotifier"
    >
      <template #prompt>
        {{ $t("notifiers.prompt") }}
      </template>

      <template #noResults>
        {{ $t("notifiers.noAvailable") }}
      </template>

      <template #label>
        <div class="mb-3">{{ $t("notifiers.label") }}</div>
      </template>

      <template #text="{ option }">
        {{ option.name }}
      </template>
    </AdvancedSelectInput>

    <div v-if="form.notifiers.length > 0" class="mt-4">
      <template
        v-for="notifier in form.notifiers"
        :key="`alert-notifier-${notifier.id}`"
      >
        <BadgeAtom class="mr-2" @dismiss="removeNotifier(notifier)">
          {{ notifier.name }}
        </BadgeAtom>
      </template>
    </div>

    <div>{{ formHandler.errors["base"] }}</div>
    <div class="flex mt-4">
      <SCButton
        size="lg"
        kind="primary"
        class="flex-grow"
        :block="true"
        :loading="isSubmitting"
        @click="$emit('formSubmitted', form)"
      >
        {{ $t("action") }}
      </SCButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BadgeAtom from "@/components/atoms/badges/BadgeAtom.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";

export default defineComponent({
  name: "NotifierChoiceForm",
  components: {
    BadgeAtom,
    SCButton,
    AdvancedSelectInput,
  },
  props: {
    formHandler: Object,
    notifiers: Object,
  },
  emits: ["formSubmitted", "submit"],
  data() {
    return {
      form: {},
      toggled: false,
    };
  },
  computed: {
    availableNotifiers() {
      return this.notifiers.items
        .filter((notifier) => {
          return !this.form.notifiers.includes(notifier);
        })
        .map((notifier) => {
          return { ...notifier, value: notifier.id };
        });
    },
    isSubmitting() {
      return this.formHandler?.isSubmitting || false;
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.form);
    },
    addNotifier(id) {
      const notifier = this.notifiers.items.find((n) => n.id === id);

      this.form.notifiers.push(notifier);
    },
    removeNotifier(notifier) {
      const idx = this.form.notifiers.indexOf(notifier.id);

      this.form.notifiers.splice(idx, 1);
    },
  },
});
</script>

<i18n>
en:
  notifiers:
    label: "Notifiers"
    prompt: "Select a notifier..."
    noAvailable: "No notifier available."
  action: "Finish"
fr:
  notifiers:
    label: "Notifieurs"
    prompt: "Selectionnez un notifieur"
    noAvailable: "Aucun notifieur disponible."
  action: "Finir"
</i18n>
