<template>
  <main>
    <SectionTitle
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    ></SectionTitle>

    <Containers
      :app="app"
      :containers="containers"
      :autoscalers="autoscalers"
      :containerSizes="containerSizes"
      :sizeOptions="sizeOptions"
      :canActOnContainers="canActOnContainers"
      :autoscalerRecommendedValue="autoscalerRecommendedValue"
      :autoscalerHandler="autoscalerHandler"
      :autoscalerActions="autoscalerActions"
      class="mt-6"
      @stop-all="requestStopAllContainers"
      @restart-all="(e) => $emit('restartAll', e)"
      @stop-container="(e) => $emit('stopContainer', e)"
      @restart-container="(e) => $emit('restartContainer', e)"
      @scale-container="(e) => $emit('scaleContainer', e)"
      @autoscaler-metric-changed="(e) => $emit('autoscalerMetricChanged', e)"
      @auto-scale-form-submitted="(e) => $emit('autoScaleFormSubmitted', e)"
      @create-autoscaler="(e) => $emit('createAutoscaler', e)"
      @edit-autoscaler="(e) => $emit('editAutoscaler', e)"
      @cancel-autoscaler="(e) => $emit('cancelAutoscaler', e)"
      @enable-autoscaler="(e) => $emit('enableAutoscaler', e)"
      @disable-autoscaler="(e) => $emit('disableAutoscaler', e)"
      @destroy-autoscaler="(e) => $emit('destroyAutoscaler', e)"
    >
    </Containers>

    <Addons
      :app="app"
      :addons="addons"
      :busyAddons="busyAddons"
      :user="user"
      class="mt-4"
      @resume-addon="(e) => $emit('resumeAddon', e)"
      @start-addon-deletion="(e) => $emit('startAddonDeletion', e)"
    />

    <CronTasks :cronTasks="cronTasks" :sizeOptions="sizeOptions" class="mt-4" />

    <ConfirmAddonDeletion
      v-if="deleteAddonCtx"
      :context="deleteAddonCtx"
      @close="$emit('cancelAddonDeletion')"
      @confirm="(e) => $emit('confirmAddonDeletion', e)"
    />

    <StopAllContainersModal
      v-if="stopAllContainerRequested"
      @confirm="confirmStopAllContainers"
      @close="cancelStopAllContainers"
    ></StopAllContainersModal>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SectionTitle from "@/components/atoms/titles/SectionTitle.vue";
import ConfirmAddonDeletion from "@/components/organisms/modals/ConfirmAddonDeletion.vue";
import Addons from "@/components/parts/app/resources/AddonsCard.vue";
import Containers from "@/components/parts/app/resources/ContainersCard.vue";
import CronTasks from "@/components/parts/app/resources/CronTasksCard.vue";
import StopAllContainersModal from "@/components/parts/app/resources/StopAllContainersModal.vue";
import { closeCurrentModal } from "@/lib/modals";
import { humanReadableSize } from "@/lib/utils/size";

export default defineComponent({
  name: "Resources",
  components: {
    Containers,
    SectionTitle,
    Addons,
    CronTasks,
    ConfirmAddonDeletion,
    StopAllContainersModal,
  },
  props: {
    app: Object,
    user: Object,
    addonProviders: Object,
    addons: Object,
    containers: Object,
    autoscalers: Object,
    containerSizes: Object,
    canActOnContainers: Boolean,
    autoscalerRecommendedValue: Number,
    autoscalerHandler: Object,
    autoscalerActions: Object,
    deleteAddonCtx: Object,
    busyAddons: Object,
    cronTasks: Object,
  },
  emits: [
    "autoScaleFormSubmitted",
    "autoscalerMetricChanged",
    "cancelAddonDeletion",
    "cancelAutoscaler",
    "confirmAddonDeletion",
    "createAutoscaler",
    "destroyAutoscaler",
    "disableAutoscaler",
    "editAutoscaler",
    "enableAutoscaler",
    "restartAll",
    "restartContainer",
    "resumeAddon",
    "scaleContainer",
    "startAddonDeletion",
    "stopAll",
    "stopContainer",
  ],
  data() {
    return {
      stopAllContainerRequested: false,
    };
  },
  computed: {
    sizeOptions() {
      return this.containerSizes.items.map((val) => {
        const humanBytes = humanReadableSize(val.memory);
        const text = `${val.human_name} – ${humanBytes}`;
        return { text, value: val.name };
      });
    },
  },
  methods: {
    confirmStopAllContainers() {
      this.$emit("stopAll", this.stopAllContainerRequested);
      closeCurrentModal();
    },
    cancelStopAllContainers() {
      this.stopAllContainerRequested = false;
    },
    requestStopAllContainers() {
      this.stopAllContainerRequested = true;
    },
  },
});
</script>

<i18n>
  en:
    title: "Resources"
    subtitle: "Manage resources in use."
  fr:
    title: "Ressources"
    subtitle: "Gestion des ressources utilisées."
</i18n>
