<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons>
          <SCButton
            v-if="!forceTLSEnabled"
            :loading="forceTLSPending"
            @click="$emit('enableForceTLS')"
          >
            {{ $t("enable") }}
          </SCButton>
          <div v-else>
            <SCButton
              v-if="!internetAccessEnabled"
              :loading="forceTLSPending"
              @click="$emit('disableForceTLS')"
            >
              {{ $t("disable") }}
            </SCButton>
          </div>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="flex flex-row items-center mt-4">
        <TwoStatesIndicator
          class="mr-2"
          :isOn="forceTLSEnabled"
          :isNo="!forceTLSEnabled"
          :alternativeColors="true"
        ></TwoStatesIndicator>
        <span v-if="forceTLSEnabled">
          {{ $t("enabled") }}
        </span>
        <span v-if="!forceTLSEnabled">
          {{ $t("disabled") }}
        </span>
      </div>
      <ProTipAlert icon="title" class="mt-4">
        {{ $t("tip") }}
      </ProTipAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";

export default defineComponent({
  name: "ForceTLSCard",
  components: {
    Card,
    CardHeader,
    SCButton,
    TwoStatesIndicator,
    ProTipAlert,
  },
  props: {
    db: Object,
    promiseInfo: Object,
    forceTLSPending: Boolean,
    forceTLSEnabled: Boolean,
    internetAccessEnabled: Boolean,
  },
  emits: ["disableForceTLS", "enableForceTLS"],
});
</script>

<i18n>
en:
  title: "Force TLS connections"
  subtitle: "By default, you can connect your database both with or without TLS encryption."
  enable: "Enable"
  disable: "Disable"
  enabled: "Non-TLS connections are blocked"
  disabled: "Non-TLS connections are allowed"
  tip: "Before activating this feature, make sure that your application can connect to your database using TLS. If not, communication will not be possible."
fr:
  title: "Forcer les connexions TLS"
  subtitle: "Par défaut, vous pouvez vous connecter à votre base de données avec ou sans chiffrement TLS."
  enable: "Activer"
  disable: "Désactiver"
  enabled: "Les connexions sans TLS sont bloquées"
  disabled: "Les connexions sans TLS sont autorisées"
  tip: "Avant d'activer cette fonctionnalité, assurez-vous que votre application peut se connecter à votre base de données en utilisant TLS. Dans le cas contraire, la communication ne sera pas possible."
</i18n>
