<template>
  <section>
    <template v-if="scmRepoLink.latestFetch.isLoading">
      <LoadingCardState />
    </template>
    <template v-if="scmRepoLink.latestFetch.isSuccess">
      <DeploymentsViaCard
        :user="user"
        :scmRepoLink="scmRepoLink.value"
        :repoLinkOperation="repoLinkOperation"
        :deleteSCMRepoLinkHandler="deleteSCMRepoLinkHandler"
        :editSCMRepoLinkerHandler="editSCMRepoLinkerHandler"
        @unlink-repository="$emit('unlinkRepository')"
        @change-linker="$emit('changeLinker')"
      ></DeploymentsViaCard>
      <AutomaticDeploymentCard
        class="mt-8"
        :repoLinkOperation="repoLinkOperation"
        :scmRepoLink="scmRepoLink.value"
        :scmBranches="scmBranches"
        :listBranchesInfo="listBranchesInfo"
        @disable-auto-deploy="$emit('disableAutoDeploy')"
        @enable-auto-deploy="(e) => $emit('enableAutoDeploy', e)"
        @fetch-scm-repo-link-branches="$emit('fetchScmRepoLinkBranches')"
      ></AutomaticDeploymentCard>
    </template>
    <template v-if="scmRepoLink.latestFetch.isError">
      <Card>
        <template #header>
          <CardHeader
            :title="$t('title')"
            :subtitle="$t('subtitle')"
          ></CardHeader>
        </template>
        <template #body>
          <DeployCartridges
            class="mt-4"
            :app="app"
            :scmIntegrations="scmIntegrations"
            :selectedCartridge="currentCartridge"
            :currentSCMIntegration="currentSCMIntegration"
            :scmOrgs="scmOrgs"
            :hasMoreOrgs="hasMoreOrgs"
            :scmRepos="scmRepos"
            :scmBranches="scmBranches"
            :connectIntegrationHandler="connectIntegrationHandler"
            :repoLinkOperation="repoLinkOperation"
            :listOrgsInfo="listOrgsInfo"
            :listReposInfo="listReposInfo"
            :listBranchesInfo="listBranchesInfo"
            @cartridge-selected="cartridgeSelected"
            @connect-s-c-m-integration="connectSCMIntegration"
            @start-hosted-s-c-m-connection="
              (e) => $emit('startHostedSCMConnection', e)
            "
            @cancel-hosted-s-c-m-connection="$emit('cancelHostedSCMConnection')"
            @confirm-hosted-s-c-m-connection="
              (e) => $emit('confirmHostedSCMConnection', e)
            "
            @start-s-c-m-link="startSCMLink"
            @stop-s-c-m-link="$emit('stopSCMLink')"
            @unlink-repo="$emit('unlinkRepo')"
            @scm-org-selected="
              (eventArgs) => $emit('scmOrgSelected', eventArgs)
            "
            @load-more-organizations="
              (eventArgs) => $emit('loadMoreOrganizations', eventArgs)
            "
            @scm-repo-searched="(e) => $emit('scmRepoSearched', e)"
            @scm-repo-selected="
              (eventArgs) => $emit('scmRepoSelected', eventArgs)
            "
            @scm-branch-selected="
              (eventArgs) => $emit('scmBranchSelected', eventArgs)
            "
            @finish-s-c-m-repo-link="
              (eventArgs) => $emit('finishSCMRepoLink', eventArgs)
            "
          ></DeployCartridges>
        </template>
      </Card>
    </template>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AutomaticDeploymentCard from "@/components/parts/app/showDeployConfiguration/AutomaticDeploymentCard.vue";
import DeploymentsViaCard from "@/components/parts/app/showDeployConfiguration/DeploymentsViaCard.vue";
import DeployCartridges from "@/components/parts/configure/app/DeployCartridges.vue";

export default defineComponent({
  name: "DeployConfiguration",
  components: {
    AutomaticDeploymentCard,
    DeploymentsViaCard,
    Card,
    CardHeader,
    DeployCartridges,
    LoadingCardState,
  },
  props: {
    app: Object,
    user: Object,
    scmRepoLink: Object,
    scmIntegrations: Object,
    currentCartridge: String,
    currentSCMIntegration: Object,
    scmOrgs: Array,
    hasMoreOrgs: Boolean,
    scmRepos: Array,
    scmBranches: Array,
    connectIntegrationHandler: Object,
    repoLinkOperation: Object,
    deleteSCMRepoLinkHandler: Object,
    editSCMRepoLinkerHandler: Object,
    listOrgsInfo: Object,
    listReposInfo: Object,
    listBranchesInfo: Object,
  },
  emits: [
    "cancelHostedSCMConnection",
    "cartridgeSelected",
    "changeLinker",
    "confirmHostedSCMConnection",
    "connectSCMIntegration",
    "disableAutoDeploy",
    "enableAutoDeploy",
    "fetchScmRepoLinkBranches",
    "finishSCMRepoLink",
    "scmBranchSelected",
    "scmOrgSelected",
    "scmRepoSearched",
    "scmRepoSelected",
    "startHostedSCMConnection",
    "startSCMLink",
    "stopSCMLink",
    "unlinkRepo",
    "unlinkRepository",
    "loadMoreOrganizations",
  ],
  data() {
    return {};
  },
  computed: {},
  methods: {
    cartridgeSelected(eventArgs) {
      this.$emit("cartridgeSelected", eventArgs);
    },
    connectSCMIntegration(eventArgs) {
      this.$emit("connectSCMIntegration", eventArgs);
    },
    startSCMLink(eventArgs) {
      this.$emit("startSCMLink", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Configuration"
  subtitle: "Configure the deployment process"
fr:
  title: "Configuration"
  subtitle: "Configurer le processus de déploiement"
</i18n>
