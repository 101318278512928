export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support plans"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the support plan best suited to your business."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the launch of the offer, please send us the list of your application collaborators with whom you want to share the benefits of your subscription. This will allow us to activate their access without delay."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres de support"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les garanties et niveaux d’assistance dont vous avez besoin pour assurer la performance et la résilience de votre projet."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendant le lancement de l’offre, pensez à nous envoyer la liste des collaborateurs de vos applications avec qui vous souhaitez partager les bénéfices de votre souscription. Cela nous permettra d’activer leur accès sans délai."])}
      }
    }
  })
}
