<template>
  <SCButton
    kind="neutral"
    :title="feedback ? $t('copied') : $t('copyClipboard')"
    @click="clicked"
  >
    <font-awesome-icon
      v-if="!feedback"
      class="text-scale-6 h-4 w-6"
      :icon="['far', 'clipboard']"
    ></font-awesome-icon>
    <font-awesome-icon
      v-else
      class="text-success h-4 w-6"
      icon="check"
    ></font-awesome-icon>
  </SCButton>
</template>
<script>
import SCButton from "@/components/molecules/buttons/SCButton.vue";

export default {
  name: "CopyButton",
  components: { SCButton },
  emits: ["click"],
  data() {
    return {
      feedback: false,
    };
  },
  methods: {
    clicked() {
      this.$emit("click");
      this.feedback = true;
      setTimeout(
        function () {
          this.feedback = false;
        }.bind(this),
        1500,
      );
    },
  },
};
</script>

<i18n>
en:
  copyClipboard: "Copy to clipboard"
  copied: "Copied"
fr:
  copyClipboard: "Copier dans le presse-papiers"
  copied: "Copié"
</i18n>
