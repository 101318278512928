<template>
  <main>
    <SectionTitle :title="$t('environment.title')"></SectionTitle>

    <Card class="mt-6">
      <template #header>
        <CardHeader
          :title="$t('variables.title')"
          :subtitle="$t('variables.subtitle')"
          :withCount="true"
          :count="variablesCount"
        >
          <template #buttons>
            <SCButton
              v-if="!bulkEditVariableHandler"
              class="mr-2"
              @click="$emit('startVariableCreation')"
            >
              {{ $t("actions.add") }}
            </SCButton>

            <SCButton v-if="bulkEditVariableHandler" @click="startBulkModeExit">
              {{ $t("actions.tableMode") }}
            </SCButton>

            <SCButton v-else @click="$emit('startBulkEdition')">
              {{ $t("actions.bulkMode") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <div class="flex flex-col py-4">
          <EmptyCardState
            v-if="
              variables.latestFetch.isFinished &&
              variables.items.length === 0 &&
              !bulkEditVariableHandler
            "
          >
            <template #icon>
              <ListGlyph />
            </template>
            {{ $t("variables.none") }}
          </EmptyCardState>

          <template v-else>
            <VariablesTextEditor
              v-if="bulkEditVariableHandler"
              :handler="bulkEditVariableHandler"
              :variables="variables"
              @set-bulk-dirtiness="(e) => (bulkModeIsDirty = e.dirty)"
              @reset-bulk-edition="(e) => $emit('resetBulkEdition', e)"
              @confirm-bulk-edition="(e) => $emit('confirmBulkEdition', e)"
            />

            <VariablesTable
              v-else
              :variables="variables"
              :secretiveCells="true"
              @copy-variable="(e) => $emit('copyVariable', e)"
              @start-variable-edition="(e) => $emit('startVariableEdition', e)"
              @start-variable-deletion="
                (e) => $emit('startVariableDeletion', e)
              "
            />
          </template>
        </div>
      </template>
    </Card>
    <ProTipAlert class="mt-6">
      <div class="flex flex-col">
        <div>{{ $t("proTip1") }}</div>
        <div class="flex flex-col pv:flex-row">
          <div>{{ $t("proTip2") }}:</div>
          <div>
            <div
              class="font-mono bg-scale-1 px-1 break-all pv:whitespace-nowrap"
            >
              DATABASE_URL=$SCALINGO_POSTGRESQL_URL
            </div>
          </div>
        </div>
      </div>
    </ProTipAlert>

    <CreateVariableModal
      v-if="createVariableHandler"
      :formHandler="createVariableHandler"
      @form-submitted="(e) => $emit('confirmVariableCreation', e)"
      @modal-closed="$emit('cancelVariableCreation')"
    />

    <EditVariableModal
      v-if="editVariableHandler"
      :formHandler="editVariableHandler"
      @form-submitted="(e) => $emit('confirmVariableEdition', e)"
      @modal-closed="$emit('cancelVariableEdition')"
    />

    <DeleteVariableModal
      v-if="deleteVariableHandler"
      :formHandler="deleteVariableHandler"
      @form-submitted="$emit('confirmVariableDeletion')"
      @modal-closed="$emit('cancelVariableDeletion')"
    />

    <DiscardBulkChangesModal
      v-if="bulkModeExitWarning"
      @confirm-bulk-mode-exit="confirmBulkModeExit"
      @cancel-bulk-mode-exit="cancelBulkModeExit"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import ListGlyph from "@/components/atoms/glyphs/ListGlyph.vue";
import SectionTitle from "@/components/atoms/titles/SectionTitle.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import CreateVariableModal from "@/components/parts/app/environment/CreateVariableModal.vue";
import DeleteVariableModal from "@/components/parts/app/environment/DeleteVariableModal.vue";
import DiscardBulkChangesModal from "@/components/parts/app/environment/DiscardBulkChangesModal.vue";
import EditVariableModal from "@/components/parts/app/environment/EditVariableModal.vue";
import VariablesTable from "@/components/parts/app/environment/VariablesTable.vue";
import VariablesTextEditor from "@/components/parts/app/environment/VariablesTextEditor.vue";
import { RemoteOperation } from "@/lib/store/remote-operation";

export default defineComponent({
  name: "EnvironmentSettings",
  components: {
    ProTipAlert,
    EmptyCardState,
    SectionTitle,
    SCButton,
    Card,
    CardHeader,
    VariablesTextEditor,
    VariablesTable,
    ListGlyph,
    CreateVariableModal,
    EditVariableModal,
    DeleteVariableModal,
    DiscardBulkChangesModal,
  },
  props: {
    app: Object,
    user: Object,
    variables: Object,
    createVariableHandler: Object,
    editVariableHandler: Object,
    deleteVariableHandler: Object,
    bulkEditVariableHandler: Object,
    bulkUpdateOperation: RemoteOperation,
    bulkDeleteOperation: RemoteOperation,
  },
  emits: [
    "cancelBulkEdition",
    "cancelVariableCreation",
    "cancelVariableDeletion",
    "cancelVariableEdition",
    "confirmBulkEdition",
    "confirmVariableCreation",
    "confirmVariableDeletion",
    "confirmVariableEdition",
    "copyVariable",
    "startBulkEdition",
    "startVariableCreation",
    "startVariableDeletion",
    "startVariableEdition",
    "resetBulkEdition",
  ],
  data: function () {
    return {
      bulkMode: false,
      bulkModeIsDirty: false,
      bulkModeExitWarning: false,
    };
  },
  computed: {
    variablesCount() {
      if (this.variables.latestFetch.isFinished) {
        return this.variables.items.length;
      }

      return 0;
    },
    bulkUpdating() {
      return (
        this.bulkUpdateOperation.isLoading || this.bulkDeleteOperation.isLoading
      );
    },

    rowsCount() {
      return this.variablesAsString.split("\n").length;
    },
  },
  methods: {
    startBulkModeExit() {
      if (!this.bulkEditVariableHandler) return;

      if (this.bulkModeIsDirty) {
        this.bulkModeExitWarning = true;
      } else {
        this.confirmBulkModeExit();
      }
    },
    confirmBulkModeExit() {
      this.bulkModeExitWarning = false;
      this.$emit("cancelBulkEdition");
    },
    cancelBulkModeExit() {
      this.bulkModeExitWarning = false;
    },
  },
});
</script>

<i18n>
en:
  environment:
    title: "Environment"
  variables:
    title: "Variables list"
    subtitle: "Environment variables that will be available in your app containers."
    none: "There are no environment variables yet."
  actions:
    add: "New variable"
    bulkMode: "Switch to bulk edit"
    tableMode: "Switch to table"
  proTip1: "You can alias an existing variable by prepending the $ sign to its name"
  proTip2: "Example"
fr:
  environment:
    title: "Environnement"
  variables:
    title: "Liste des variables"
    subtitle: "Variables d'environnement disponible dans vos containers applicatif."
    none: "Il n'y a pas encore de variable d'environmment"
  actions:
    add: "Nouvelle variable"
    bulkMode: "Modifier en masse"
    tableMode: "Retourner au tableau"
  proTip1: "Vous pouvez créer un alias d'une variable existante en faisant précéder son nom par le signe $"
  proTip2: "Exemple"
</i18n>
