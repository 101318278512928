<template>
  <WarningModal
    @modal-closed="$emit('modalClosed')"
    @form-submitted="$emit('warningAccepted')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      <span v-if="name === 'web'">
        {{ $t("message_web") }}
      </span>
      <span v-else>
        {{ $t("message_others", { type: name }) }}
      </span>
    </template>
    <template #button>{{ $t("button", { type: name }) }}</template>
  </WarningModal>
</template>

<script>
import { defineComponent } from "vue";

import WarningModal from "@/components/molecules/modalsCollection/WarningModal.vue";

export default defineComponent({
  name: "ScaleToZeroWarning",
  components: { WarningModal },
  props: {
    name: String,
  },
  emits: ["modalClosed", "warningAccepted"],
});
</script>

<i18n>
en:
  title: "Scale to 0 container"
  button: "Scale '{type}' to 0 container"
  message_web: "If you decide to scale your web containers to 0, the app will become inactive"
  message_others: "If you decide to scale '{type}' resource type to 0 containers, the '{type}' process will become inactive"
fr:
  title: "Scale à 0 container"
  button: "Scale '{type}' à 0 container"
  message_web: "Si vous décidez de scaler vos containers web à 0, l'app deviendra inactive"
  message_others: "Si vous décidez de scaler vos containers '{type}' à 0 containers, le processus '{type}' deviendra inactif"
</i18n>
