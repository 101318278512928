<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :logs="logs"
    :db="db"
    :streamLogs="streamLogs"
    :logsFontSize="logsFontSize"
    :displayInFullScreen="displayInFullScreen"
    @streaming-toggled="streamingToggled"
    @clear-clicked="clearClicked"
    @font-size-changed="fontSizeChanged"
    @full-screen-toggled="fullScreenToggled"
  ></ViewComponent>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/db/logs/DbLogsLive.vue";
import {
  initDbLogsStreamer,
  teardownDbLogsStreamer,
} from "@/lib/scalingo/streamers/logs";
import FullscreenableController from "@/mixins/fullscreenable_controller";
import { toggleLogStreaming } from "@/store/logs";
import { updatePreferences } from "@/store/user";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbLogsStore } from "@/stores/db/logs";

export default defineComponent({
  name: "DbLogsLiveContainer",
  components: { ViewComponent },
  mixins: [FullscreenableController],
  setup() {
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const dbLogsStore = useDbLogsStore();

    onBeforeMount(() => dbLogsStore.ensure());

    return {
      app: computed(() => currentAppStore.regional),
      addon: computed(() => currentDbStore.addon),
      db: computed(() => currentDbStore.database),
      logs: computed(() => dbLogsStore.items),
      clearClicked: () => dbLogsStore.$reset(),
    };
  },
  computed: {
    streamLogs() {
      return this.currentUser.preferences?.stream_logs;
    },
    logsFontSize() {
      return this.currentUser.preferences.logs_font_size || "text-sm";
    },
  },
  mounted() {
    if (this.streamLogs) {
      initDbLogsStreamer(this.app, this.addon, this.db);
    } else {
      teardownDbLogsStreamer(this.db);
    }
  },
  methods: {
    fontSizeChanged({ fontSize }) {
      updatePreferences(this.$store, { logs_font_size: fontSize });
    },
    streamingToggled(eventArgs) {
      toggleLogStreaming(this.$store, eventArgs);
      updatePreferences(this.$store, { stream_logs: eventArgs });
    },
  },
});
</script>
