<template>
  <SimpleModal @modal-closed="$emit('modalClosed')">
    <template #title>
      {{ $t("title") }}
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template #body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6">
              <StripeElement
                id="sepa-iban-element"
                labelClass="capitalize"
                :label="$ta('paymentMethod', 'iban')"
                :errors="elementsErrors.iban"
              />
            </div>

            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.holder"
                name="holder"
                rules="required|min:3"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('paymentMethod', 'holder')"
                  labelClass="capitalize"
                  :errors="errors"
                  @update:model-value="handleChange"
                ></TextInput>
              </Field>
            </div>

            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-8"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import StripeElement from "@/components/molecules/stripe/Element.vue";
import { stripeClient } from "@/lib/stripe";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "SepaFormModal",
  components: {
    SimpleModal,
    SCButton,
    StripeElement,
    TextInput,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  emits: ["modalClosed", "formSubmitted"],
  data() {
    return {
      elements: [],
      elementsErrors: {
        iban: [],
      },
      elementsValidity: {
        iban: false,
      },
    };
  },
  computed: {
    stripeElementsInvalid() {
      return !this.elementsValidity.iban;
    },
    globalErrors() {
      if (this.formHandler.errors.$codes.length > 0) {
        return this.formHandler.errors.$codes
          .map((code) => this.$t(`errors.${code}`))
          .join(", ");
      }

      return null;
    },
  },
  async mounted() {
    const stripe = await stripeClient();

    if (!stripe) return;

    const elements = stripe.elements();

    if (!elements) return;

    const style = this.generateStyle();

    this.elements = [
      {
        el: elements.create("iban", {
          style: style,
          supportedCountries: ["SEPA"],
        }),
        id: "#sepa-iban-element",
      },
    ];

    this.elements.forEach((e) => {
      e.el.mount(e.id);

      e.el.on("change", (event) => {
        if (event) {
          this.elementsValidity[event.elementType] = event.complete;
          if (event.error) {
            this.elementsErrors[event.elementType] = [event.error.message];
          } else {
            this.elementsErrors[event.elementType] = [];
          }
        }
      });
    });
  },
  methods: {
    generateStyle() {
      const appRoot = document.getElementById("app-root");

      const backgroundColor =
        getComputedStyle(appRoot).getPropertyValue("--scale-0");
      const textColor =
        getComputedStyle(appRoot).getPropertyValue("--scale-10");
      const placeholderColor =
        getComputedStyle(appRoot).getPropertyValue("--scale-3");
      const errorColor = getComputedStyle(appRoot).getPropertyValue("--error");

      return {
        base: {
          backgroundColor: backgroundColor,
          color: textColor,
          "::placeholder": {
            color: placeholderColor,
          },
          fontSize: "16px",
          lineHeight: "24px",
          paddingTop: "0.5rem",
          paddingRight: "0.75rem",
          paddingBottom: "0.5rem",
          paddingLeft: "0.75rem",
          height: "24px",
        },
        invalid: {
          iconColor: errorColor,
          color: errorColor,
        },
      };
    },
    submitForm() {
      this.$emit("formSubmitted", {
        ...this.form,
        el: this.elements[0].el,
      });
    },
  },
});
</script>

<i18n>
  en:
    title: "Add SEPA account"
    subtitle: "Use a SEPA mechanism in your facturation profile"
    buttonName: "Add"
  fr:
    title: "Ajouter un compte SEPA"
    subtitle: "Utiliser un mécanisme SEPA dans votre profil de facturation"
    buttonName: "Ajouter"
</i18n>
