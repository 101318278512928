<template>
  <main>
    <div class="mt-8">
      <Card>
        <template #header>
          <CardHeader
            :title="$t('title')"
            :subtitle="$t('subtitle')"
          ></CardHeader>
        </template>
        <template #body>
          <div v-if="usage.promiseInfo.isLoading">
            <LoadingCardState />
          </div>
          <div
            v-else-if="
              usage.promiseInfo.isError && errorMessage === 'no billing profile'
            "
            class="rounded bg-scale-0 px-8 py-6 flex flex-col"
          >
            <EmptyCardState>
              <template #icon>
                <BillGlyph class="h-8"></BillGlyph>
              </template>
              <span class="text-scale-6 text-lg font-medium">{{
                $t("noBillingProfile")
              }}</span>
            </EmptyCardState>
          </div>

          <div
            v-else-if="usage.promiseInfo.isError"
            class="rounded bg-scale-0 px-8 py-6 flex flex-col"
          >
            <EmptyCardState>
              <template #icon>
                <BillGlyph class="h-8 text-error"></BillGlyph>
              </template>
              <span class="text-scale-6 text-lg font-medium">{{
                $t("error")
              }}</span>
            </EmptyCardState>
          </div>
          <div v-else>
            <SCBanner v-if="hasOrder">
              {{ $t("orderWarning") }}
            </SCBanner>
            <Period
              class="mt-4"
              :usage="usage"
              :accountCreationDate="accountCreationDate"
              :accountName="accountName"
              :fromDate="fromDate"
              :toDate="toDate"
              :groupBy="groupBy"
              @reset-today="(e) => $emit('resetToday', e)"
              @group-by-selected="(e) => $emit('groupBySelected', e)"
              @period-selected="(e) => $emit('periodSelected', e)"
            ></Period>
          </div>
        </template>
      </Card>

      <ProTipAlert class="mt-4 flex">
        <div>{{ $t("proTip") }}</div>
      </ProTipAlert>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import Period from "@/components/parts/consumption/Period.vue";

export default defineComponent({
  name: "Consumption",
  components: {
    BillGlyph,
    EmptyCardState,
    LoadingCardState,
    Period,
    Card,
    ProTipAlert,
    CardHeader,
    SCBanner,
  },
  props: {
    usage: Object,
    accountCreationDate: String,
    accountName: String,
    fromDate: Object,
    toDate: Object,
    groupBy: String,
    hasOrder: Boolean,
  },
  emits: ["resetToday", "groupBySelected", "periodSelected"],
  computed: {
    errorMessage() {
      return this.usage.promiseInfo.err?.message;
    },
  },
});
</script>

<i18n>
en:
  title: "Consumption reports"
  subtitle: "This report is updated daily at midnight UTC."
  error: "An error occured."
  noBillingProfile: "Create a billing profile to follow your consumption."
  orderWarning: "The amounts displayed may differ from those shown on your orders. These are calculated using the price grid associated with your account and not one of the specific grids for one of your orders."
  proTip: "The amounts shown are frozen at the end of each billing period. An application transferred during the month is billed to its new owner, even if you can see its consumption for the period prior to its transfer."
fr:
  title: "Rapports de consommation"
  subtitle: "Ce rapport est mis à jour quotidiennement à minuit UTC."
  error: "Une erreur est survenue."
  noBillingProfile: "Créez un profil de facturation pour suivre vos consommation."
  orderWarning: "Les montants affichés peuvent différer de ceux reportés sur vos commandes. Ceux-ci sont calculés en utilisant la grille de prix associée à votre compte et non une des grilles spécifiques à l'une de vos commandes."
  proTip: "Les montants indiqués sont gelés à la fin de chaque période de facturation. Une application transférée en cours de mois est facturée à son nouveau propriétaire, même si vous pouvez voir sa consommation pour la période précédant son transfert."
</i18n>
