<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons> </template>
      </CardHeader>
    </template>
    <template #body>
      <SmallToggle
        :modelValue="value"
        @update:model-value="
          (e) => $emit('settingUpdated', { sticky_session: e })
        "
        >{{ $t("text") }}</SmallToggle
      >
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import SmallToggle from "@/components/molecules/toggles/SmallToggle.vue";

export default defineComponent({
  name: "StickySessionCard",
  components: { Card, SmallToggle, CardHeader },
  props: {
    value: Boolean,
  },
  emits: ["settingUpdated"],
});
</script>

<i18n>
en:
  title: "Sticky Session"
  subtitle: "User sessions will be sticky, they will always return to the same container."
  text: "Sticky Session"
fr:
  title: "Sessions persistantes"
  subtitle: "Les sessions utilisateurs sont persistantes, elles renvoient toujours au même container."
  text: "Sessions persistantes"
</i18n>
