<template>
  <section>
    <Card>
      <template #header>
        <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
          <template #buttons>
            <SCButton :loading="false" @click="$emit('startUserCreation')">
              {{ $t("addUser") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <UsersList
          v-if="users.promiseInfo.isSuccess && users?.items.length > 0"
          class="mt-2"
          :users="users"
          :app="app"
          :scmRepoLink="scmRepoLink"
          @start-user-deletion="(e) => $emit('startUserDeletion', e)"
          @start-password-reset="(e) => $emit('startPasswordReset', e)"
        />
      </template>
    </Card>
    <CreateUserModal
      v-if="createCtx"
      :context="createCtx"
      @submit="(e) => $emit('confirmUserCreation', e)"
      @close="$emit('cancelUserCreation')"
    />
    <PasswordCopyModal
      v-if="createdUser || updatedUser"
      :user="createdUser || updatedUser"
      @close="$emit('closePasswordCopyModal')"
    />
    <DeleteUserModal
      v-if="deleteCtx"
      :context="deleteCtx"
      @close="$emit('cancelUserDeletion')"
      @confirm="(e) => $emit('confirmUserDeletion', e)"
    />
    <PasswordResetModal
      v-if="resetCtx"
      :context="resetCtx"
      @close="$emit('cancelPasswordReset')"
      @confirm="(e) => $emit('confirmPasswordReset', e)"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import CreateUserModal from "@/components/parts/dbSettings/users/CreateUserModal.vue";
import DeleteUserModal from "@/components/parts/dbSettings/users/DeleteUserModal.vue";
import PasswordCopyModal from "@/components/parts/dbSettings/users/PasswordCopyModal.vue";
import PasswordResetModal from "@/components/parts/dbSettings/users/PasswordResetModal.vue";
import UsersList from "@/components/parts/dbSettings/users/UsersList.vue";

export default defineComponent({
  name: "UserAccess",
  components: {
    CardHeader,
    UsersList,
    CreateUserModal,
    PasswordCopyModal,
    DeleteUserModal,
    PasswordResetModal,
    Card,
    SCButton,
  },
  props: {
    db: Object,
    users: Object,
    createdUser: Object,
    updatedUser: Object,
    createCtx: Object,
    deleteCtx: Object,
    resetCtx: Object,
  },
  emits: [
    "startUserCreation",
    "confirmUserCreation",
    "cancelUserCreation",
    "closePasswordCopyModal",
    "startUserDeletion",
    "confirmUserDeletion",
    "cancelUserDeletion",
    "startPasswordReset",
    "cancelPasswordReset",
    "confirmPasswordReset",
  ],
});
</script>

<i18n>
en:
  title: "User Management"
  subtitle: "List of users who have access to this database."
  addUser: "Add a user"
fr:
  title: "Gestion des utilisateurs"
  subtitle: "Liste des utilisateurs disposant d'un accès à cette base de données."
  addUser: "Ajouter un utilisateur"
</i18n>
