<template>
  <SimpleModal :hasBorder="true" @modal-closed="$emit('modalClosed')">
    <template #title>
      <div class="mb-2">
        {{ $t("title") }}
      </div>
    </template>
    <template #body>
      <div class="text-scale-9 mt-2">
        {{ $t("subtitle") }}
      </div>
      <InformationAlert v-if="context.error" kind="error" class="mt-3">
        {{ context.error }}
      </InformationAlert>
      <div
        class="my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 border rounded p-2 max-h-80 overflow-auto"
      >
        <div
          v-for="(value, key) in availableModes"
          :key="key"
          class="flex flex-col rounded border border-padding cursor-pointer"
          @click="setConfiguration(key, value)"
        >
          <div class="flex m-3">
            <CheckboxInputAtom :modelValue="value" />

            <div class="text-ellipsis overflow-hidden">
              {{ key }}
            </div>
          </div>
        </div>
      </div>
      <SCButton
        block
        kind="primary"
        size="lg"
        class="w-full"
        :loading="context.info.isLoading"
        @click="$emit('updateConfigurationList')"
      >
        <slot name="cancelText">
          {{ $t("confirm") }}
        </slot>
      </SCButton>
      <SCButton
        block
        kind="neutral"
        size="lg"
        class="w-full mt-3"
        @click="$emit('modalClosed')"
      >
        <slot name="cancelText">
          {{ $t("cancel") }}
        </slot>
      </SCButton>
    </template>
  </SimpleModal>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";

export default defineComponent({
  name: "EditModalConfiguration",
  components: {
    SimpleModal,
    CheckboxInputAtom,
    SCButton,
    InformationAlert,
  },
  props: {
    availableModes: {
      type: Array,
    },
    context: {
      type: Object,
    },
  },
  emits: ["modalClosed", "updateConfigurationList"],
  methods: {
    setConfiguration(key, value) {
      this.availableModes[key] = !value;
    },
  },
});
</script>

<i18n>
  en:
    title: "Manage SQL modes"
    subtitle: "Select the SQL modes you wish to activate:"
    confirm: "Update configuration"
    cancel: "Cancel"
    errorMessage: "An error occurred. Please reload the page and try again."
  fr:
    title: "Gérer les modes SQL"
    subtitle: "Choisissez le ou les modes SQL que vous souhaitez activer :"
    confirm: "Mettre à jour la configuration"
    cancel: "Annuler"
    errorMessage: "Une erreur est survenue. Veuillez recharger la page et réessayer."
</i18n>
