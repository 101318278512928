<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :scmRepoLink="scmRepoLink"
    :scmPullRequests="scmPullRequests"
    :reviewApps="reviewApps"
    :deployHandler="deployHandler"
    :listPullRequestsInfo="listPullRequestsInfo"
    @manual-review-app="(e) => deployHandler.submit(e.number)"
    @fetch-scm-repo-link-pull-requests="fetchScmRepoLinkPullRequests"
  ></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/reviewApps/Manual.vue";
import { CreateReviewAppHandler } from "@/lib/handlers";
import { promiseInfo } from "@/lib/promises/info";
import { scalingoClient } from "@/lib/scalingo/client";
import { listPullsForAppRepoLink } from "@/lib/scm-repo-links";
import { Routes } from "@/router/names";
import { ensureReviewApps, listReviewApps } from "@/store/review-apps";

export default defineComponent({
  name: "ReviewAppsManualContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    scmRepoLink: Object,
  },
  data() {
    return {
      listPullRequestsInfo: null,
      deployHandler: new CreateReviewAppHandler(this, this.app),
    };
  },
  computed: {
    reviewApps() {
      return listReviewApps(this.$store);
    },
    scmPullRequests() {
      return this.listPullRequestsInfo?.data || [];
    },
  },
  beforeMount() {
    if (!this.scmRepoLink.value) {
      this.$router.push({ name: Routes.App.ReviewApps.Root });
    }

    ensureReviewApps(this.$store, { staleAfter: "always" });
    this.fetchScmRepoLinkPullRequests();
  },
  methods: {
    fetchScmRepoLinkPullRequests() {
      this.listPullRequestsInfo = promiseInfo(
        listPullsForAppRepoLink(
          scalingoClient(this.$store, this.app.region),
          this.app,
        ),
      );
    },
  },
});
</script>
