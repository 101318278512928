<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <div>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons>
          <SCButton v-if="isEnabled" :loading="isPending" @click="disableCache">
            {{ $t("disable") }}
          </SCButton>
          <SCButton v-else :loading="isPending" @click="enableCache">
            {{ $t("enable") }}
          </SCButton>
        </template>
      </CardHeader>

      <div class="mt-4">
        <div class="flex flex-row items-center">
          <TwoStatesIndicator
            class="mr-2"
            :isOn="isEnabled"
            :isNo="!isEnabled"
            :alternativeColors="true"
          ></TwoStatesIndicator>
          <span v-if="isEnabled">
            {{ $t("enabled") }}
          </span>
          <span v-if="!isEnabled">
            {{ $t("disabled") }}
          </span>
        </div>
      </div>
      <div class="mt-4 text-scale-8">{{ $t("text") }}</div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { dbFeatureIs } from "@/lib/scalingo/dbapi/database";

export default defineComponent({
  name: "CacheCard",
  components: {
    CardHeader,
    SCButton,
    TwoStatesIndicator,
  },
  props: {
    db: Object,
    user: Object,
    promiseInfo: Object,
    enableCache: Function,
    disableCache: Function,
  },
  setup(props) {
    const isEnabled = computed(() =>
      dbFeatureIs(props.db, "redis-cache", "ACTIVATED"),
    );

    const isPending = computed(() => {
      return (
        props.promiseInfo.isLoading ||
        dbFeatureIs(props.db, "redis-cache", "PENDING")
      );
    });

    return {
      isPending,
      isEnabled,
    };
  },
});
</script>

<i18n>
en:
  title: "Cache Mode"
  subtitle: "Cache mode configuration"
  text: "This persistence mode configures Redis® to drop the least recently used keys automatically. This mode is optimal for caching as the most queried keys will be kept in cache forever. Please be cautious, any data can be deleted don't use your Redis® instance to store important information in this mode."
  enable: "Enable"
  disable: "Disable"
  enabled: "Cache mode enabled"
  disabled: "Cache mode disabled"
fr:
  title: "Mode Cache"
  subtitle: "Configuration du mode Cache"
  text: "Ce mode de persistance configure Redis® pour qu'il abandonne automatiquement les clés les moins récemment utilisées. Ce mode est optimal pour la mise en cache car les clés les plus demandées seront conservées dans le cache pour toujours. Soyez prudent, toutes les données peuvent être supprimées. N'utilisez pas votre instance Redis® dans ce mode pour stocker des informations importantes."
  enable: "Activer"
  disable: "Désactiver"
  enabled: "Mode Cache activé"
  disabled: "Mode Cache désactivé"
</i18n>
