<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons>
          <SCButton @click="$emit('downloadCaCertificate')">
            {{ $t("download") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      {{ $t("tip") }}
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "CaCertificateCard",
  components: {
    Card,
    CardHeader,
    SCButton,
  },
  emits: ["downloadCaCertificate"],
});
</script>

<i18n>
en:
  title: "Certificate authority"
  subtitle: "Download database TLS certificate."
  tip: "The certificate authority is already included in the certificate pool of your application's containers. You only need this certificate if you need to access your database from a remote server or your development environment."
  download: "Download"
fr:
  title: "Autorité de certification"
  subtitle: "Télécharger le certificat TLS de la base de données."
  tip: "L'autorité de certification est déjà incluse dans le pool de certificats des conteneurs de votre application. Vous n'avez besoin de ce certificat que si vous souhaitez accéder à votre base de données depuis un serveur distant ou votre environnement de développement."
  download: "Télécharger"
</i18n>
