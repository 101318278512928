<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton :to="operationsRoute">
          {{ $t("operations") }}
        </SCButton>
      </template>
    </CardHeader>
    <InvoicesListBody
      v-if="displayInvoices"
      :invoices="invoices"
      @fetch-more="$emit('fetchMoreInvoices')"
      @download-pdf="(e) => $emit('downloadInvoicePdf', e)"
    ></InvoicesListBody>
    <EmptyCardState v-else>
      <template #icon>
        <BillGlyph class="h-8 text-scale-6"></BillGlyph>
      </template>
      <span class="text-scale-6 text-lg font-medium">
        {{ $t("emptyText") }}
      </span>
    </EmptyCardState>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import InvoicesListBody from "@/components/parts/billing/InvoicesListBody.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "InvoicesList",
  components: {
    SCButton,
    InvoicesListBody,
    BillGlyph,
    EmptyCardState,
    CardHeader,
  },
  props: {
    invoices: Object,
  },
  emits: ["fetchMoreInvoices", "viewMoreButtonClicked", "downloadInvoicePdf"],
  computed: {
    operationsRoute() {
      return {
        name: Routes.Billing.Invoices.Detailed,
      };
    },
    displayInvoices() {
      const op = this.invoices.latestFetch;

      return op.isLoading || this.invoices.items.length > 0;
    },
  },
  methods: {
    viewMoreButtonClicked(eventArgs) {
      this.$emit("viewMoreButtonClicked", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Invoices"
  subtitle: "Invoices history"
  emptyText: "Nothing to show"
  operations: "See detailed operations"
fr:
  title: "Factures"
  subtitle: "Historique des factures"
  emptyText: "Aucune entrée"
  operations: "Voir les opérations en détail"
</i18n>
