<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton
          :disabled="promiseInfo.isSuccess || !proceedWithDeletion"
          :loading="promiseInfo.isLoading"
          @click="submitForm"
        >
          {{ $t("buttonName") }}
        </SCButton>
      </template>
    </CardHeader>
    <div>
      <div v-if="promiseInfo.isSuccess" class="flex">
        <div class="self-center">
          <CheckGlyph class="text-color-1" :size="4"></CheckGlyph>
        </div>
        <div class="ml-2">
          {{ $t("successPrompt") }}
        </div>
      </div>
      <template v-else>
        <p class="text-scale-8">{{ $t("text") }}</p>
        <div class="flex">
          <CheckboxInput
            v-model="proceedWithDeletion"
            class="py-4"
            :label="$t('label')"
            inputId="delete-account"
          >
            <template #label>
              <span>{{ $t("label") }}</span>
              <span class="text-scale-9 font-medium">
                {{ accountName }}
              </span>
            </template>
          </CheckboxInput>
        </div>

        <p
          v-if="promiseInfo.isError"
          class="border-t border-scale-2 text-color-2 pt-2"
        >
          {{ $t("errorPrompt") }}
          <span class="lowercase"> {{ formattedErrors }}. </span>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { APIError } from "scalingo/lib/errors";
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import CheckboxInput from "@/components/atoms/inputs/CheckboxInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "DeleteAccountForm",
  components: {
    CheckGlyph,
    CheckboxInput,
    CardHeader,
    SCButton,
  },
  props: {
    accountName: String,
    promiseInfo: Object,
  },
  emits: ["formSubmitted"],
  data() {
    return {
      proceedWithDeletion: false,
    };
  },
  computed: {
    formattedErrors() {
      const info = this.promiseInfo;

      if (!info.isError) return null;

      if (info.err instanceof APIError) {
        return info.err.data.error;
      }

      return info.err;
    },
  },
  methods: {
    submitForm() {
      this.$emit("formSubmitted", this.form);
    },
  },
});
</script>

<i18n>
  en:
    title: "Delete your Scalingo account"
    subtitle: "Remove all your Scalingo data"
    buttonName: "Delete"
    label: "Confirm that I want to start the account deletion process for the account "
    text: "This action will queue the removal of all your Scalingo account's data, including: Deployments, Activity, Aliases, Domains, Certificates and your Billing subscription"
    errorPrompt: "Your request could not be fulfilled:"
    successPrompt: "Account deletion successfully requested. Check your emails to confirm."
  fr:
    title: "Supprimer votre compte Scalingo"
    subtitle: "Suppresion de toutes vos données Scalingo"
    buttonName: "Supprimer"
    label: "Confirmer que vous voulez commencer le processus de de suppression pour le compte "
    text: "Cette action demandera la suppression de toutes vos données Scalingo, includant: Deploiments, Activités, Alias, Domaines, Certificats et vos Facturations"
    errorPrompt: "Votre requête n'a pas aboutie:"
    successPrompt: "Suppression de compte enregistrée. Vérifiez vos emails pour confirmer."
</i18n>
