<template>
  <div>
    <template v-if="events.items">
      <div
        v-for="(event, index) in events.items"
        :key="index"
        class="py-3 flex flex-col"
        :class="{ 'border-t border-scale-2': index > 0 }"
      >
        <EventsSwitch
          :event="event"
          :long="true"
          :displayAuthor="true"
          :displayAppName="false"
          :withEventLink="true"
          :eventsComplements="eventsComplements"
          @body-opened="(e) => $emit('bodyOpened', e)"
        />
      </div>
    </template>
    <template v-if="events.latestFetch.isLoading">
      <LoadingCardState />
    </template>
    <template v-else>
      <div v-if="hasMore" class="flex justify-center">
        <LinkButton
          :noUnderLine="true"
          @clicked="$emit('viewMoreButtonClicked')"
        >
          {{ $t("viewMore") }}
        </LinkButton>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import EventsSwitch from "@/components/parts/app/showActivity/EventsSwitch.vue";

export default defineComponent({
  name: "EventsList",
  components: { EventsSwitch, LinkButton, LoadingCardState },
  props: {
    events: Object,
    eventsComplements: Array,
  },
  emits: ["bodyOpened", "viewMoreButtonClicked"],
  computed: {
    hasMore() {
      return !!this.events.meta?.pagination?.next_page;
    },
  },
});
</script>

<i18n>
  en:
    viewMore: "View more"
  fr:
    viewMore: "Voir plus"
</i18n>
