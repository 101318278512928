<template>
  <div>
    <header>
      <NavBar
        v-if="displayNavBar"
        class="border-t border-scale-2"
        :title="navBarTitle"
      />

      <template v-if="app">
        <AdminAccessBanner
          v-if="accessAsAdmin"
          :dataAccessConsent="dataAccessConsent"
        />

        <ContractUpdateBanner
          v-for="contract in contractsToAccept.items"
          :key="`contract-${contract.id}`"
          :contract="contract"
          @start-contract-acceptance="
            (e) => $emit('startContractAcceptance', e)
          "
        />

        <ContactNearlyOutdatedBanner
          v-if="contactNearlyOutdated && bannerIsDisplayed"
          :contactLastUpdate="contactLastUpdate"
          @dismiss="$emit('dismissBanner')"
        />
        <ContactOutdatedBanner v-if="contactOutdated" />

        <DeploymentBanner
          v-if="deploymentsInProgress.length > 0"
          :deployment="deploymentsInProgress[0]"
        />
        <MigratedBanner v-if="migratedOrMigrating" :app="app" />
        <AppOperationBanner
          v-for="(operation, index) of allOperations"
          :key="index"
          :operation="operation"
        />
      </template>
    </header>

    <main v-if="app" id="main-container" class="container px-4">
      <router-view
        :app="app"
        :appInfo="appInfo"
        :parentAppInfo="parentAppInfo"
        class="mt-8"
      />
    </main>

    <main v-else class="container px-4">
      <div class="flex flex-col items-center pt-6">
        <div class="h-20 w-20 rounded-full flex items-center justify-center">
          <Spinner class="h-10 w-10 text-scale-5"></Spinner>
        </div>
      </div>
    </main>

    <AcceptContractModal
      v-if="acceptContractHandler"
      :formHandler="acceptContractHandler"
      @modal-closed="$emit('cancelContractAcceptance')"
      @form-submitted="(e) => $emit('submitContractAcceptance', e)"
    />
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";
import NavBar from "@/components/molecules/navBar/NavBar.vue";
import AdminAccessBanner from "@/components/organisms/banners/AdminAccessBanner.vue";
import AppOperationBanner from "@/components/organisms/banners/AppOperationBanner.vue";
import ContactNearlyOutdatedBanner from "@/components/organisms/banners/ContactNearlyOutdatedBanner.vue";
import ContactOutdatedBanner from "@/components/organisms/banners/ContactOutdatedBanner.vue";
import ContractUpdateBanner from "@/components/organisms/banners/ContractUpdateBanner.vue";
import DeploymentBanner from "@/components/organisms/banners/DeploymentBanner.vue";
import MigratedBanner from "@/components/organisms/banners/MigratedBanner.vue";
import AcceptContractModal from "@/components/parts/account/contracts/AcceptContractModal.vue";
import { Routes } from "@/router/names";

let labelKeyForRoute = {};

export default defineComponent({
  name: "Root",
  components: {
    ContactNearlyOutdatedBanner,
    ContactOutdatedBanner,
    AdminAccessBanner,
    ContractUpdateBanner,
    AcceptContractModal,
    DeploymentBanner,
    AppOperationBanner,
    MigratedBanner,
    NavBar,
    Spinner,
  },
  props: {
    app: Object,
    appInfo: Object,
    parentAppInfo: Object,
    user: Object,
    appIsLoading: Boolean,
    deploymentsInProgress: Array,
    allOperations: Array,
    contractsToAccept: Object,
    acceptContractHandler: Object,
    accessAsAdmin: {
      type: Boolean,
      default: false,
    },
    dataAccessConsent: Object,
    contactNearlyOutdated: {
      type: Boolean,
      default: false,
    },
    contactOutdated: {
      type: Boolean,
      default: false,
    },
    bannerIsDisplayed: {
      type: Boolean,
      default: true,
    },
    contactLastUpdate: String,
  },
  emits: [
    "cancelContractAcceptance",
    "startContractAcceptance",
    "submitContractAcceptance",
    "dismissBanner",
  ],
  data() {
    return {};
  },
  computed: {
    displayAppMenu() {
      return !this.displayNavBar;
    },
    displayNavBar() {
      return this.$route.meta?.hasNavBar || false;
    },
    navBarTitle() {
      return this.$i18n.t(labelKeyForRoute[this.$route.name]);
    },
    migratedOrMigrating() {
      return ["migrated", "migrating"].includes(this.app.status);
    },
    hasContractsToAccept() {
      return !isEmpty(this.contractsToAccept);
    },
  },
  created() {
    /**
     * @hack
     * on initial load, somehow those constants are (sometimes) undefined.
     * Setting them after the fact solves the issue.
     * We don't need reactivity, so the object is still "external" to the component.
     */
    labelKeyForRoute = {
      [Routes.App.Addon.Creation.Step1]: "title.newAddon.step1",
      [Routes.App.Addon.Creation.Step2]: "title.newAddon.step2",
      [Routes.App.Addon.Creation.Step3]: "title.newAddon.step3",
      [Routes.App.Addon.Edition.Step2]: "title.editAddon.step2",
      [Routes.App.Addon.Edition.Step3]: "title.editAddon.step3",
    };
  },
});
</script>

<i18n>
en:
  title:
    newAddon:
      step1: "Select an addon"
      step2: "Select a plan"
      step3: "Confirm your selection"
    editAddon:
      step2: "Select a plan"
      step3: "Confirm your selection"
fr:
  title:
    newAddon:
      step1: "Choix d'un add-on"
      step2: "Choix d'un plan"
      step3: "Confirmation de la selection"
    editAddon:
      step2: "Choix d'un plan"
      step3: "Confirmation de la selection"
</i18n>
