<template>
  <ViewComponent
    :db="db"
    :dbMaintenance="dbMaintenance"
    :updateCtx="updateCtx"
    @start-maintenance-configuration="startMaintenanceConfiguration"
    @cancel-maintenance-configuration="cancelMaintenanceConfiguration"
    @update-maintenance-configuration="updateMaintenanceConfiguration"
  >
  </ViewComponent>
</template>

<script>
import { ref, computed, defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/db/settings/DbMaintenance.vue";
import { closeCurrentModal } from "@/lib/modals";
import { voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import {
  useCurrentDBStore,
  currentDB,
  currentDBClient,
} from "@/stores/current/db";
import { useDbMaintenanceStore } from "@/stores/db/maintenance";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "DbMaintenance",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();
    const currentDbStore = useCurrentDBStore();
    const dbMaintenanceStore = useDbMaintenanceStore();
    const updateCtx = ref(null);

    onBeforeMount(() => {
      dbMaintenanceStore.ensure({ staleAfter: "always" });
    });

    async function updateMaintenanceConfiguration(payload) {
      const client = await currentDBClient();
      const db = currentDB();

      db.maintenance_window.weekday_utc = payload.weekday_utc;
      db.maintenance_window.starting_hour_utc = payload.starting_hour_utc;

      try {
        client.Database.updateDatabase(db.id, db)
          .then(() => currentDbStore.partialDBRefresh("maintenance_window"))
          .then(() => {
            toastsStore.addOne({
              type: "success",
              title: t("success.title").toString(),
              message: t("success.message").toString(),
            });
          });
        closeCurrentModal();
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("error.title").toString(),
          message: t("error.message").toString(),
        });
      }
    }

    return {
      db: computed(() => currentDbStore.database),
      dbMaintenance: computed(() => {
        return {
          items: dbMaintenanceStore.items,
          promiseInfo: dbMaintenanceStore.promiseInfo,
        };
      }),
      updateCtx,
      updateMaintenanceConfiguration,
      startMaintenanceConfiguration() {
        updateCtx.value = { info: voidPromiseInfo(), errors: null };
      },
      cancelMaintenanceConfiguration() {
        updateCtx.value = null;
      },
    };
  },
});
</script>

<i18n>
  en:
    success:
      title: "Configuration updated"
      message: "The configuration has been successfully updated."
    error:
      title: "Configuration update failure"
      message: "The configuration update failed."
  fr:
    success:
      title: "Configuration mise à jour"
      message: "La configuration a été mise à jour avec succès."
    error:
      title: "Échec"
      message: "La mise à jour de la configuration a échoué."
</i18n>
