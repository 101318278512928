<template>
  <SCTable
    layout="fixed"
    :headers="headers"
    :items="logsArchives.items"
    :hasMore="hasMore"
    :fetchingMore="logsArchives.latestFetch.isLoading"
    @fetch-more="$emit('fetchMore')"
  >
    <template #item.from="{ item }">
      {{ humanisedDate(item.from) }}
    </template>

    <template #item.to="{ item }">
      {{ humanisedDate(item.to) }}
    </template>

    <template #item.size="{ item }">
      {{ humanisedSize(item.size) }}
    </template>

    <template #item.actions="{ item, index }">
      <div class="flex justify-end">
        <a :href="item.url" target="_blank">
          <SCTableAction :title="$t('download')" :rowIndex="index" class="ml-2">
            <font-awesome-icon icon="download" class="h-4 w-4" />
          </SCTableAction>
        </a>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import { humanReadableSize } from "@/lib/utils/size";
import { customFormatDateTime } from "@/lib/utils/time"; // Hack on UTC where the 'ZZZZ' token is not recognized

const CUSTOM_FROM_FORMAT = "ccc LLL d TT ZZZ 'UTC' yyyy";

export default defineComponent({
  name: "LogsArchivesList",
  components: {
    SCTableAction,
    SCTable,
  },
  props: {
    logsArchives: Object,
  },
  emits: ["fetchMore"],
  data() {
    return {
      headers: [
        { value: "from", text: this.$t("headers.from"), class: "w-2/6" },
        { value: "to", text: this.$t("headers.to"), class: "w-2/6" },
        { value: "size", text: this.$t("headers.size"), class: "1/6" },
        { value: "actions", text: "", align: "right", class: "1/6" },
      ],
    };
  },
  computed: {
    hasMore() {
      return this.logsArchives.latestFetch.isSuccess
        ? !!this.logsArchives.meta.hasMore
        : false;
    },
  },
  methods: {
    humanisedSize(value) {
      return humanReadableSize(value);
    },
    humanisedDate(value) {
      return customFormatDateTime(value, CUSTOM_FROM_FORMAT);
    },
  },
});
</script>

<i18n>
en:
  headers:
    from: "From"
    to: "To"
    size: "Size"
  download: "Download"
fr:
  headers:
    from: "Depuis"
    to: "Jusqu'au"
    size: "Taille"
  download: "Télécharger"
</i18n>
