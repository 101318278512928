<template>
  <main>
    <BackLinkButton class="my-2" :routeArgs="backLink">
      {{ $t("backToInvoices") }}
    </BackLinkButton>

    <OperationsList
      :balanceOperations="balanceOperations"
      @fetch-more-operations="$emit('fetchMoreOperations')"
    ></OperationsList>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import OperationsList from "@/components/parts/billing/showBilling/OperationsList.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "Detailed",
  components: {
    BackLinkButton,
    OperationsList,
  },
  props: {
    user: Object,
    billingProfile: Object,
    balanceOperations: Object,
  },
  emits: ["fetchMoreOperations"],
  computed: {
    backLink() {
      return {
        name: Routes.Billing.Invoices.Root,
      };
    },
  },
});
</script>

<i18n>
en:
  backToInvoices: "Back to invoices"
fr:
  backToInvoices: "Retour aux factures"
</i18n>
