<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
      <form @submit.prevent="handleSubmit(submitForm)">
        <CardHeader
          :title="$t('title')"
          :subtitle="$t('subtitle', { email: currentEmail })"
        >
          <template #buttons>
            <SCButton
              type="submit"
              :disabled="!meta.valid"
              :loading="isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </template>
        </CardHeader>
        <div class="mt-6">
          <div v-if="unconfirmedEmail" class="border-b pb-2 mb-2">
            {{ $t("previouslySent", { email: unconfirmedEmail }) }}
            <a href="#" class="font-bold" @click.prevent="resendUnconfirmed">
              {{ $t("previouslySentResend") }}
            </a>
          </div>

          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.email"
            name="email"
            rules="required|email"
            mode="eager"
          >
            <TextInput
              :name="field.name"
              :modelValue="field.value"
              :label="$t('newEmailLabel')"
              :errors="errors"
              :placeholder="$t('newEmailPlaceholder')"
              @update:model-value="handleChange"
            ></TextInput>
          </Field>
        </div>
      </form>
    </Form>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";

export default defineComponent({
  name: "ChangeEmailForm",
  components: {
    SCButton,
    TextInput,
    CardHeader,
    Field,
    Form,
  },
  props: {
    currentEmail: String,
    unconfirmedEmail: String,
    formHandler: Object,
  },
  emits: ["formSubmitted"],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    isSubmitting() {
      return this.formHandler?.isSubmitting || false;
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);
        this.$refs.form?.reset();
      },
    },
  },
  methods: {
    submitForm() {
      this.$emit("formSubmitted", this.form);
    },
    resendUnconfirmed() {
      this.$emit("formSubmitted", { email: this.unconfirmedEmail });
    },
  },
});
</script>

<i18n>
en:
  title: "Change email"
  subtitle: "Current email address used by this account: {email}"
  buttonName: "Update email"
  newEmailLabel: "New email address"
  newEmailPlaceholder: "example{'@'}mail.com"
  previouslySent: "Your email address was previously changed to {email}, but it is not yet confirmed."
  previouslySentResend: "Didn't receive confirmation email? Click to resend."
  successfulSubmissionResend: "Didn't receive confirmation email? Click to resend."
fr:
  title: "Changer l'email"
  subtitle: "L'adresse email actuellement utilisée pour ce compte: {email}"
  buttonName: "Mettre à jour l'email"
  newEmailLabel: "Nouvelle addresse email"
  newEmailPlaceholder: "exemple{'@'}email.com"
  previouslySent: "Votre adresse email a précédemment été changée pour {email}, mais elle n'est pas encore confirmée."
  previouslySentResend: "Vous n'avez pas reçu l'email de confirmation? Cliquez pour renvoyer."
  successfulSubmissionResend: "Vous n'avez pas reçu l'email de confirmation? Cliquez pour renvoyer."
</i18n>
