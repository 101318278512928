<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :deployments="deployments"
    :resetDeploymentCacheHandler="resetDeploymentCacheHandler"
    :addons="addons"
    @view-more-button-clicked="viewMoreButtonClicked"
    @start-reset-deployment-cache="startResetDeploymentCache"
    @cancel-reset-deployment-cache="cancelResetDeploymentCache"
    @confirm-reset-deployment-cache="confirmResetDeploymentCache"
  ></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/deploy/list/All.vue";
import { ResetDeploymentCacheHandler } from "@/lib/handlers/reset/deployment-cache";
import {
  listDeployments,
  ensureDeployments,
  fetchMoreDeployments,
} from "@/store/deployments";
import { useAppAddonsStore } from "@/stores/app/addons";

export default defineComponent({
  name: "DeployListContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { resetDeploymentCacheHandler: null };
  },
  computed: {
    addonsStore() {
      return useAppAddonsStore();
    },
    deployments() {
      return listDeployments(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
    addons() {
      return {
        items: this.addonsStore.items,
        promiseInfo: this.addonsStore.promiseInfo,
      };
    },
  },
  created() {
    ensureDeployments(this.$store);
    this.addonsStore.ensure();
  },
  methods: {
    startResetDeploymentCache() {
      this.resetDeploymentCacheHandler = new ResetDeploymentCacheHandler(this);
    },
    cancelResetDeploymentCache() {
      this.resetDeploymentCacheHandler = null;
    },
    confirmResetDeploymentCache(eventArgs) {
      this.resetDeploymentCacheHandler.submit(eventArgs);
    },
    viewMoreButtonClicked() {
      fetchMoreDeployments(this.$store);
    },
  },
});
</script>
