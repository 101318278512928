<template>
  <SCCard :title="isCustom ? $t('title.change') : $t('title.upload')">
    <p>
      {{ isCustom ? $t("instructions.change") : $t("instructions.upload") }}
    </p>

    <SCButton
      v-if="isCustom"
      class="my-4"
      @click="$emit('startCertificateDeletion')"
    >
      {{ $t("actions.letsencrypt") }}
    </SCButton>

    <p class="mt-2">
      {{ $t("instructions.details") }}

      <DocLink href="https://doc.scalingo.com/platform/app/ssl" class="inline">
        {{ $t("instructions.doclink") }}
      </DocLink>
    </p>

    <Form
      ref="form"
      v-slot="{ meta }"
      as="div"
      class="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-4"
    >
      <div class="flex flex-col">
        <h4 class="font-medium text-lg">
          {{ $t("certificate") }}
        </h4>
        <Field v-slot="{ errors }" ref="tlscert" name="tlscert">
          <FileDropzone
            uid="domain-certificate"
            class="flex-grow mt-4"
            :askForInput="!form.tlscert"
            :errors="errors"
            @read-success="addCertificate"
          >
            <div v-if="form.tlscert">
              <BadgeAtom @dismiss="removeCertificate">
                {{ names.tlscert }}
              </BadgeAtom>
            </div>

            <pre v-else class="text-xs text-left text-scale-7">{{
              $t("placeholders.tlscert")
            }}</pre>
          </FileDropzone>
        </Field>
      </div>

      <div class="flex flex-col">
        <h4 class="font-medium text-lg">
          {{ $t("key") }}
        </h4>
        <Field v-slot="{ errors }" ref="tlskey" name="tlskey">
          <FileDropzone
            uid="domain-key"
            class="flex-grow mt-4"
            :askForInput="!form.tlskey"
            :errors="errors"
            @read-success="addKey"
          >
            <div v-if="form.tlskey">
              <BadgeAtom @dismiss="removeKey">
                {{ names.tlskey }}
              </BadgeAtom>
            </div>

            <pre v-else class="text-xs text-left text-scale-7">{{
              $t("placeholders.tlskey")
            }}</pre>
          </FileDropzone>
        </Field>
      </div>

      <SCButton
        block
        class="mt-2 col-span-2"
        size="lg"
        kind="primary"
        :disabled="meta.invalid || invalid"
        :loading="domainSSLHandler && domainSSLHandler.isSubmitting"
        @click="$emit('submitCertificate', form)"
      >
        {{ $t("actions.upload") }}
      </SCButton>
    </Form>
  </SCCard>
</template>

<script>
import { Form, Field } from "vee-validate";
import { defineComponent } from "vue";

import BadgeAtom from "@/components/atoms/badges/BadgeAtom.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SCCard from "@/components/molecules/card/SCCard.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import FileDropzone from "@/components/parts/app/domains/FileDropzone.vue";

export default defineComponent({
  name: "CertificatesCard",
  components: {
    SCCard,
    SCButton,
    DocLink,
    BadgeAtom,
    FileDropzone,
    Field,
    Form,
  },
  props: {
    app: Object,
    domain: Object,
    domainSSLHandler: Object,
  },
  emits: ["startCertificateDeletion", "submitCertificate"],
  data() {
    return {
      form: {},
      names: {
        tlscert: null,
        tlskey: null,
      },
    };
  },
  computed: {
    isCustom() {
      return this.domain._detailedSSLStatus === "success";
    },
    invalid() {
      return !this.form.tlscert || !this.form.tlskey;
    },
  },
  watch: {
    domainSSLHandler: {
      immediate: true,
      handler(newVal) {
        newVal.initComponent(this);
      },
    },
  },
  methods: {
    addCertificate({ name, content }) {
      this.$refs.tlscert.reset();
      this.names.tlscert = name;
      this.form.tlscert = content;
    },
    removeCertificate() {
      this.$refs.tlscert.reset();
      this.names.tlscert = null;
      this.form.tlscert = null;
    },
    addKey({ name, content }) {
      this.$refs.tlskey.reset();
      this.names.tlskey = name;
      this.form.tlskey = content;
    },
    removeKey() {
      this.$refs.tlskey.reset();
      this.names.tlskey = null;
      this.form.tlskey = null;
    },
  },
});
</script>

<i18n>
en:
  title:
    upload: "Upload your own SSL certificate"
    change: "Upload another SSL certificate"
  certificate: "Certificate (usually .crt)"
  key: "Key (usually .key)"
  actions:
    upload: "Upload"
    letsencrypt: "Use the Let’s Encrypt integration"
  instructions:
    upload: "If you wish to use your own SSL certificate, you need to upload both your certificate and key files below."
    change: |
      You can upload another set of certificate and key below,
      or use our Let’s Encrypt integration to benefit from
      the automatic certicate generation and renewal,
    details: "Files must follow the PEM format. For additional details please refer to"
    doclink: "Configure SSL/HTTPS access on our documentation center"
  placeholders:
    tlscert: |
      -----BEGIN CERTIFICATE-----
      MIIDXTCCAkWgAwIBAgIJAJC1HiIAZAiIMA0GCSqGSIb3Df
      aWRnaXRzIFB0eSBMdGQwHhcNMTExMjMxMDg1OTQ0WhcNMT
      .... MANY LINES LIKE THAT ....
      JjyzfN746vaInA1KxYEeI1Rx5KXY8zIdj6a7hhphpj2E04
      B7xxt8BVc69rLeHV15A0qyx77CLSj3tCx2IUXVqRs5mlSb
      -----END CERTIFICATE-----
    tlskey: |
      -----BEGIN PRIVATE KEY-----
      MIIFDjBABgkqhkiG9w0BBQ0wMzAbBgkqhkiG9w0BBQwwDg
      9g73NQbtqZwI+9X5OhpSg/2ALxlCCjbqvzgSu8gfFZ4yo+
      .... MANY LINES LIKE THAT ....
      X0R+meOaudPTBxoSgCCM51poFgaqt4l6VlTN4FRpj+c/WZ
      H0ga/iLNvWYexG7FHLRiq5hTj0g9mUPEbeTXuPtOkTEb/0
      -----END PRIVATE KEY-----
fr:
  title:
    upload: "Utiliser un certificat SSL personnel"
    change: "Utiliser un autre certificat SSL"
  certificate: "Certificat (généralement .crt)"
  key: "Clé (généralement .key)"
  actions:
    upload: "Uploader"
    letsencrypt: "Utiliser l'intégration Let’s Encrypt"
  instructions:
    upload: "Si vous souhaitez utiliser votre propre certificat SSL, vous devez uploader votre certificat et votre clé ci-dessous."
    change: |
      Vous pouvez uploader un autre jeu de certificat et clé ci-dessous,
      ou bien utiliser notre intégration avec Let’s Encrypt
      et profiter de la génération et du renouvellement automatique.
    details: "Les fichiers doivent être au format PEM. Pour plus d'informations, se référer à la page"
    doclink: "Configurer l'accès SSL/HTTPS sur notre documentation"
  placeholders:
    tlscert: |
      -----BEGIN CERTIFICATE-----
      MIIDXTCCAkWgAwIBAgIJAJC1HiIAZAiIMA0GCSqGSIb3Df
      aWRnaXRzIFB0eSBMdGQwHhcNMTExMjMxMDg1OTQ0WhcNMT
      .... PLUSIEURS LIGNES DU GENRE ....
      JjyzfN746vaInA1KxYEeI1Rx5KXY8zIdj6a7hhphpj2E04
      B7xxt8BVc69rLeHV15A0qyx77CLSj3tCx2IUXVqRs5mlSb
      -----END CERTIFICATE-----
    tlskey: |
      -----BEGIN PRIVATE KEY-----
      MIIFDjBABgkqhkiG9w0BBQ0wMzAbBgkqhkiG9w0BBQwwDg
      9g73NQbtqZwI+9X5OhpSg/2ALxlCCjbqvzgSu8gfFZ4yo+
      .... PLUSIEURS LIGNES DU GENRE ....
      X0R+meOaudPTBxoSgCCM51poFgaqt4l6VlTN4FRpj+c/WZ
      H0ga/iLNvWYexG7FHLRiq5hTj0g9mUPEbeTXuPtOkTEb/0
      -----END PRIVATE KEY-----
</i18n>
