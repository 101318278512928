<template>
  <section class="flex flex-col space-y-4">
    <StackCard
      :stacks="stacks"
      :app="app"
      :collaborators="collaborators"
      :deployments="deployments"
      :scmRepoLink="scmRepoLink"
      @start-stack-change="$emit('startStackChange')"
    />

    <RenameAppCard :user="user" :app="app" @click="$emit('startAppRenaming')" />

    <TransferAppCard
      :user="user"
      :app="app"
      :billingProfile="billingProfile"
      :collaborators="collaborators"
      @click="$emit('startAppTransfer')"
    />

    <RemoveAppCard :user="user" :app="app" @click="$emit('startAppDeletion')" />

    <RenameAppModal
      v-if="renameAppHandler"
      :app="app"
      :formHandler="renameAppHandler"
      @modal-closed="$emit('cancelAppRenaming')"
      @form-submitted="(e) => $emit('confirmAppRenaming', e)"
    ></RenameAppModal>

    <TransferAppModal
      v-if="transferAppHandler"
      :app="app"
      :collaborators="collaborators"
      :formHandler="transferAppHandler"
      @modal-closed="$emit('cancelAppTransfer')"
      @form-submitted="(e) => $emit('confirmAppTransfer', e)"
    ></TransferAppModal>

    <RemoveAppModal
      v-if="deleteAppHandler"
      :formHandler="deleteAppHandler"
      @modal-closed="$emit('cancelAppDeletion')"
      @form-submitted="$emit('confirmAppDeletion')"
    ></RemoveAppModal>

    <StackChangeModal
      v-if="editAppSettingsHandler"
      :stacks="stacks"
      :app="app"
      :formHandler="editAppSettingsHandler"
      @modal-closed="$emit('cancelStackChange')"
      @form-submitted="(e) => $emit('confirmStackChange', e)"
    ></StackChangeModal>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import RemoveAppCard from "@/components/parts/app/management/RemoveAppCard.vue";
import RemoveAppModal from "@/components/parts/app/management/RemoveAppModal.vue";
import RenameAppCard from "@/components/parts/app/management/RenameAppCard.vue";
import RenameAppModal from "@/components/parts/app/management/RenameAppModal.vue";
import StackCard from "@/components/parts/app/management/StackCard.vue";
import StackChangeModal from "@/components/parts/app/management/StackChangeModal.vue";
import TransferAppCard from "@/components/parts/app/management/TransferAppCard.vue";
import TransferAppModal from "@/components/parts/app/management/TransferAppModal.vue";

export default defineComponent({
  name: "Management",
  components: {
    StackChangeModal,
    StackCard,
    RenameAppCard,
    RemoveAppCard,
    TransferAppCard,
    RemoveAppModal,
    RenameAppModal,
    TransferAppModal,
  },
  props: {
    app: Object,
    user: Object,
    billingProfile: Object,
    collaborators: Object,
    stacks: Object,
    deployments: Object,
    renameAppHandler: Object,
    transferAppHandler: Object,
    editAppSettingsHandler: Object,
    deleteAppHandler: Object,
    scmRepoLink: Object,
  },
  emits: [
    "cancelAppDeletion",
    "cancelAppRenaming",
    "cancelAppTransfer",
    "cancelStackChange",
    "confirmAppDeletion",
    "confirmAppRenaming",
    "confirmAppTransfer",
    "confirmStackChange",
    "startAppDeletion",
    "startAppRenaming",
    "startAppTransfer",
    "startStackChange",
  ],
});
</script>
