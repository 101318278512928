<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"></CardHeader>
    </template>
    <template #body>
      <template v-if="articles.latestFetch.isLoading">
        <LoadingCardState />
      </template>
      <template v-if="articles.latestFetch.isFinished">
        <div class="py-4">
          <div v-if="articles.items.length > 0" class="divide-y divide-scale-2">
            <div
              v-for="(article, key) in articles.items"
              :key="key"
              class="py-2"
            >
              <a :href="article.link" target="_blank">
                {{ article.title }}
              </a>
              <span class="text-scale-5">
                - <SimpleDate :value="article.publishedAt" />
              </span>
            </div>
          </div>
          <div v-else>No blog article (probably blocked by CORS)</div>
        </div>
      </template>
    </template>
    <template #footer>
      <CardFooter
        :withViewAllButton="articles.latestFetch.isSuccess"
        :routeArgs="blogUrl"
      ></CardFooter>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";

export default defineComponent({
  name: "NewsCard",
  components: { SimpleDate, Card, CardFooter, CardHeader, LoadingCardState },
  props: {
    articles: Object,
  },
  computed: {
    blogUrl() {
      return {
        noRouter: true,
        tagAttrs: {
          href: `https://scalingo.com/${this.$i18n.locale}/blog`,
          target: "_blank",
        },
      };
    },
  },
});
</script>

<i18n>
en:
  title: "News"
  subtitle: "The latest news from Scalingo."
fr:
  title: "News"
  subtitle: "Les dernières news de Scalingo."
</i18n>
