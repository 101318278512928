<template>
  <main>
    <ChoiceCard :enabled="false" />
    <CardSeparator />
    <PlanCard :enabled="false" />
    <CardSeparator />
    <ValidationCard
      :owner="owner"
      :enabled="true"
      :loadingOperation="loadingOperation"
      :addon="addon"
      :selectedAddonProvider="selectedAddonProvider"
      :selectedAddonPlan="selectedAddonPlan"
      :context="updateCtx"
      :displayPrices="displayPrices"
      @change-addon-plan="$emit('changeAddonPlan')"
      @plan-change-requested="$emit('planChangeRequested')"
    ></ValidationCard>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import ChoiceCard from "@/components/parts/app/newAddons/ChoiceCard.vue";
import PlanCard from "@/components/parts/app/newAddons/PlanCard.vue";
import ValidationCard from "@/components/parts/app/newAddons/ValidationCard.vue";
import { RemoteOperation } from "@/lib/store/remote-operation";

export default defineComponent({
  name: "AddonsEditionStep3",
  components: {
    ValidationCard,
    PlanCard,
    ChoiceCard,
    CardSeparator,
  },
  props: {
    owner: Object,
    loadingOperation: RemoteOperation,
    addon: Object,
    selectedAddonProvider: Object,
    selectedAddonPlan: Object,
    updateCtx: Object,
    displayPrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["changeAddonPlan", "planChangeRequested"],
});
</script>
