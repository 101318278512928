<template>
  <DbNotRunning
    v-if="db.status !== 'running'"
    :app="app"
    :db="db"
    :withCartridge="false"
  />
  <main class="mt-8">
    <DividedSectionTitle :title="$t('title')" :subtitle="$t('subtitle')">
      <div class="flex flex-row items-center space-x-4">
        <DateSelector
          :selectedValue="since"
          @range-selected="(e) => $emit('rangeSelected', e)"
        />
        <SmallToggle v-model="showGateways"> Gateways </SmallToggle>
      </div>
    </DividedSectionTitle>

    <template v-for="(data, id) in instancesData" :key="id">
      <div v-if="instanceFor(id).type === 'db-node'" class="mt-4">
        <h3 class="text-lg font-bold py-4" :title="'Id: ' + id">
          {{ $t("dbNode") }}
        </h3>
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
          <CpuCard :promiseInfo="data.cpu.info" :cpuData="data.cpu.sets.cpu" />

          <MemoryCard
            :promiseInfo="data.memory.info"
            :dbPlan="dbPlan"
            :memoryData="data.memory.sets.memory"
            :swapData="data.memory.sets.swap"
          />

          <DiskUsageCard
            :promiseInfo="data.disk.info"
            :dbPlan="dbPlan"
            :usedData="data.disk.sets.used"
          />

          <DiskIOCard
            :promiseInfo="data.disk.info"
            :readsData="data.diskio.sets.reads"
            :writesData="data.diskio.sets.writes"
          />
        </div>
      </div>
    </template>
    <template v-for="(data, id) in instancesData" :key="id">
      <div
        v-if="showGateways && instanceFor(id).type === 'gateway'"
        class="mt-4"
      >
        <h3 class="text-lg font-bold py-4" :title="'Id: ' + id">
          {{ $t("gatewayNode") }}
        </h3>
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
          <CpuCard :promiseInfo="data.cpu.info" :cpuData="data.cpu.sets.cpu" />

          <MemoryCard
            :promiseInfo="data.memory.info"
            :dbPlan="dbPlan"
            :memoryData="data.memory.sets.memory"
            :swapData="data.memory.sets.swap"
          />
        </div>
      </div>
    </template>
  </main>
</template>

<script>
import { defineComponent, ref } from "vue";

import DividedSectionTitle from "@/components/atoms/titles/DividedSectionTitle.vue";
import SmallToggle from "@/components/molecules/toggles/SmallToggle.vue";
import DateSelector from "@/components/parts/app/showMetrics/DateSelector.vue";
import CpuCard from "@/components/parts/db/metrics/CpuCard.vue";
import DiskIOCard from "@/components/parts/db/metrics/DiskIOCard.vue";
import DiskUsageCard from "@/components/parts/db/metrics/DiskUsageCard.vue";
import MemoryCard from "@/components/parts/db/metrics/MemoryCard.vue";
import DbNotRunning from "@/components/views/db/DbNotRunning.vue";

export default defineComponent({
  name: "DbMetrics",
  components: {
    DbNotRunning,
    DiskIOCard,
    DiskUsageCard,
    SmallToggle,
    DateSelector,
    DividedSectionTitle,
    CpuCard,
    MemoryCard,
  },
  props: {
    app: Object,
    addon: Object,
    db: Object,
    dbPlan: Object,
    user: Object,
    since: Number,
    instancesData: Object,
  },
  emits: ["rangeSelected"],
  setup(props) {
    function instanceFor(id) {
      return props.db.instances.find((instance) => instance.id === id);
    }
    let showGateways = ref(false);

    return { instanceFor, showGateways };
  },
});
</script>

<i18n>
  en:
    title: "Metrics"
    subtitle: "Analyse key indicators of performance and consumption."
    dbNode: "Db node instance"
    gatewayNode: "Gateway instance"
  fr:
    title: "Métriques"
    subtitle: "Analyse des indicateurs clés de performance et de consommation."
    dbNode: "Instance BDD"
    gatewayNode: "Instance Gateway"
</i18n>
