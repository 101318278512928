<template>
  <ViewComponent
    :app="app"
    :addon="addon"
    :db="db"
    :dbVersion="dbVersion"
    :dbPlan="dbPlan"
    :deleteAddonCtx="deleteAddonCtx"
    :upgradeCtx="upgradeCtx"
    @cancel-db-upgrade="cancelDbUpgrade"
    @confirm-db-upgrade="confirmDbUpgrade"
    @start-db-upgrade="startDbUpgrade"
    @cancel-addon-deletion="cancelAddonDeletion"
    @confirm-addon-deletion="confirmAddonDeletion"
    @start-addon-deletion="startAddonDeletion"
  ></ViewComponent>
</template>

<script>
import { ref, computed, defineComponent, onBeforeMount, nextTick } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/db/settings/General.vue";
import i18n from "@/i18n";
import { closeCurrentModal } from "@/lib/modals";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import { importOperationMethods } from "@/mixins/operation";
import { Routes } from "@/router/names";
import { useAppAddonsStore } from "@/stores/app/addons";
import { useCurrentAppStore } from "@/stores/current/app";
import {
  currentDB,
  currentDBClient,
  useCurrentDBStore,
} from "@/stores/current/db";
import { useDbBannersStore } from "@/stores/db/banners";
import { useDbMetricsStore } from "@/stores/db/metrics";
import { useDbPlanStore } from "@/stores/db/plan";
import { useDbVersionStore } from "@/stores/db/version";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "GeneralContainer",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();

    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const dbVersionStore = useDbVersionStore();
    const dbPlanStore = useDbPlanStore();
    const addonsStore = useAppAddonsStore();
    const deleteAddonCtx = ref(null);
    const upgradeCtx = ref(null);
    const router = useRouter();

    const app = currentAppStore.appInfoOrFull;
    const addon = currentDbStore.addon;

    onBeforeMount(() => {
      dbVersionStore.ensure();
      dbPlanStore.ensure();
    });

    async function confirmAddonDeletion() {
      const addon = deleteAddonCtx.value.addon;

      deleteAddonCtx.value.info = addonsStore.destroy(addon);

      try {
        await deleteAddonCtx.value.info.promise;
        await nextTick();

        toastsStore.addOne({
          type: "success",
          title: t("notifications.addons.deleted.success.title"),
          message: t("notifications.addons.deleted.success.message"),
        });

        closeCurrentModal();
        router.push({
          name: Routes.App.Root,
          params: { region: app.region, id: app.name },
        });
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("notifications.addons.deleted.error.title"),
          message: t("notifications.addons.deleted.error.message"),
        });
      }
    }

    async function confirmDbUpgrade() {
      const client = await currentDBClient();
      const db = currentDB();
      const dbMetricsStore = useDbMetricsStore();
      const bannersStore = useDbBannersStore();

      const { fetchDbOperationById } = importOperationMethods();

      try {
        const promise = client.Database.upgradeDatabase(db.id);
        upgradeCtx.value.info = promiseInfo(promise);
        const response = await promise;
        fetchDbOperationById(client, { id: response.operation.id });
        await nextTick();
        await currentDbStore.fetchDatabase();
        dbMetricsStore.ensure({ staleAfter: "always" });
      } catch (e) {
        bannersStore.setGenericBanner({
          name: "databaseUpgrade",
          kind: "error",
          dismissible: true,
          title: i18n.global.t("banners.databaseUpgrade.trigger-error.title"),
          subtitle: i18n.global.t(
            "banners.databaseUpgrade.trigger-error.subtitle",
            { error: e.message },
          ),
        });
      }

      upgradeCtx.value = null;
    }

    const dbVersion = computed(() => {
      return {
        item: dbVersionStore.item,
        promiseInfo: dbVersionStore.promiseInfo,
      };
    });

    return {
      db: computed(() => currentDbStore.database),
      dbPlan: computed(() => {
        return {
          item: dbPlanStore.item,
          promiseInfo: dbPlanStore.promiseInfo,
        };
      }),
      startAddonDeletion() {
        deleteAddonCtx.value = {
          info: voidPromiseInfo(),
          app: app,
          addon: addon,
        };
      },
      cancelAddonDeletion() {
        deleteAddonCtx.value = null;
      },
      dbVersion,
      startDbUpgrade() {
        upgradeCtx.value = {
          info: voidPromiseInfo(),
          db: computed(() => currentDbStore.database),
          dbVersion: dbVersion,
        };
      },
      cancelDbUpgrade() {
        upgradeCtx.value = null;
      },
      confirmAddonDeletion,
      deleteAddonCtx,
      confirmDbUpgrade,
      upgradeCtx,
      app,
      addon,
    };
  },
});
</script>
