<template>
  <ViewComponent :user="currentUser" :supportPlan="supportPlan" />
</template>

<script setup>
import { ref } from "vue";

import ViewComponent from "@/components/views/billing/SupportPlans.vue";

const supportPlan = ref("basic");
</script>
