<template>
  <div class="flex">
    <SmallToggleAtom
      :modelValue="toggled"
      @update:model-value="toggle"
    ></SmallToggleAtom>
    <span class="ml-3 cursor-pointer text-scale-10" @click="toggle"
      ><slot></slot
    ></span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SmallToggleAtom from "../../atoms/toggles/SmallToggle";

export default defineComponent({
  name: "SmallToggle",
  components: { SmallToggleAtom },
  props: {
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      toggled: !!this.modelValue,
    };
  },
  watch: {
    value(value) {
      this.toggled = !!value;
    },
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
      this.$emit("update:modelValue", this.toggled);
    },
  },
});
</script>
