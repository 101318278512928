<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
        :withButton="false"
      ></CardHeader
    ></template>
    <template #body>
      <template v-if="reviewApps.latestFetch.isLoading">
        <LoadingCardState />
      </template>
      <template v-if="reviewApps.latestFetch.isSuccess">
        <EmptyCardState v-if="reviewApps.items.length === 0">
          <template #icon>
            <font-awesome-icon
              icon="circle"
              class="h-4 text-scale-5"
            ></font-awesome-icon>
          </template>
          {{ $t("empty") }}
        </EmptyCardState>

        <ReviewAppsList
          v-else
          class="mt-2"
          :parentApp="parentApp"
          :scmRepoLink="scmRepoLink"
          :reviewApps="reviewApps"
        />
      </template>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ReviewAppsList from "@/components/parts/app/showReviewApps/ReviewAppsList.vue";

export default defineComponent({
  name: "ReviewAppsCard",
  components: {
    Card,
    ReviewAppsList,
    LoadingCardState,
    EmptyCardState,
    CardHeader,
  },
  props: {
    parentApp: Object,
    scmRepoLink: Object,
    reviewApps: Object,
  },
});
</script>

<i18n>
en:
  title: "Review apps"
  subtitle: "Newly created first."
  empty: "No review app yet"
fr:
  title: "Review apps"
  subtitle: "De la plus récente à la moins récente."
  empty: "Aucune review app"
</i18n>
