<template>
  <FormModal
    :withCancelBtn="false"
    :promiseInfo="context.info"
    :title="$t('title')"
    :fieldErrors="errors"
    panelClasses="sm:w-full sm:max-w-lg"
    :submitLabel="$t('action')"
    @close="(e) => $emit('close', e)"
    @submit="onSubmit"
  >
    <div class="mt-6">
      <Field
        v-slot="{ field, fieldErrors, handleChange }"
        v-model="form.periodic_backups_enabled"
        name="periodicBackupsEnabled"
      >
        <CheckboxInput
          :name="field.name"
          :modelValue="db.periodic_backups_enabled"
          class="mb-5 mt-3"
          :errors="fieldErrors"
          :label="$t('enable')"
          @update:model-value="handleChange"
        ></CheckboxInput>
      </Field>
    </div>
    <div class="border-t text-scale-8">
      <Field
        v-slot="{ field, fieldErrors, handleChange }"
        v-model="form.periodic_backups_scheduled_at"
        name="periodic_backups_scheduled_at"
      >
        <span
          class="flex items-center mt-4"
          :class="!periodicBackupsEnabled ? 'text-scale-4' : ''"
          ><span class="mr-2">{{ $t("schedule") }}</span
          ><TextInput
            :modelValue="field.value"
            :disabled="!periodicBackupsEnabled"
            :name="field.name"
            :errors="fieldErrors"
            type="number"
            max="23"
            min="0"
            class="w-32"
            @update:model-value="handleChange"
          ></TextInput>
          <span class="ml-2">UTC</span>
        </span>
      </Field>
    </div>
  </FormModal>
</template>

<script>
import { Field } from "vee-validate";
import { computed, defineComponent, reactive } from "vue";

import TextInput from "@/components/atoms/inputs/TextInput.vue";
import CheckboxInput from "@/components/molecules/inputs/CheckboxInput.vue";
import FormModal from "@/components/molecules/modals/FormModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export function initForm(extra) {
  return {
    ...extra,
  };
}

export default defineComponent({
  components: {
    Field,
    FormModal,
    TextInput,
    CheckboxInput,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    context: { type: Object, required: true },
    db: { type: Object, required: true },
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const form = reactive(
      initForm({
        periodic_backups_scheduled_at: props.db.periodic_backups_scheduled_at,
      }),
    );

    const formattedErrors = computed(() => {
      if (!props.context?.info?.err) return null;

      return props.context.info.err.data.db;
    });

    const onSubmit = () => {
      emit("submit", form);
    };

    const periodicBackupsEnabled = computed(() => {
      if (props.db.periodic_backups_enabled) {
        if (form.periodic_backups_enabled !== false) {
          return true;
        } else {
          return false;
        }
      } else if (form.periodic_backups_enabled) {
        return true;
      } else {
        return false;
      }
    });

    const errors = computed(() => props.context.errors);

    return {
      form,
      errors,
      formattedErrors,
      onSubmit,
      periodicBackupsEnabled,
    };
  },
});
</script>

<i18n>
en:
  title: "Scheduled backups"
  enable: "I want to enable scheduled backups"
  action: "Update"
  schedule: "Schedule backups at"
fr:
  title: "Backups planifiés"
  enable: "Je veux activer la planification des backups"
  action: "Mise à jour"
  schedule: "Backups planifiés à"
</i18n>
