<template>
  <ViewComponent
    :owner="app.owner"
    :loadingOperation="loadingOperation"
    :addon="addon"
    :selectedAddonProvider="selectedAddonProvider"
    :selectedAddonPlan="selectedAddonPlan"
    :updateCtx="updateCtx"
    :displayPrices="!c3Customer"
    @change-addon-plan="changeAddonPlan"
    @plan-change-requested="planChangeRequested"
  ></ViewComponent>
</template>

<script>
import { APIError } from "scalingo/lib/errors";
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/addonsEdition/Step3.vue";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { errorsMeta } from "@/lib/scalingo/errors";
import { Routes } from "@/router/names";
import { listAddonProviders } from "@/store/addon-providers";
import { billingProfile } from "@/store/billing-profile";
import { useAppAddonsStore } from "@/stores/app/addons";

export default defineComponent({
  name: "AddonsEditionStep3Container",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    addon: Object,
  },
  data() {
    return {
      updateCtx: { info: voidPromiseInfo() },
    };
  },
  computed: {
    addonsStore() {
      return useAppAddonsStore();
    },
    loadingOperation() {
      return this.addonProviders.latestFetch;
    },
    addonProviders() {
      return listAddonProviders(this.$store, this.app.region);
    },
    billingProfile() {
      return billingProfile(this.$store);
    },
    selectedAddonProvider() {
      return this.addonProviders.items.find((provider) => {
        return provider.id === this.addon.addon_provider.id;
      });
    },
    selectedAddonPlan() {
      return this.selectedAddonProvider?.plans?.find((plan) => {
        return plan.name === this.$route.params.planId;
      });
    },
  },
  methods: {
    changeAddonPlan() {
      this.$router.push({
        name: Routes.App.Addon.Edition.Step2,
        params: {
          ...this.$route.params,
          planId: null,
        },
      });
    },
    async planChangeRequested() {
      this.updateCtx = { info: voidPromiseInfo(), errors: {} };

      const payload = {
        plan_id: this.selectedAddonPlan.id,
      };

      try {
        const promise = this.addonsStore.update(this.addon, payload);
        this.updateCtx.info = promiseInfo(promise);

        await promise;

        this.$router.push({
          name: Routes.App.Resources,
          params: {
            id: this.$route.params.id,
            region: this.$route.params.region,
          },
        });
      } catch (err) {
        this.updateCtx.errors.meta = errorsMeta(err);

        if (err instanceof APIError) {
          if (err.data?.message) {
            if (err.data.message.match(/upgrade it to mysql 8/i)) {
              this.updateCtx.errors.message = this.$t("errors.mysqlBusiness");
            } else {
              this.updateCtx.errors.message = err.data.message;
            }
          }
        }
      }
    },
  },
});
</script>

<i18n>
en:
  errors:
    mysqlBusiness: "To upgrade your MySQL instance to a Business Plan, you must first upgrade it to MySQL 8.0 LTS."
fr:
  errors:
    mysqlBusiness: "Pour mettre à niveau votre instance MySQL vers un plan Business, vous devez préalablement la mettre à jour vers MySQL 8.0 LTS."
</i18n>
