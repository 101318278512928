<template>
  <FormModal
    :withCancelBtn="false"
    :promiseInfo="context.info"
    :title="$t('title')"
    :fieldErrors="errors"
    panelClasses="sm:w-full sm:max-w-lg"
    :submitLabel="$t('action')"
    @close="(e) => $emit('close', e)"
    @submit="onSubmit"
  >
    <div class="mt-6">
      <span class="text-scale-10">{{ $t("body") }}</span>
    </div>
    <div class="text-scale-8 flex flex-row space-x-6">
      <Field
        v-slot="{ field, fieldErrors, handleChange }"
        v-model="form.weekday_utc"
        name="weekday_utc"
      >
        <span class="items-center mt-4">
          <span class="mr-2 text-sm text-scale-6">{{ $t("selectDay") }}</span>
          <SelectInput
            :modelValue="field.value"
            :name="field.name"
            :errors="fieldErrors"
            class="w-32"
            :options="daysOptions"
            @update:model-value="handleChange"
          ></SelectInput>
        </span>
      </Field>
      <Field
        v-slot="{ field, fieldErrors, handleChange }"
        v-model="form.starting_hour_utc"
        name="starting_hour_utc"
      >
        <span class="items-center mt-4"
          ><span class="mr-2 text-sm text-scale-6">{{
            $t("startingTime")
          }}</span>
          <div class="flex items-center">
            <SelectInput
              :modelValue="field.value"
              :name="field.name"
              :errors="fieldErrors"
              class="w-32"
              :options="hoursOptions"
              @update:model-value="handleChange"
            >
            </SelectInput>
            <span class="ml-2">UTC</span>
          </div>
        </span>
      </Field>
    </div>
  </FormModal>
</template>

<script>
import { DateTime } from "luxon";
import { Field } from "vee-validate";
import { computed, defineComponent, reactive } from "vue";

import SelectInput from "@/components/atoms/inputs/SelectInput.vue";
import FormModal from "@/components/molecules/modals/FormModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export function initForm(extra) {
  return {
    ...extra,
  };
}

export default defineComponent({
  components: {
    Field,
    FormModal,
    SelectInput,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    context: { type: Object, required: true },
    db: { type: Object, required: true },
    periodicBackupsTimeInfo: Object,
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const form = reactive(
      initForm({
        weekday_utc: props.db.maintenance_window.weekday_utc,
        starting_hour_utc: props.db.maintenance_window.starting_hour_utc,
      }),
    );

    const formattedErrors = computed(() => {
      if (!props.context?.info?.err) return null;

      return props.context.info.err.data.db;
    });

    const onSubmit = () => {
      emit("submit", form);
    };

    const errors = computed(() => props.context.errors);

    return {
      form,
      errors,
      formattedErrors,
      onSubmit,
    };
  },
  data() {
    return {
      daysOptions: [],
    };
  },
  computed: {
    hoursOptions() {
      const options = [];
      const locale = this.$i18n.locale;
      const hour12 = locale === "en";

      for (let i = 0; i < 24; i++) {
        const time = DateTime.fromObject({ hour: i }).toLocaleString({
          hour: "numeric",
          minute: "2-digit",
          hour12: hour12,
          locale: locale,
        });
        options.push({ text: time, value: i });
      }

      return options;
    },
  },
  mounted() {
    for (let i = 1; i <= 7; i++) {
      const dayOfWeek = DateTime.local()
        .set({ weekday: i })
        .toLocaleString({ weekday: "long" });

      // humanize class doesn't work on SelectInput
      const dayOfWeekCapitalized =
        dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

      this.daysOptions.push({ text: dayOfWeekCapitalized, value: i - 1 });
    }
  },
});
</script>

<i18n>
en:
  title: "Maintenance Window Schedule"
  body: "The upcoming maintenance operations will be scheduled during an 8-hour window."
  selectDay: "Select a day"
  startingTime: "Select a starting time"
  action: "Update schedule"

fr:
  title: "Planification de la fenêtre de maintenance"
  body: "Les prochaines opérations de maintenances seront planifiées durant une fenêtre de 8 heures."
  selectDay: "Sélectionnez un jour"
  startingTime: "Sélectionnez une heure de début"
  action: "Mettre à jour la planification"
</i18n>
