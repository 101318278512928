<template>
  <SCTable
    :headers="headers"
    :items="invoices.items"
    :searchable="true"
    :hasMore="hasMore"
    :fetchingMore="invoices.latestFetch.isLoading"
    :searchFn="searchFn"
    @fetch-more="$emit('fetchMore')"
  >
    <template #item.paid="{ item }">
      <div :title="statusFor(item)">
        <CheckGlyph v-if="!!item.paid_at" class="text-color-1" />
        <BillGlyph
          v-else-if="item.state === 'new'"
          class="text-secondary-2 w-6"
        />
        <DangerSignGlyph
          v-else-if="item.state === 'cancelled'"
          class="text-scale-6"
        />
        <DangerSignGlyph v-else class="text-color-2" />
      </div>
    </template>

    <template #item.number="{ item }">
      <router-link
        class="font-mono hover:underline"
        :to="invoiceRouteFor(item)"
      >
        {{ item.invoice_number }}
      </router-link>
    </template>

    <template #item.date="{ item }">
      <div class="humanize">
        {{ formatDateTime(item.billing_month, "LLLL y") }}
      </div>
    </template>

    <template #item.amount="{ item }">
      {{ formatAmount(item.total_price_with_vat) }}
    </template>

    <template #item.state="{ item }">
      <PaymentStatus :invoiceState="item.state"></PaymentStatus>
    </template>

    <template #item.actions="{ item }">
      <div class="inline-flex text-scale-5">
        <router-link
          :to="invoiceRouteFor(item)"
          :title="$t('seeInvoiceDetails')"
        >
          <EyeGlyph />
        </router-link>

        <a
          v-if="item.pdf_url"
          class="ml-2"
          href="#"
          :title="$t('downloadInvoicePdf')"
          @click="$emit('downloadPdf', { invoice: item })"
        >
          <PdfGlyph />
        </a>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import EyeGlyph from "@/components/atoms/glyphs/EyeGlyph.vue";
import PdfGlyph from "@/components/atoms/glyphs/PdfGlyph.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import PaymentStatus from "@/components/parts/billing/showBilling/PaymentStatus.vue";
import { formatAmount } from "@/lib/utils/currency";
import { formatDateTime } from "@/lib/utils/time";
import { Routes } from "@/router/names";

function searchFn(row, search) {
  const cols = [
    row.invoice_number,
    row.billing_month,
    formatAmount(row.total_price_with_vat),
  ];
  return cols.find((col) => {
    return (
      col.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !==
      -1
    );
  });
}

export default defineComponent({
  name: "InvoicesListBody",
  components: {
    SCTable,
    DangerSignGlyph,
    PaymentStatus,
    CheckGlyph,
    PdfGlyph,
    EyeGlyph,
    BillGlyph,
  },
  props: {
    invoices: Object,
  },
  emits: ["fetchMore", "downloadPdf"],
  data() {
    return {
      searchFn: searchFn,
      headers: [
        { value: "paid", text: "", class: "w-8" },
        {
          value: "number",
          text: this.$i18n.t(`headers.number`),
          class: "w-40",
        },
        { value: "date", text: this.$i18n.t(`headers.date`), class: "w-40" },
        { value: "amount", text: this.$i18n.t(`headers.amount`) },
        { value: "actions", text: "", align: "right" },
      ],
    };
  },
  computed: {
    hasMore() {
      return this.invoices.latestFetch.isSuccess
        ? !!this.invoices.meta?.pagination?.next_page
        : false;
    },
  },
  methods: {
    formatAmount,
    formatDateTime,
    statusFor(item) {
      return this.$t(`attributes.invoice.statuses.${item.state}`);
    },
    invoiceRouteFor(item) {
      return {
        name: Routes.Billing.Invoices.Show,
        params: {
          id: item.id,
        },
      };
    },
  },
});
</script>

<i18n>
en:
  headers:
    number: "Nº"
    date: "Billing month"
    amount: "Amount"
    state: "Status"
  seeInvoiceDetails: "See invoice details"
  downloadInvoicePdf: "Download invoice (PDF)"
fr:
  headers:
    number: "Nº"
    date: "Mois facturé"
    amount: "Montant"
    state: "Statut"
  seeInvoiceDetails: "Voir la facture en détails"
  downloadInvoicePdf: "Télécharger facture (PDF)"
</i18n>
