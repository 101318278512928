<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :domains="domains"
    :sortBy="sortBy"
    :sortDirection="sortDirection"
    :url="defaultAppDomain"
    :createDomainHandler="createDomainHandler"
    :deleteDomainHandler="deleteDomainHandler"
    :makeCanonicalHandler="makeCanonicalHandler"
    :unmakeCanonicalHandler="unmakeCanonicalHandler"
    @start-domain-creation="startDomainCreation"
    @cancel-domain-creation="cancelDomainCreation"
    @submit-domain-creation="(e) => createDomainHandler.submit(e)"
    @start-domain-deletion="startDomainDeletion"
    @cancel-domain-deletion="cancelDomainDeletion"
    @submit-domain-deletion="(e) => deleteDomainHandler.submit(e)"
    @start-make-canonical="startMakeCanonical"
    @cancel-make-canonical="cancelMakeCanonical"
    @submit-make-canonical="submitMakeCanonical"
    @start-unmake-canonical="startUnmakeCanonical"
    @cancel-unmake-canonical="cancelUnmakeCanonical"
    @submit-unmake-canonical="submitUnmakeCanonical"
    @sort-changed="sortChanged"
  ></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/domains/Index.vue";
import { CreateDomainHandler } from "@/lib/handlers/create/domain";
import { DeleteDomainHandler } from "@/lib/handlers/delete/domain";
import { SetDefaultDomainHandler } from "@/lib/handlers/set-default/domain";
import { UnsetDefaultDomainHandler } from "@/lib/handlers/unset-default/domain";
import { availableRegions } from "@/lib/scalingo/client";
import { ensureDomains, listDomains } from "@/store/domains";

export default defineComponent({
  name: "DomainsIndexCtrl",
  components: { ViewComponent },
  props: {
    app: Object,
  },
  data() {
    return {
      sortBy: "name",
      sortDirection: "asc",
      createDomainHandler: null,
      deleteDomainHandler: null,
      makeCanonicalHandler: null,
      unmakeCanonicalHandler: null,
    };
  },
  computed: {
    defaultAppDomain() {
      const region = availableRegions.find((r) => r.code === this.app.region);

      return `${this.app.name}.${region.appSubdomain}.`;
    },
    domains() {
      return listDomains(this.$store, {
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      });
    },
  },
  created() {
    ensureDomains(this.$store);
  },
  methods: {
    startDomainCreation() {
      this.createDomainHandler = new CreateDomainHandler(this);
    },
    cancelDomainCreation() {
      this.createDomainHandler = null;
    },
    startDomainDeletion({ domain }) {
      this.deleteDomainHandler = new DeleteDomainHandler(this, domain);
    },
    cancelDomainDeletion() {
      this.deleteDomainHandler = null;
    },
    removeDomainFormSubmitted(eventArgs) {
      this.deleteDomainHandler.submit(eventArgs);
    },
    startMakeCanonical({ domain }) {
      this.makeCanonicalHandler = new SetDefaultDomainHandler(this, domain);
    },
    cancelMakeCanonical() {
      this.makeCanonicalHandler = null;
    },
    submitMakeCanonical() {
      this.makeCanonicalHandler.submit();
    },
    startUnmakeCanonical({ domain }) {
      this.unmakeCanonicalHandler = new UnsetDefaultDomainHandler(this, domain);
    },
    cancelUnmakeCanonical() {
      this.unmakeCanonicalHandler = null;
    },
    submitUnmakeCanonical() {
      this.unmakeCanonicalHandler.submit();
    },
    sortChanged({ sortBy, sortDirection }) {
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
    },
  },
});
</script>
