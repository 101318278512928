<template>
  <main class="container px-4">
    <section class="mt-8">
      <SectionTitle
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      ></SectionTitle>
      <BackLinkButton class="my-2" :routeArgs="backLink">{{
        $t("backToLogs")
      }}</BackLinkButton>
    </section>
    <section>
      <Card class="mt-6">
        <template #body>
          <EmptyCardState v-if="isEmpty">
            <template #icon>
              <font-awesome-icon icon="circle" class="h-4 text-scale-5" />
            </template>
            {{ $t("empty") }}
          </EmptyCardState>

          <LogsArchivesList
            v-else
            class="mt-2"
            :logsArchives="logsArchives"
            @fetch-more="$emit('fetchMoreArchives')"
          />
        </template>
      </Card>
    </section>
    <ProTipLogsViaCLI class="mt-4" :app="app"></ProTipLogsViaCLI>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import SectionTitle from "@/components/atoms/titles/SectionTitle.vue";
import Card from "@/components/molecules/card/Card.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import ProTipLogsViaCLI from "@/components/organisms/alerts/ProTipLogsViaCLI.vue";
import LogsArchivesList from "@/components/parts/app/listLogsArchives/LogsArchivesList.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "LogsArchives",
  components: {
    ProTipLogsViaCLI,
    BackLinkButton,
    SectionTitle,
    LogsArchivesList,
    EmptyCardState,
    Card,
  },
  props: {
    app: Object,
    user: Object,
    logsArchives: Object,
  },
  emits: ["fetchMoreArchives", "routerLogsToggled", "streamingToggled"],
  data: function () {
    return {};
  },
  computed: {
    isEmpty() {
      return (
        this.logsArchives.latestFetch.isSuccess &&
        this.logsArchives.items.length === 0
      );
    },
    backLink() {
      return { name: Routes.App.Logs.Live, params: { id: this.app.name } };
    },
  },
  methods: {
    routerLogsToggled(eventArgs) {
      this.$emit("routerLogsToggled", eventArgs);
    },
    streamingToggled(eventArgs) {
      this.$emit("streamingToggled", eventArgs);
    },
  },
});
</script>

<i18n>
  en:
    title: "Logs / Archives"
    subtitle: "Logs app archives, downloadable in gzip format"
    viewArchives: "View archives"
    backToLogs: "Back to Logs"
    empty: "No logs archive yet"
  fr:
    title: "Logs / Archives"
    subtitle: "Logs archivés de votre application"
    viewArchives: "Voir archives"
    backToLogs: "Revenir aux Logs"
    empty: "Aucun archive de logs"
</i18n>
