<template>
  <WarningModal
    :formHandler="formHandler"
    @modal-closed="$emit('modalClosed')"
    @form-submitted="$emit('warningAccepted')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("message") }}
    </template>
    <template #button>{{ $t("button") }}</template>
  </WarningModal>
</template>

<script>
import { defineComponent } from "vue";

import WarningModal from "@/components/molecules/modalsCollection/WarningModal.vue";

export default defineComponent({
  name: "UnmakeCanonicalWarning",
  components: { WarningModal },
  props: {
    name: String,
    formHandler: Object,
  },
  emits: ["modalClosed", "warningAccepted"],
});
</script>

<i18n>
en:
  title: "Remove canonicity"
  button: "Apply"
  message: "Are you sure to want remove domain canonicity?"
fr:
  title: "Retirer la canonicité"
  button: "Appliquer"
  message: "Etes vous sur de vouloir retirer la cannocité?"
</i18n>
