<template>
  <section>
    <template v-if="scmRepoLink.latestFetch.isLoading">
      <LoadingCardState />
    </template>
    <template v-if="scmRepoLink.latestFetch.isSuccess">
      <ManualReviewAppCard
        :app="app"
        :scmRepoLink="scmRepoLink.value"
        :scmPullRequests="scmPullRequests"
        :reviewApps="reviewApps"
        :deployHandler="deployHandler"
        :listPullRequestsInfo="listPullRequestsInfo"
        @fetch-scm-repo-link-pull-requests="
          $emit('fetchScmRepoLinkPullRequests')
        "
        @manual-review-app="(e) => requestManualReviewApp(e)"
      ></ManualReviewAppCard>
    </template>
    <ManualReviewAppModal
      v-if="manuelReviewAppEvent"
      :event="manuelReviewAppEvent"
      @confirm="confirmManualReviewApp"
      @close="cancelManualReviewApp"
    ></ManualReviewAppModal>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ManualReviewAppCard from "@/components/parts/app/showDeployConfiguration/ManualReviewAppCard.vue";
import ManualReviewAppModal from "@/components/parts/app/showDeployConfiguration/ManualReviewAppModal.vue";

export default defineComponent({
  name: "ReviewAppsManual",
  components: {
    ManualReviewAppModal,
    ManualReviewAppCard,
    LoadingCardState,
  },
  props: {
    app: Object,
    user: Object,
    scmRepoLink: Object,
    scmPullRequests: Array,
    reviewApps: Object,
    deployHandler: Object,
    listPullRequestsInfo: Object,
  },
  emits: ["fetchScmRepoLinkPullRequests", "manualReviewApp"],
  data() {
    return {
      manuelReviewAppEvent: null,
    };
  },
  methods: {
    confirmManualReviewApp() {
      this.$emit("manualReviewApp", this.manuelReviewAppEvent);
      this.manuelReviewAppEvent = null;
    },
    cancelManualReviewApp() {
      this.manuelReviewAppEvent = null;
    },
    requestManualReviewApp(e) {
      if (!e.opened_from_a_forked_repo) {
        this.$emit("manualReviewApp", e);
        return;
      }
      this.manuelReviewAppEvent = e;
    },
  },
});
</script>
