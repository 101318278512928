<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons>
          <SCButton
            :loading="false"
            @click="$emit('startMaintenanceConfiguration')"
          >
            {{ $t("edit") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="flex flex-row space-x-3">
        <div class="bg-scale-1 w-12 h-12 p-2 rounded-lg text-scale-4">
          <font-awesome-icon icon="wrench" class="h-8 w-8" />
        </div>
        <div class="flex flex-col text-scale-10">
          <div class="flex flex-col">
            <div>{{ $t("scheduled") }}</div>
            <div class="text-scale-10 text-base font-medium humanize">
              {{ weekDay }} {{ $t("between") }}
              {{ formattedScheduledTime }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>
<script>
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

const { DateTime } = require("luxon");

export default {
  name: "MaintenanceWindowCard",
  components: { SCButton, Card, CardHeader },
  props: {
    db: Object,
  },
  emits: ["startMaintenanceConfiguration"],
  computed: {
    weekDay() {
      // Luxon handles weekdays from 1 to 7. But db.maintenance_window.weekday_utc is a value between 0 (Monday) and 6 (Sunday).
      const weekdayUTC = this.db.maintenance_window.weekday_utc + 1;
      const template = DateTime.utc().setLocale(this.$i18n.locale);
      return template
        .set({ weekday: weekdayUTC })
        .toLocaleString({ weekday: "long" });
    },

    startTime() {
      const startingHourUTC = this.db.maintenance_window.starting_hour_utc;
      return DateTime.utc().setLocale(this.$i18n.locale).set({
        hour: startingHourUTC,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    },

    endTime() {
      const durationInHours = this.db.maintenance_window.duration_in_hour;
      return this.startTime.plus({ hours: durationInHours });
    },

    formattedScheduledTime() {
      return `${this.startTime.toLocaleString(DateTime.TIME_SIMPLE)} ${this.$t(
        "and",
      )} ${this.endTime.toLocaleString(DateTime.TIME_SIMPLE)} UTC`;
    },
  },
};
</script>

<i18n>
en:
  title: "Maintenance Window"
  subtitle: "Define the 8 hour window that best suits the workload of this database."
  between: "between"
  and: "and"
  scheduled: "Scheduled:"
  edit: "Update schedule"
fr:
  title: "Fenêtre de Maintenance"
  subtitle: "Définissez la fenêtre de 8 heures la plus adaptée à la charge de travail de cette base de données."
  between: "entre"
  and: "et"
  scheduled: "Planifié :"
  edit: "Mettre à jour la planification"
</i18n>
