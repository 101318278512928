<template>
  <ViewComponent
    v-if="currentUser"
    :user="currentUser"
    :contracts="contracts"
    :acceptContractHandler="acceptContractHandler"
    :accountFormHandler="accountFormHandler"
    :preferencesFormHandler="preferencesFormHandler"
    :availableLocales="availableLocales"
    :availableRoles="availableRoles"
    @fetch-all-contracts="fetchAllContracts"
    @start-contract-acceptance="startContractAcceptance"
    @cancel-contract-acceptance="cancelContractAcceptance"
    @submit-contract-acceptance="acceptContractHandler.submit()"
    @start-account-edition="startAccountEdition"
    @cancel-account-edition="cancelAccountEdition"
    @submit-account-edition="(e) => accountFormHandler.submit(e)"
    @submit-preferences-edition="(e) => preferencesFormHandler.submit(e)"
  ></ViewComponent>
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/account/Basic.vue";
import { EditAccountHandler, EditUserPreferencesHandler } from "@/lib/handlers";
import { AcceptContractHandler } from "@/lib/handlers/accept/contract";
import {
  ensureAllContracts,
  listPlatformContracts,
  listPreviousPlatformContractsAccepted,
} from "@/store/contracts";
import { useLocaleStore } from "@/stores/locale-store";
import { useRolesStore } from "@/stores/roles";

export default defineComponent({
  name: "BasicContainer",
  components: { ViewComponent },
  setup() {
    const rolesStore = useRolesStore();

    onBeforeMount(() => rolesStore.ensure());

    return { rolesStore };
  },
  data() {
    return {
      accountFormHandler: null,
      acceptContractHandler: null,
      preferencesFormHandler: null,
    };
  },
  computed: {
    contracts() {
      return listPlatformContracts(this.$store);
    },
    availableLocales() {
      return [
        { value: "en", text: this.$t("localeEnLabel") },
        { value: "fr", text: this.$t("localeFrLabel") },
      ];
    },
    availableRoles() {
      return this.rolesStore.items;
    },
    previousContractsAcceptedPresent() {
      return (
        listPreviousPlatformContractsAccepted(this.$store).items.length > 0
      );
    },
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return;

        this.preferencesFormHandler = new EditUserPreferencesHandler(
          this,
          newVal,
        );
        this.preferencesFormHandler.on("success", () =>
          this.updateLocaleStore(),
        );
      },
    },
  },
  methods: {
    updateLocaleStore() {
      const locale = this.currentUser.preferences.locale;
      const localeStore = useLocaleStore();
      localeStore.setLocale(locale);
    },
    fetchAllContracts() {
      ensureAllContracts(this.$store);
    },
    startContractAcceptance({ contract }) {
      this.acceptContractHandler = new AcceptContractHandler(
        this,
        contract,
        this.previousContractsAcceptedPresent,
      );
    },
    cancelContractAcceptance() {
      this.acceptContractHandler = null;
    },
    startAccountEdition() {
      this.accountFormHandler = new EditAccountHandler(this, this.currentUser);
    },
    cancelAccountEdition() {
      this.accountFormHandler = null;
    },
  },
});
</script>

<i18n>
en:
  localeEnLabel: "English"
  localeFrLabel: "French"
fr:
  localeEnLabel: "Anglais"
  localeFrLabel: "Français"
</i18n>
