<template>
  <NotFoundError v-if="notFound" />
  <ViewComponent
    v-else
    :user="currentUser"
    :app="app"
    :appInfo="appInfo"
    :parentAppInfo="parentAppInfo"
    :appIsLoading="appIsLoading"
    :deploymentsInProgress="deploymentsInProgress"
    :allOperations="allOperations"
    :accessAsAdmin="accessAsAdmin"
    :dataAccessConsent="dataAccessConsent"
    :contractsToAccept="contractsToAccept"
    :contactNearlyOutdated="contactNearlyOutdated"
    :contactOutdated="contactOutdated"
    :contactLastUpdate="contact?.updated_at"
    :acceptContractHandler="acceptContractHandler"
    :bannerIsDisplayed="bannerIsDisplayed"
    @dismiss-banner="dismissBanner"
    @start-contract-acceptance="startContractAcceptance"
    @cancel-contract-acceptance="cancelContractAcceptance"
    @submit-contract-acceptance="(e) => acceptContractHandler.submit(e)"
  ></ViewComponent>
</template>

<script>
import { DateTime } from "luxon";
import {
  ref,
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
} from "vue";

import ViewComponent from "@/components/views/app/Root.vue";
import NotFoundError from "@/components/views/misc/NotFoundError.vue";
import { AcceptContractHandler } from "@/lib/handlers/accept/contract";
import { isBefore } from "@/lib/utils/time";
import {
  listPlatformContractsToAccept,
  listPreviousPlatformContractsAccepted,
} from "@/store/contracts";
import { deploymentsInProgress } from "@/store/deployments";
import { allOperations } from "@/store/operations";
import { useAppInfosStore } from "@/stores/app-infos";
import { useCurrentAppStore } from "@/stores/current/app";
import { usePointOfContactStore } from "@/stores/hds-point-of-contact";
import { useSessionStore } from "@/stores/session";

export default defineComponent({
  name: "RootContainer",
  components: { ViewComponent, NotFoundError },
  async beforeRouteUpdate(to, from, next) {
    try {
      await this.currentAppStore.setCurrentApp(
        this.$store,
        to.params.region,
        to.params.id,
      );
      next();
    } catch (e) {
      next(false);
    }
  },
  setup() {
    const sessionStore = useSessionStore();
    const currentAppStore = useCurrentAppStore();
    const appInfosStore = useAppInfosStore();
    const pocStore = usePointOfContactStore();
    const bannerIsDisplayed = ref(
      isBefore(localStorage.getItem("dismissBanner"), { weeks: 1 }),
    );

    onBeforeMount(() => {
      sessionStore.showBreadcrumbMenu();
      sessionStore.showMenu("app");
    });

    onBeforeUnmount(() => {
      sessionStore.hideBreadcrumbMenu();
      sessionStore.hideMenu();
    });

    async function dismissBanner() {
      localStorage.setItem("dismissBanner", DateTime.now());
      bannerIsDisplayed.value = isBefore(
        localStorage.getItem("dismissBanner"),
        { weeks: 1 },
      );
    }

    return {
      currentAppStore,
      appInfosStore,
      pocStore,
      appInfo: computed(() => currentAppStore.appInfo),
      app: computed(() => currentAppStore.regional),
      notFound: computed(() => currentAppStore.regionalPromiseInfo.isError),
      appIsLoading: computed(
        () => currentAppStore.regionalPromiseInfo.isLoading,
      ),
      dismissBanner,
      bannerIsDisplayed,
    };
  },
  data() {
    return {
      destroyOperation: null,
      acceptContractHandler: null,
    };
  },
  computed: {
    contact() {
      return this.pocStore.item;
    },
    contactNearlyOutdated() {
      if (!this.contact) {
        return false;
      }
      return this.contact.nearly_outdated;
    },
    contactOutdated() {
      if (!this.contact) {
        return false;
      }
      return this.contact.outdated;
    },
    accessAsAdmin() {
      return this.currentUser.flags?.admin && !this.appInfo;
    },
    dataAccessConsent() {
      if (!this.accessAsAdmin) {
        return null;
      }

      return this.app?.data_access_consent;
    },
    parentAppInfo() {
      return this.appInfosStore.parentAppInfoFor(this.appInfo);
    },
    deploymentsInProgress() {
      return deploymentsInProgress(this.$store);
    },
    allOperations() {
      return allOperations(this.$store);
    },
    contractsToAccept() {
      return listPlatformContractsToAccept(this.$store);
    },
    previousContractsAcceptedPresent() {
      return (
        listPreviousPlatformContractsAccepted(this.$store).items.length > 0
      );
    },
  },
  created() {
    this.currentAppStore.setCurrentApp(
      this.$store,
      this.$route.params.region,
      this.$route.params.id,
    );
    this.pocStore.ensure();
  },
  beforeUnmount() {
    this.currentAppStore.unsetCurrentApp(this.$store);
  },
  methods: {
    startContractAcceptance({ contract }) {
      this.acceptContractHandler = new AcceptContractHandler(
        this,
        contract,
        this.previousContractsAcceptedPresent,
      );
    },
    cancelContractAcceptance() {
      this.acceptContractHandler = null;
    },
  },
});
</script>
