<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons>
          <SCButton
            :disabled="!hasBackupInPlan"
            @click="$emit('startBackupsConfiguration')"
          >
            {{ $t("edit") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="flex flex-row space-x-3">
        <div class="bg-scale-1 w-12 h-12 p-2 rounded-lg text-scale-4">
          <BackupGlyph></BackupGlyph>
        </div>
        <div
          class="flex flex-col"
          :class="{
            'text-scale-10': hasBackupInPlan && backupEnabled,
            'text-scale-5': !hasBackupInPlan || !backupEnabled,
          }"
        >
          <div class="flex flex-row space-x-1">
            <div>{{ $t("scheduled") }}:</div>
            <div
              v-if="hasBackupInPlan"
              class="flex flex-row items-center font-medium"
            >
              <span v-if="backupEnabled">
                {{ $t("dailyBackups") }} {{ backupTime }}
              </span>
              <span v-else> {{ $t("disabledBackup") }} </span>
            </div>
            <div v-else>{{ $t("none") }}</div>
          </div>
          <div class="flex flex-row space-x-1">
            <div>{{ $t("nextRun") }}:</div>
            <div v-if="hasBackupInPlan" class="font-medium">
              <span v-if="backupEnabled">
                {{ nextBackupTime }}
              </span>
              <span v-else> {{ $t("disabledBackup") }} </span>
            </div>
            <div v-else>{{ $t("none") }}</div>
          </div>
        </div>
      </div>
      <InformationAlert
        v-if="hasBackupInPlan && !backupEnabled"
        class="mt-3"
        :finerBanner="true"
      >
        {{ $t("infoMessage") }}
      </InformationAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import BackupGlyph from "@/components/atoms/glyphs/BackupGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";
import { distanceWithUtcNow } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "BackupSettings",
  components: {
    InformationAlert,
    BackupGlyph,
    SCButton,
    CardHeader,
    Card,
  },
  mixins: [ModelsTranslation],
  props: {
    db: Object,
    hasBackupInPlan: Boolean,
  },
  emits: ["startBackupsConfiguration"],
  data() {
    return {
      requestedSize: null,
    };
  },
  computed: {
    backupEnabled() {
      return !!this.db?.periodic_backups_enabled;
    },
    nextBackupTime() {
      return distanceWithUtcNow(
        this.db.periodic_backups_scheduled_at?.[0] ||
          this.db.periodic_backups_scheduled_at,
      ).toRelative();
    },
    backupTime() {
      // The db api returns an array with an integer on db.periodic_backups_scheduled_at
      // But when we update this field, we need to send an integer
      // As the db object isn't reloaded, it needs to accept both formats
      // Check updateBackupsConfiguration in src/controllers/db/backups/Configuration.vue
      return (
        (this.db.periodic_backups_scheduled_at?.[0] ||
          this.db.periodic_backups_scheduled_at) + ":00 UTC"
      );
    },
    sizeState() {
      return "success";
    },
  },
});
</script>

<i18n>
en:
  title: "Backup schedule"
  subtitle: "Define the most appropriate backup time."
  edit: "Schedule"
  dailyBackups: "every day at "
  disabled: "Scheduled backups are not configured to run"
  nextRun: "Next backup"
  scheduled: "Scheduled"
  infoMessage: "Starter and Business plans include scheduled, managed backups, that create a daily copy of your database."
  none: "none"
  disabledBackup: "disabled"
fr:
  title: "Planification des backups"
  subtitle: "Définissez l'horaire de backup le plus approprié."
  edit: "Planifier"
  dailyBackups: "chaque jour à"
  disabled: "Les backups planifiés ne sont pas configurés pour s'exécuter"
  nextRun: "Prochain backup"
  scheduled: "Planifié"
  infoMessage: "Les plans Starter et Business incluent des sauvegardes planifiées et gérées, qui créent une copie quotidienne de votre base de données."
  none: "aucun"
  disabledBackup: "désactivé"
</i18n>
