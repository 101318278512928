<template>
  <div class="flex flex-grow flex-col">
    <div class="flex flex-grow">
      <div class="flex flex-col flex-grow">
        <div class="flex flex-col pv:flex-row">
          <div
            class="flex"
            :class="{
              'text-color-1': inSuccess,
              'text-color-2': inFailure,
              'text-scale-5': isOngoing,
            }"
          >
            <CheckGlyph v-if="inSuccess" />
            <DangerSignGlyph v-if="inFailure" />

            <span :class="{ 'ml-2': !isOngoing }" class="humanize font-bold">
              {{ $ta("deployment", `statuses.${deployment.status}`) }}
            </span>
          </div>
          <div class="flex ml-6 pv:ml-0">
            <div class="ml-1">{{ $t("by") }}</div>
            <div class="ml-1 font-medium">{{ deployment.pusher.username }}</div>
          </div>
        </div>
        <div
          class="flex text-sm text-scale-7 py-1 flex-col pv:flex-row items-center mt-2"
          :class="{ 'space-x-2 lg:space-x-4': !constraintWidth }"
        >
          <div class="flex">
            <span>
              <font-awesome-icon
                class="text-scale-5"
                :icon="['far', 'calendar']"
                :title="$ta('icons', 'timestamp')"
              ></font-awesome-icon>
            </span>
            <div :class="{ timestampWidth: constraintWidth }">
              <router-link
                v-if="withLink"
                class="hover:underline ml-1"
                :title="formatDateTime(deployment.created_at)"
                :to="showRouteFor(deployment)"
              >
                {{ relativeOrAbsolute(deployment.created_at) }}
              </router-link>
              <div
                v-else
                class="ml-1"
                :title="formatDateTime(deployment.created_at)"
              >
                {{ relativeOrAbsolute(deployment.created_at) }}
              </div>
            </div>
          </div>
          <div
            class="flex flex-row"
            :class="{ 'space-x-2 lg:space-x-4': !constraintWidth }"
          >
            <div class="flex" :title="$ta('icons', 'duration')">
              <span>
                <font-awesome-icon
                  class="text-scale-5 pr-1"
                  :icon="['far', 'clock']"
                ></font-awesome-icon>
              </span>
              <div :class="{ durationWidth: constraintWidth }">
                {{ formatDeploymentDuration(realDuration) }}
              </div>
            </div>
            <div class="flex" :title="$ta('icons', 'commit')">
              <span>
                <font-awesome-icon
                  class="text-scale-5 pr-1"
                  icon="code-commit"
                ></font-awesome-icon>
              </span>
              <div :class="{ commitWidth: constraintWidth }">
                {{ shortGitRef(deployment.git_ref) }}
              </div>
            </div>
            <div
              v-if="isList && inSuccess && deployment.image_size"
              class="flex"
              :title="$ta('icons', 'imageSize')"
            >
              <span>
                <font-awesome-icon
                  class="text-scale-5 pr-1"
                  :icon="['far', 'hard-drive']"
                ></font-awesome-icon>
              </span>
              <div>
                {{ humanReadableSize(deployment.image_size, false, 2) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="withActionsButtons"
          class="items-center flex pv:hidden justify-center"
        >
          <SCButton
            v-if="displayDockerImageLink"
            kind="neutral"
            class="mr-2 py-1"
            :title="$t('openDockerInstructionsTitle')"
            @click="dockerInstructionsShown = !dockerInstructionsShown"
          >
            <font-awesome-icon :icon="['fab', 'docker']" class="text-lg" />
          </SCButton>

          <SCButton
            kind="neutral"
            :title="$t('openTerminalTitle')"
            @click="consoleOpened = !consoleOpened"
          >
            <TerminalGlyph />
          </SCButton>
        </div>
      </div>
      <div class="items-center hidden pv:flex ml-2">
        <SCButton
          v-if="withActionsButtons && displayDockerImageLink"
          kind="neutral"
          class="mr-2 py-1"
          :title="$t('openDockerInstructionsTitle')"
          @click="dockerInstructionsShown = !dockerInstructionsShown"
        >
          <font-awesome-icon :icon="['fab', 'docker']" class="text-lg" />
        </SCButton>
        <SCButton
          v-if="withActionsButtons"
          kind="neutral"
          :title="$t('openTerminalTitle')"
          @click="consoleOpened = !consoleOpened"
        >
          <TerminalGlyph />
        </SCButton>
        <DeploymentHelpLinks v-if="withHelpLinks" :deployment="deployment" />
      </div>
    </div>
    <div
      v-if="dockerInstructionsShown"
      class="border-l-2 border-scale-3 pl-4 text-sm mb-2 text-scale-9"
    >
      <p class="mb-2">
        {{ $t("docker.line1") }}
        <DocLink
          href="https://doc.scalingo.com/addons/scalingo-docker-image/start"
        >
          {{ $t("docker.doc") }}
        </DocLink>
        <br />
        {{ $t("docker.line2") }}
        <router-link class="underline" :to="tokensRoute" target="_blank">
          {{ $t("docker.tokens") }}
          <font-awesome-icon class="ml-1" icon="external-link-alt" />
        </router-link>
        <br />
        {{ $t("docker.line3") }}
      </p>
      <Console :lines="dockerInstructions" class="mb-2" small />
      <p>
        {{ $t("docker.line4") }}
      </p>
    </div>

    <div v-if="consoleOpened">
      <div class="flex-grow mt-3 pv:hidden">
        <TableAlert styling="warning">
          <template #title>{{ $t("unsupportedMode") }}</template>
          <template #text>{{ $t("turnYourMobile") }}</template></TableAlert
        >
      </div>
      <Console
        class="text-xs pv:flex hidden my-1"
        :autoScroll="true"
        :htmlLogs="htmlLogs"
        :isLoading="loadingOperation.isLoading"
      ></Console>
    </div>
  </div>
</template>

<script>
import AnsiUp from "ansi_up";
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import TerminalGlyph from "@/components/atoms/glyphs/TerminalGlyph.vue";
import TableAlert from "@/components/molecules/alerts/TableAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import DeploymentHelpLinks from "@/components/parts/app/showDeploy/DeploymentHelpLinks.vue";
import Console from "@/components/parts/app/showLogs/ShowLogsConsole.vue";
import { RemoteOperationSuccess } from "@/lib/store/remote-operation";
import { shortGitRef } from "@/lib/utils/git";
import { humanReadableSize } from "@/lib/utils/size";
import {
  formatDateTime,
  formatDuration,
  relativeOrAbsolute,
} from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";
import {
  fetchLogsForDeployment,
  logsForDeployment,
} from "@/store/deployment-logs";
import {
  STATUSES_ONGOING,
  STATUSES_SUCCESSES,
  STATUSES_FAILURES,
} from "@/store/deployments";

export default defineComponent({
  name: "ShowDeployDeployment",
  components: {
    DeploymentHelpLinks,
    TableAlert,
    SCButton,
    TerminalGlyph,
    CheckGlyph,
    DangerSignGlyph,
    Console,
    DocLink,
  },

  mixins: [ModelsTranslation],
  props: {
    app: Object,
    deployment: Object,
    dockerAddon: Object,
    openConsole: {
      type: Boolean,
      default: false,
    },
    openableConsole: {
      type: Boolean,
      default: true,
    },
    withLink: {
      type: Boolean,
      default: false,
    },
    withActionsButtons: {
      type: Boolean,
      default: true,
    },
    withHelpLinks: {
      type: Boolean,
      default: false,
    },
    isList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const colorizer = new AnsiUp();
    colorizer.use_classes = true;

    return {
      dockerInstructionsShown: false,
      consoleOpened: false,
      colorizer,
      loadingOperation: RemoteOperationSuccess,
      realDuration: null,
      durationUpdaterIntervalId: null,
      tokensRoute: {
        name: Routes.Account.Tokens,
      },
    };
  },
  computed: {
    inSuccess() {
      return STATUSES_SUCCESSES.includes(this.deployment.status);
    },
    inFailure() {
      return STATUSES_FAILURES.includes(this.deployment.status);
    },
    isOngoing() {
      return STATUSES_ONGOING.includes(this.deployment.status);
    },
    displayDockerImageLink() {
      return this.inSuccess && this.dockerAddon;
    },
    dockerInstructions() {
      return [
        `docker login ${this.deployment.registry}`,
        `docker pull ${this.deployment.image}`,
      ];
    },
    rawLogs() {
      return logsForDeployment(this.$store, this.deployment.id)?.logs;
    },
    htmlLogs() {
      if (!this.rawLogs || this.rawLogs === "") {
        return this.rawLogs;
      }

      return this.colorizer.ansi_to_html(this.rawLogs);
    },
    constraintWidth() {
      if (!this.isList) {
        return false;
      }
      return window.innerWidth > 1024;
    },
  },
  watch: {
    deployment: {
      handler(newVal) {
        this.realDuration = newVal.duration;

        this.updateDurationIfNeeded();
      },
      immediate: true,
    },
    "deployment.status": {
      handler() {
        this.openConsoleIfRelevant();
      },
      immediate: true,
    },
    consoleOpened: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          if (!this.isOngoing) {
            const operation = await fetchLogsForDeployment(
              this.$store,
              this.deployment.id,
            );

            if (operation) {
              this.loadingOperation = operation;
            }
          }
        }
      },
    },
  },
  beforeMount() {
    this.openConsoleIfRelevant();
  },
  beforeUnmount() {
    this.removeDurationUpdater();
  },
  methods: {
    formatDeploymentDuration(input) {
      return formatDuration({ seconds: input });
    },
    relativeOrAbsolute,
    shortGitRef,
    formatDateTime,
    humanReadableSize,
    openConsoleIfRelevant() {
      if (!this.openableConsole) {
        return;
      }
      if (this.isOngoing || this.openConsole) {
        this.consoleOpened = true;
      }
    },
    showRouteFor(deployment) {
      return {
        name: Routes.App.Deploy.List.Show,
        params: {
          deploymentId: deployment.id,
        },
      };
    },
    removeDurationUpdater() {
      if (this.durationUpdaterIntervalId) {
        clearTimeout(this.durationUpdaterIntervalId);
        this.durationUpdaterIntervalId = null;
      }
    },
    updateDurationIfNeeded() {
      if (STATUSES_ONGOING.includes(this.deployment.status)) {
        const date = DateTime.fromISO(this.deployment.created_at);
        const diff = date.diffNow();

        this.realDuration = -1 * diff.as("seconds");

        this.durationUpdaterIntervalId = setTimeout(
          () => this.updateDurationIfNeeded(),
          1000,
        );
      } else {
        this.realDuration = this.deployment.duration;

        this.removeDurationUpdater();
      }
    },
  },
});
</script>

<style scoped>
.timestampWidth {
  min-width: 320px;
}
.durationWidth {
  min-width: 80px;
}
.commitWidth {
  min-width: 90px;
}
</style>

<i18n>
  en:
    openTerminalTitle: "Open terminal"
    openDockerInstructionsTitle: "View Docker instructions"
    unsupportedMode: "Unsupported mode"
    turnYourMobile: "Turn your phone in landscape mode"
    by: "by"
    docker:
      line1: "First, read the instructions related to the docker addon"
      doc: "on our documentation center"
      line2: "Then, you will need an API token: you can generate one from"
      tokens: "the dedicated page in your account"
      line3: "Finally, execute the commands below from a terminal:"
      line4: "Warning: old images are probably already deleted."
    title:
      timestamp: "Timestamp"
      duration: "Duration"
      commit: "Commit"
      imageSize: "Image size"
  fr:
    openTerminalTitle: "Ouvrir le terminal"
    openDockerInstructionsTitle: "Afficher les instructions Docker"
    unsupportedMode: "Mode non supporté"
    turnYourMobile: "Tournez votre mobile en mode paysage"
    by: "par"
    docker:
      line1: "D'abord, consultez les instructions relatives a l'addon docker"
      doc: "depuis notre documentation"
      line2: "Ensuite, vous allez avoir besoin d'un API token: vous pouvez en générer un depuis"
      tokens: "la page dédiée de votre compte"
      line3: "Enfin, exécutez les commandes ci-dessous depuis un terminal:"
      line4: "Attention: les anciennes images sont probablement déjà supprimées."
    title:
      timestamp: "Horodatage"
      duration: "Durée"
      commit: "Commit"
      imageSize: "Taille de l'image"
</i18n>
