<template>
  <main>
    <SectionTitle
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    ></SectionTitle>
    <Card class="mt-6">
      <template #body>
        <EventsList
          :events="events"
          :eventsComplements="eventsComplements"
          @view-more-button-clicked="$emit('viewMore')"
          @body-opened="(e) => $emit('bodyOpened', e)"
        ></EventsList>
      </template>
    </Card>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SectionTitle from "@/components/atoms/titles/SectionTitle.vue";
import Card from "@/components/molecules/card/Card.vue";
import EventsList from "@/components/parts/app/showActivity/EventsList.vue";

export default defineComponent({
  name: "ActivityAll",
  components: {
    EventsList,
    Card,
    SectionTitle,
  },
  props: {
    app: Object,
    user: Object,
    events: Object,
    eventsComplements: Array,
  },
  emits: ["bodyOpened", "viewMore"],
  data() {
    return {};
  },
});
</script>

<i18n>
  en:
    title: "Activity"
    subtitle: "Audit the timeline of events."
  fr:
    title: "Activité"
    subtitle: "Audit de l'historique des événements."
</i18n>
