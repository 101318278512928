<template>
  <section>
    <Card>
      <template #header>
        <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
          <template #buttons>
            <SCButton :to="newAlertRoute">
              {{ $t("add") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <EmptyCardState v-if="noItems">
          <template #icon>
            <SVGModelAlert class="h-8 w-8 text-scale-5" />
          </template>
          <div class="flex flex-col text-center">
            <div class="text-lg font-bold text-scale-10">
              {{ $t("none.title") }}
            </div>
            <div class="mt-2">
              {{ $t("none.subtitle") }}
            </div>
            <div class="mt-2">
              <SCButton :to="newAlertRoute">
                {{ $t("none.action") }}
              </SCButton>
            </div>
          </div>
        </EmptyCardState>

        <AlertsList
          v-else
          class="mt-2"
          :alerts="alerts"
          :app="app"
          @start-alert-deletion="(e) => $emit('startAlertDeletion', e)"
        />
      </template>
    </Card>

    <RemoveAlertModal
      v-if="deletionHandler"
      :formHandler="deletionHandler"
      @form-submitted="$emit('comfirmAlertDeletion')"
      @modal-closed="$emit('cancelAlertDeletion')"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import SVGModelAlert from "@/components/graphics/models/Alert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import RemoveAlertModal from "@/components/parts/app/alerts/RemoveAlertModal.vue";
import AlertsList from "@/components/parts/appSettings/alertsSettings/AlertsList.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "Alerts",
  components: {
    AlertsList,
    CardHeader,
    EmptyCardState,
    Card,
    SCButton,
    SVGModelAlert,
    RemoveAlertModal,
  },
  props: {
    app: Object,
    user: Object,
    alerts: Object,
    deletionHandler: Object,
  },
  emits: ["cancelAlertDeletion", "comfirmAlertDeletion", "startAlertDeletion"],
  data() {
    return {
      newAlertRoute: {
        name: Routes.App.Alerts.Creation,
        params: { id: this.app.name, region: this.app.region },
      },
    };
  },
  computed: {
    noItems() {
      return (
        this.alerts.latestFetch.isFinished && this.alerts.items.length === 0
      );
    },
  },
});
</script>

<i18n>
en:
  title: "Alerts"
  subtitle: "Configure alerts to be informed about events in your app."
  add: "Add"
  none:
    title: "There are no items here"
    subtitle: "Alerts will appear here once created."
    action: "Add an alert"

fr:
  title: "Alertes"
  subtitle: "Configurer des alertes pour être informé des évènements de votre app."
  add: "Ajouter"
  none:
    title: "Il n'y a aucun élément"
    subtitle: "Les alertes apparaitront ici une fois ajoutées."
    action: "Ajouter une alerte"
</i18n>
