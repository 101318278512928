<template>
  <main>
    <DividedSectionTitle :title="$t('title')" :subtitle="$t('subtitle')">
      <DateSelector
        :selectedValue="since"
        @range-selected="(e) => $emit('rangeSelected', e)"
      />
    </DividedSectionTitle>

    <RequestsCard
      class="mt-6"
      :promiseInfo="routerInfo"
      :routerRPMRawData="routerRPMRawData"
      :router5XXRawData="router5XXRawData"
      :eventsRawData="eventsRawData"
    />

    <ResponseTimeCard
      class="mt-4"
      :promiseInfo="responseTimeInfo"
      :responseTimeMedianRawData="responseTimeMedianRawData"
      :responseTimep95RawData="responseTimep95RawData"
      :responseTimep99RawData="responseTimep99RawData"
      :eventsRawData="eventsRawData"
    />

    <div
      v-for="(rawData, name) in containersRawData"
      :key="name"
      class="flex flex-col pv:flex-row mt-4"
    >
      <CpuCard
        class="pv:w-1/2 pv:mr-2"
        :container="containers[name]"
        :containerSizes="containerSizes"
        :promiseInfo="containersInfos[name].allCpu"
        :cpuData="rawData.cpu"
      ></CpuCard>

      <MemoryCard
        class="pv:ml-2 pv:w-1/2 mt-4 pv:mt-0"
        :container="containers[name]"
        :containerSizes="containerSizes"
        :promiseInfo="containersInfos[name].allMemory"
        :memoryData="rawData.memory"
        :swapData="rawData.swap"
      ></MemoryCard>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import DividedSectionTitle from "@/components/atoms/titles/DividedSectionTitle.vue";
import CpuCard from "@/components/parts/app/showMetrics/CpuCard.vue";
import DateSelector from "@/components/parts/app/showMetrics/DateSelector.vue";
import MemoryCard from "@/components/parts/app/showMetrics/MemoryCard.vue";
import RequestsCard from "@/components/parts/app/showMetrics/RequestsCard.vue";
import ResponseTimeCard from "@/components/parts/app/showMetrics/ResponseTimeCard.vue";

export default defineComponent({
  name: "MetricsOverview",
  components: {
    DateSelector,
    DividedSectionTitle,
    MemoryCard,
    CpuCard,
    ResponseTimeCard,
    RequestsCard,
  },
  props: {
    app: Object,
    user: Object,
    containers: Object,
    containerSizes: Object,
    since: [String, Number],
    routerInfo: Object,
    responseTimeInfo: Object,
    containersInfos: Object,
    routerRPMRawData: Array,
    router5XXRawData: Array,
    responseTimeMedianRawData: Array,
    responseTimep95RawData: Array,
    responseTimep99RawData: Array,
    containersRawData: Object,
    eventsRawData: Array,
  },
  emits: ["rangeSelected"],
  computed: {
    containerNames() {
      return Object.keys(this.containersRawData);
    },
  },
});
</script>

<i18n>
  en:
    title: "Metrics"
    subtitle: "Analyse key indicators of performance and consumption."
  fr:
    title: "Métriques"
    subtitle: "Analyse des indicateurs clés de performance et de consommation."
</i18n>
