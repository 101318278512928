<template>
  <SimpleModal
    size="sm:w-2/3 md:1/2 lg:w-1/2 2xl:w-1/3"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ localizedAttr(contract, "name") }} – {{ $ta("contract", "version") }}
      {{ contract.version }}
    </template>
    <template #subtitle>
      {{
        $t("subtitle", {
          publishedOn: formatDateTime(contract.published_at, "DDD"),
        })
      }}
    </template>

    <template #body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <p class="mt-4">
              {{ $t("disclaimer.line1", { documents: docCount }) }}
            </p>
            <p class="mt-2">
              {{ $t("disclaimer.line2", { pronoun: pronounDocCount }) }}
            </p>

            <p v-if="localizedAttr(contract, 'blog_article')" class="mt-2">
              {{ $t("article.read") }}

              <DocLink
                :href="localizedAttr(contract, 'blog_article')"
                class="inline"
              >
                {{ $t("article.link") }}
              </DocLink>

              {{ articleLinkSentence }}
            </p>

            <p v-if="contract.enroll_at" class="mt-2">
              {{
                $t("disclaimer.enrollAt", {
                  date: formatDateTime(contract.enroll_at, "DDD"),
                })
              }}
            </p>

            <ContractDocuments class="mt-4" :documents="contract.documents" />

            <CheckboxInput
              :modelValue="checked"
              :label="$t('labels.documents', { documents: docCount })"
              class="mt-4"
              @update:model-value="checked = !checked"
            />

            <SCAlert
              v-if="formHandler.hasFieldErrors"
              kind="error"
              class="mt-4"
            >
              <p>{{ formattedErrors }}</p>
            </SCAlert>

            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="mt-4"
              :disabled="!meta.valid || !checked"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("action", { documents: docCount }) }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Form } from "vee-validate";
import { defineComponent } from "vue";

import CheckboxInput from "@/components/atoms/inputs/CheckboxInput.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import ContractDocuments from "@/components/parts/account/contracts/ContractDocuments.vue";
import { formatDateTime } from "@/lib/utils/time";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "AcceptContractModal",
  components: {
    CheckboxInput,
    DocLink,
    SimpleModal,
    SCButton,
    Form,
    ContractDocuments,
    SCAlert,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    formHandler: Object,
  },
  emits: ["modalClosed"],
  data() {
    return {
      form: {}, // unused
      checked: false,
    };
  },
  computed: {
    contract() {
      return this.formHandler.contract;
    },
    pronounDocCount() {
      return this.$tc("disclaimer.pronoun", this.contract.documents.length);
    },
    docCount() {
      return this.$tc("documents", this.contract.documents.length);
    },
    formattedErrors() {
      if (!this.formHandler.errors.user_id) return "";
      if (this.formHandler.errors.user_id.length == 0) return "";

      return this.formHandler.errors.user_id.join(" ");
    },
    articleLinkSentence() {
      return this.formHandler.previousContractsAcceptedPresent
        ? this.$t("article.sentenceContractUpdate")
        : this.$t("article.sentenceFirstContract");
    },
  },
  methods: {
    formatDateTime,
    titleForContract(contract) {
      return (
        this.$ta("contract", `kinds.${contract.kind}`) +
        " - " +
        this.$ta("contract", "version") +
        " " +
        contract.version
      );
    },
    localizedAttr(object, attr) {
      return object[attr]?.[this.$i18n.locale];
    },
  },
});
</script>

<i18n>
en:
  title: "Review contract"
  subtitle: "Published on {publishedOn}"
  documents: "0 documents | the document | all {count} documents"
  disclaimer:
    line1: "Please read carefully {documents} of this contract before accepting it."
    line2: "You can consult {pronoun} on our website or download the PDFs."
    enrollAt: "Unless otherwise indicated, this contract will automatically be accepted on {date}."
    pronoun: "it | them"
  article:
    read: "Read "
    link: "this article"
    sentenceContractUpdate: "for detailed informations on what is changing for you and why."
    sentenceFirstContract: "for more informations on this contract."
  labels:
    documents: "I have read {documents}"
  action: "I accept this contract"
fr:
  title: "Revue d'un contrat"
  subtitle: "Publié le {publishedOn}"
  documents: "0 documents | le document | les {count} documents"
  disclaimer:
    line1: "Veuillez lire attentivement {documents} de ce contrat avant de l'accepter."
    line2: "Vous pouvez {pronoun} lire sur notre site web ou bien télécharger les PDFs."
    enrollAt: "Sauf indication contraire, ce contrat sera automatiquement accepté le {date}."
    pronoun: "le | les"
  article:
    read: "Consultez "
    link: "cet article"
    sentenceContractUpdate: "Consultez {link} pour plus de détails sur ce qui change pour vous et pourquoi."
    sentenceFirstContract: "Consultez {link} pour plus d'informations sur ce contrat."
  labels:
    documents: "J'ai lu {documents}"
  action: "J'accepte ce contrat"
</i18n>
