<template>
  <Card>
    <template #header>
      <CardHeader :title="title" :subtitle="subtitle">
        <template #buttons>
          <SCButton
            v-if="!index && container.amount > 1"
            :to="containerMetricsRoute"
            :title="$t('actions.details', { type: container.name })"
          >
            <font-awesome-icon icon="info" />
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <LoadingCardState v-if="isLoading" class="h-64 justify-center" />
      <EmptyCardState v-else-if="isError" class="h-64 justify-center">
        <template #icon><CrossGlyph /></template>
        <span class="capitalize">{{ promiseInfo.err.data.error }}</span>
      </EmptyCardState>
      <ContainerRAM
        v-else-if="isLoaded"
        :datasets="datasets"
        :containerMemoryLimit="maxMemory"
      />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ContainerRAM from "@/components/organisms/graphs/ContainerRAM.vue";
import { makeDataSet } from "@/lib/metrics";
import { Routes } from "@/router/names";

function sizeForContainer(container, sizes) {
  return sizes.find((size) => size.name === container.size);
}

export default defineComponent({
  name: "MemoryCard",
  components: {
    Card,
    CardHeader,
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
    SCButton,
    ContainerRAM,
  },
  props: {
    promiseInfo: Object,
    container: Object,
    containerSizes: Object,
    memoryData: Array,
    swapData: Array,
    index: Number,
  },
  computed: {
    containerMetricsRoute() {
      return {
        name: Routes.App.Metrics.PerContainer,
        params: { containerType: this.container.name },
      };
    },
    title() {
      if (this.index) {
        return this.$t("perContainer.title", {
          formation: `${this.container.name}-${this.index}`,
        });
      }

      return this.$t("global.title", { formation: this.formation });
    },
    subtitle() {
      if (this.index) {
        return this.$t("perContainer.subtitle", {
          formation: `${this.container.name}-${this.index}`,
        });
      }

      return this.$t("global.subtitle", { name: this.container.name });
    },
    formation() {
      const { name, size, amount } = this.container;

      return `${name} (${size}:${amount})`;
    },
    isLoaded() {
      return this.promiseInfo.isSuccess;
    },
    isLoading() {
      return this.promiseInfo.isLoading;
    },
    isError() {
      return this.promiseInfo.isError;
    },
    maxMemory() {
      const size = sizeForContainer(this.container, this.containerSizes.items);

      return size.memory;
    },
    memoryDataset() {
      return makeDataSet(this.$i18n, "containers", "memory", this.memoryData);
    },
    swapDataset() {
      return makeDataSet(this.$i18n, "containers", "swap", this.swapData);
    },
    datasets() {
      return [this.memoryDataset, this.swapDataset];
    },
  },
});
</script>

<i18n>
en:
  global:
    title: "Memory – {formation}"
    subtitle: "Maximum memory consumption across containers of type {name}."
  perContainer:
    title: "Memory – {formation}"
    subtitle: "Memory consumption of container {formation}."
  actions:
    details: "See metrics for individual {type} containers"
fr:
  global:
    title: "Mémoire – {formation}"
    subtitle: "Consommation mémoire maximale des conteneurs de type {name}."
  perContainer:
    title: "Mémoire – {formation}"
    subtitle: "Consommation mémoire du conteneur {formation}."
  actions:
    details: "Voir les métriques pour chaque conteneur {type}."
</i18n>
